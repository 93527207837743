import About1 from '../../images/About1.png';
import About2 from '../../images/About2.png';
import About3 from '../../images/About3.png';
import React from 'react';
import { Typography } from '@mui/material';

function Card({ image, title, description, link, buttonName }) {
    return (
        <div className="justify-center mb-auto" style={{ maxWidth: '330px', minWidth: '220px' }}>
            <img src={image} alt={title} />
            <div className="w-full pt-5 text-center space-y-3">
                <span className="font-display font-bold text-3xl">{title}</span>
                <p>{description}</p>
                <div className="w-full px-5 text-center">
                    <a
                        href={link}
                        className="font-semibold text-customPrimary hover:text-cybercast-blue"
                    >
                        {buttonName}
                    </a>
                </div>
            </div>
        </div>
    );
}

function AboutUsCard() {
    const cardData = [
        {
            image: About1,
            title: 'Hardware',
            description: 'Leading manufacturer of next-generation Digital Signage',
            link: './product/hardware',
            buttonName: 'View our Catelog →',
        },
        // {
        //     image: About2,
        //     title: 'Software',
        //     description:
        //         'Content, device & data management packaged together inside an innovative CMS suite.',
        //     link: 'http://link2.com',
        //     buttonName: 'Learn More →',
        // },
        {
            image: About3,
            title: 'Solutions',
            description: 'Bespoke solutions that make our clients’ vision come to life.',
            link: 'http://link3.com',
            buttonName: 'Learn More →',
        },
    ];

    return (
        <div className="w-full flex flex-col sm:flex-row justify-around items-center space-x-auto mt-20">
            {cardData.map((card, index) => (
                <Card
                    key={index}
                    image={card.image}
                    title={card.title}
                    description={card.description}
                    link={card.link}
                    buttonName={card.buttonName}
                />
            ))}
        </div>
    );
}

export default AboutUsCard;
