import { BlogList, BottomSignUp, GetStarted } from '../components/website';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import GetStartedBg from '../images/GetStarted.png';
import TalkToUs from '../images/TalkToUs.png';
import { useStateContext } from '../contexts/ContextProvider';

const Blogs = () => {
    const [activeTab, setActiveTab] = useState('All Post');
    return (
        <div className="flex flex-col items-center w-full bg-custom-bg">
            {/* First row- Typography */}
            <div className="max-w-6xl mx-auto justify-around space-y-8 text-center items-center py-25 px-2">
                <div className="max-w-5xl mx-auto">
                    <h6 className="font-display font-bold text-4xl mb-3 md:text-5xl">
                        Insight from our expert team{' '}
                    </h6>
                    <span>Talk to us to create your unique digital experience today.</span>
                </div>
            </div>

            {/* Second row */}
            <div className="w-full pb-25">
                <div className="max-w-6xl justify-center items-center mx-auto md:flex">
                    <img src={TalkToUs} alt="image" className="md:w-1/2  px-12" />

                    <div className="md:w-1/2 mx-auto  px-12">
                        <h6 className="font-display font-bold text-slate-500 mb-4">
                            {' '}
                            Help & Advice{' '}
                        </h6>
                        <h6 className="font-display font-bold text-3xl">
                            Corporate digital signage starter guide for your office workplace{' '}
                        </h6>
                        <p className="mt-4 mb-8">
                            Should your next motion feature digital signage? Whether it’s
                            information in the foyer, clever presentations in meeting rooms or even
                            swift updates …
                        </p>
                        <button className="font-display font-bold text-customPrimary">
                            Read More →
                        </button>
                    </div>
                </div>
            </div>
            {/* Third row */}
            <div className="w-full bg-white">
                <div className="max-w-6xl pt-25 items-center mx-auto">
                    <h3 className="mb-10 font-display font-bold text-3xl">Browse our articles</h3>
                    <div className="flex space-x-4 items-center z-30 mr-auto">
                        {/* Tab 1 */}
                        <div
                            onClick={() => setActiveTab('All Post')}
                            className={`cursor-pointer text-center py-2 px-2 ${
                                activeTab === 'All Post'
                                    ? 'border-b-4 border-customPrimary z-10 bg-white'
                                    : 'bg-white'
                            }`}
                        >
                            <h3 className="font-display ">All Post</h3>
                        </div>
                        {/* Tab 2 */}
                        <div
                            onClick={() => setActiveTab('Help & Advice')}
                            className={`cursor-pointer text-center py-2 px-2 ${
                                activeTab === 'Help & Advice'
                                    ? 'border-b-4 border-customPrimary z-10 bg-white'
                                    : 'bg-white'
                            }`}
                        >
                            <h3 className="font-display  ">Help & Advice</h3>
                        </div>
                        {/* Tab 3 */}
                        <div
                            onClick={() => setActiveTab('News & Trends')}
                            className={`cursor-pointer text-center py-2 px-2 ${
                                activeTab === 'News & Trends'
                                    ? 'border-b-4 border-customPrimary z-10 bg-white'
                                    : 'bg-white'
                            }`}
                        >
                            <h3 className="font-display ">News & Trends</h3>
                        </div>
                        {/* Tab 4 */}
                        <div
                            onClick={() => setActiveTab('Development updates')}
                            className={`cursor-pointer text-center py-2 px-2 ${
                                activeTab === 'Development updates'
                                    ? 'border-b-4 border-customPrimary z-10 bg-white'
                                    : 'bg-white'
                            }`}
                        >
                            <h3 className="font-display ">Development updates</h3>
                        </div>
                        {/* Tab 5 */}
                        <div
                            onClick={() => setActiveTab('Hardware news')}
                            className={`cursor-pointer text-center py-2 px-2 ${
                                activeTab === 'Hardware news'
                                    ? 'border-b-4 border-customPrimary z-10 bg-white'
                                    : 'bg-white'
                            }`}
                        >
                            <h3 className="font-display ">Hardware news</h3>
                        </div>
                        {/* Tab 6 */}
                        <div
                            onClick={() => setActiveTab('Content ideas')}
                            className={`cursor-pointer text-center py-2 px-2 ${
                                activeTab === 'Content ideas'
                                    ? 'border-b-4 border-customPrimary z-10 bg-white'
                                    : 'bg-white'
                            }`}
                        >
                            <h3 className="font-display ">Content ideas</h3>
                        </div>
                    </div>
                    <Divider />
                </div>
            </div>
            {/* Fourth row */}
            <div
                className="w-full "
                style={{
                    background: 'linear-gradient(to bottom, white, white 160px, transparent 160px)',
                }}
            >
                <BlogList category={activeTab} />
            </div>

            {/* Sixth row */}
            <div
                className="w-full pt-20 pb-44 relative bg-cover bg-center h-screen flex justify-center"
                style={{ backgroundImage: `url(${GetStartedBg})`, height: '511px' }}
            >
                <GetStarted />
            </div>
            {/* <div className="w-full pt-10 pb-10 ">
                <BottomSignUp />
            </div> */}
        </div>
    );
};
export default Blogs;
