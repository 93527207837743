import {
    Badge,
    Chip,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { GrDown, GrEdit, GrNext, GrSystem, GrTrash, GrUnlock } from 'react-icons/gr';
import { LocationDeviceList, LocationDialog } from './';
import React, { useEffect, useState } from 'react';

import { DeleteDialog } from '../utils';
import EditDeviceDialog from './EditDeviceDialog';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GetLocationInfo } from '../../../api/api';
import { MdOutlineImportantDevices } from 'react-icons/md';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { set } from 'lodash';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../../../contexts/ContextProvider';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    // '&:not(:last-child)': {
    // 	borderBottom: 0,
    // },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<GrDown sx={{ fontSize: '0.9rem', color: '#12acd7' }} />} {...props} />
))(({ theme }) => ({
    marginTop: '10px',
    marginBottom: '10px',
    // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -16,
        top: 12,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

/**
 *
 * Location list
 * @param {currentUser, locationListDetail, triggerUpdate, setTriggerUpdate, locationList }
 * @returns
 *
 */

const Locations = ({ currentUser, triggerUpdate, setTriggerUpdate, locationList, switchUser }) => {
    const [expanded, setExpanded] = useState();
    const [groupId, setGroupId] = useState();
    console.log('Locations => groupId:', groupId);

    const [locationDetail, setLocationDetail] = useState();
    const handleLocationInfo = async (group_id) => {
        const data = await GetLocationInfo(switchUser, group_id);
        console.log('data:', data);
        if (data.code === 0) {
            setLocationDetail(data.data);
            // localStorage.setItem('locationDetail', JSON.stringify(data.data));
        }
    };

    const handleExpanded = (item) => {
        console.log('item:', item);
        if (expanded === item.group_id) {
            console.log('handleExpanded => expanded:', expanded);

            setExpanded();
        } else {
            setExpanded(item.group_id);
            setLocationDetail({});
            item.device_count !== 0 && handleLocationInfo(item.group_id);
        }
    };
    useEffect(() => {
        setExpanded(groupId ? groupId : 0);
        handleLocationInfo(groupId);
    }, [triggerUpdate]);

    //handle Open Location Dialog
    const [openLocation, setOpenLocation] = useState(false);
    // const [addedLocation, setAddedLocation] = useState(false);
    const handleOpenLocationDialog = (item) => {
        console.log('item', item);
        setGroupId(item.group_id);
        handleLocationInfo(item.group_id);
        // localStorage.setItem('locationDetail', JSON.stringify(locationDetail));
        setOpenLocation(true);
    };
    const handleCloseLocationDialog = () => {
        setOpenLocation(false);
    };

    //delete location
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const handleOpenDeleteDialog = (item) => {
        console.log('item', item);
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    return (
        <>
            {locationList?.map((item, index) => (
                <Accordion
                    key={index + item}
                    expanded={expanded === item.group_id}
                    onChange={(e) => {
                        e.stopPropagation();
                        handleExpanded(item);
                    }}
                >
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <div className="flex justify-between w-full">
                            <div className="flex flex-row">
                                <FaMapMarkerAlt className="mt-1 mr-1" color="#12acd7" />
                                <Typography>
                                    <StyledBadge badgeContent={item.device_count} color="primary">
                                        {item.group_name}
                                    </StyledBadge>
                                </Typography>
                            </div>
                            <div className="flex flex-row " onClick={(e) => e.stopPropagation()}>
                                <Tooltip title="Edit" onClick={() => handleOpenLocationDialog(item)}>
                                    <IconButton size="small">
                                        <GrEdit label="Edit" size={15} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete" onClick={() => handleOpenDeleteDialog(item)}>
                                    <IconButton size="small">
                                        <GrTrash size={15} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="flex flex-row">
                            <p className="text-sm text-stone-700">Campaign: </p>
                            {item?.campaign?.length === 0 ? (
                                <p className="text-sm">Not Assigned</p>
                            ) : (
                                item?.campaign?.map((campaign, index) => (
                                    <ListItem sx={{ padding: '0 4px', width: 'auto' }} key={campaign + index}>
                                        <Chip size="small" color="primary" label={campaign.schedule_name} />
                                    </ListItem>
                                ))
                            )}
                        </div>
                        <LocationDeviceList
                            locationDetail={locationDetail}
                            currentUser={currentUser}
                            triggerUpdate={triggerUpdate}
                            setTriggerUpdate={setTriggerUpdate}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
            {openLocation && (
                <LocationDialog
                    openLocation={openLocation}
                    handleCloseLocationDialog={handleCloseLocationDialog}
                    locationDetail={locationDetail}
                    currentUser={currentUser}
                    setTriggerUpdate={setTriggerUpdate}
                    triggerUpdate={triggerUpdate}
                    setLocationDetail={setLocationDetail}
                    text={'Edit'}
                />
            )}
            {openDeleteDialog && (
                <DeleteDialog
                    openDialog={openDeleteDialog}
                    handleCloseDialog={handleCloseDeleteDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    text={'location'}
                    deleteItem={deleteItem}
                    triggerUpdate={triggerUpdate}
                />
            )}
        </>
    );
};

export default Locations;
