import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    LinearProgress,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UploadFileInput, UploadedFilesTable } from './';
import { alpha, styled } from '@mui/material/styles';

import LazyLoad from 'react-lazyload';

const ImageListItemBarCenter = styled(ImageListItemBar)(({ theme }) => ({
    ' .MuiImageListItemBar-titleWrap': {
        padding: '0',
    },
    '& .MuiImageListItemBar-title': {
        textAlign: 'center',
        fontSize: '12px',
    },
    '& .MuiImageListItemBar-subtitle': {
        lineHeight: '1.5',
        fontSize: '10px',
    },
}));

const UploadExternalSource = ({
    imageURLs,
    setImageURLs,
    BorderLinearProgress,
    uploadFailed,
    uploadFailedMsg,
    formatFileSize,
    UploadIcon,
}) => {
    const [externalURLs, setExternalURLs] = useState([]);
    const [externalURLInput, setExternalURLInput] = useState('');
    const [srcs, setSrcs] = useState([]);
    const [isError, setIsError] = useState(false);
    console.log('UploadExternalSource => srcs:', srcs);
    // const [imageURLs, setImageURLs] = useState([]);
    console.log('UploadExternalSource => imageURLs:', imageURLs);

    const handleInputChange = (e) => {
        setExternalURLInput(e.target.value);
    };
    const createFileFromBlob = (blob, fileName) => {
        return new File([blob], fileName, { type: blob.type });
    };
    let fileName;
    const handleClick = async () => {
        setIsError(false);
        if (!externalURLInput) return; // 确保输入的URL不为空

        try {
            const fileName = externalURLInput.split('/').pop().split('#')[0].split('?')[0];
            console.log('File name:', fileName);
            const response = await fetch(
                externalURLInput,
                // {
                // mode: 'no-cors', // 添加这行代码
                // }
            );
            console.log('handleClick => response:', response);
            const blob = await response.blob(); // 获取Blob对象
            console.log('handleClick => blob:', blob);
            setSrcs((prevSrcs) => [...prevSrcs, new File([blob], fileName, { type: blob.type })]); // 添加新的Base64数据到数组

            console.log('Resolved file name:', fileName);

            setExternalURLs((prevURLs) => [...prevURLs, externalURLInput]); // 添加新的URL到数组
            setExternalURLInput(''); // 清空输入框
        } catch (error) {
            console.error('Error fetching or converting the file:', error);
            console.log('Not supported files');
            setIsError(true);
        }
    };

    useEffect(() => {
        if (srcs.length < 1) return;
        console.log('file: UploadDialog.jsx:179 => useEffect => srcs:', srcs);

        const newImageUrls = srcs.map((image) => {
            const newImage = {
                file_type: image?.type?.split('/')[0] === 'application' ? 'file' : image?.type?.split('/')[0],
                file_url: image,
                file_name: image?.name,
                file_size: image?.size,
                file_width: 0,
                file_height: 0,
                file_duration: 0,
                progress: 0,
                uploadStatus: 'Ready to upload',
            };

            if (newImage.file_type === 'video') {
                const url = URL.createObjectURL(image);
                const video = document.createElement('video');

                video.addEventListener('loadedmetadata', () => {
                    newImage.file_duration = Math.floor(video.duration);
                });

                video.src = url;
                video.load();
            }
            return newImage;
        });
        console.log('file: UploadDialog.jsx:146 => Promise.all => newImageUrls:', newImageUrls);
        setImageURLs(newImageUrls);
    }, [srcs]);

    return (
        <Stack sx={{ minHeight: '400px', maxHeight: '400px' }}>
            <div className="flex flex-row w-full mb-4">
                <div className="w-3/4">
                    <UploadFileInput
                        error={isError}
                        fullWidth
                        placeholder="Enter Remote URL"
                        name="externalURL"
                        value={externalURLInput}
                        onChange={handleInputChange}
                        helperText={isError ? 'Not supported files' : ''}
                    />
                </div>
                <div className="w-1/4 max-h-[37px] h-[37px]">
                    <Button
                        fullWidth
                        sx={{ marginLeft: '8px', height: '100%' }}
                        variant="contained"
                        onClick={handleClick}
                    >
                        Apply
                    </Button>
                </div>
            </div>
            <Stack>
                <ImageList
                    sx={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        overflowX: 'scroll',
                        padding: '10px',
                        backgroundColor: imageURLs.length ? 'rgb(248, 249, 249)' : '#ffffff',
                        // minHeight: '235px',
                    }}
                    cols={imageURLs.length}
                    gap={10}
                    rowHeight={'auto'}
                    // sx={{ padding: '8px' }}
                >
                    {imageURLs.map((imageSrc, index) => {
                        console.log('imageSrc:', imageSrc);
                        const url = URL.createObjectURL(imageSrc.file_url);
                        return (
                            <ImageListItem
                                key={imageSrc.file_url + index}
                                className="cursor-pointer hover:bg-gray-200 "
                                sx={{
                                    // border: '2px solid rgba(18, 188, 215, 0.2)',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'rgba(18, 188, 215, 0.2)',
                                    },
                                    backgroundColor: '#ffffff',
                                    minHeight: '200px',
                                    maxHeight: '200px',
                                    minWidth: '200px',
                                }}
                                // onClick={() => handleChooseMedia(item)}
                            >
                                <div className="flex h-full w-full justify-center items-center">
                                    <LazyLoad>
                                        <img
                                            src={url}
                                            // srcSet={`${item.resource_url}?w=248&dpr=2 2x`}
                                            // alt={item.resource_name}
                                            loading="lazy"
                                            className="max-w-full items-center p-3"
                                            style={{ maxHeight: '150px' }}
                                        />
                                    </LazyLoad>
                                </div>

                                <ImageListItemBarCenter
                                    title={imageSrc.file_name}
                                    position="below"
                                    sx={{
                                        background: 'transparent',
                                        padding: '6px',
                                    }}
                                />
                            </ImageListItem>
                        );
                    })}
                </ImageList>
            </Stack>
            {imageURLs.length > 0 && (
                <div style={{ maxHeight: '240px', overflowY: 'auto', marginTop: '10px' }}>
                    <hr />
                    <UploadedFilesTable
                        imageURLs={imageURLs}
                        uploadFailed={uploadFailed}
                        uploadFailedMsg={uploadFailedMsg}
                    />
                </div>
            )}
        </Stack>
    );
};

export default UploadExternalSource;
