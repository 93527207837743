import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    CopyCampaign,
    GetCampaignList,
    GetCampaignPlaybackGroupList,
    deleteCampaign,
    updateCampaign,
} from '../api/api';
// import { CopyDialog, DeleteDialog } from '../components/dashboard/utils';
import React, { useEffect, useState } from 'react';
import { RiDeleteBin6Fill, RiFolder3Fill, RiSettings2Fill } from 'react-icons/ri';

import { CampaignList } from '../components/dashboard/campaign';
import { GrCopy } from 'react-icons/gr';
import { Header } from '../components/dashboard';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { TbFileExport } from 'react-icons/tb';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0.5),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CampaignManager = () => {
    const { currentUser } = useStateContext();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    const navigate = useNavigate();
    // const [templateView, setTemplateView] = useState(false);
    const handleAddCampaign = () => {
        // setTemplateView(!templateView);
        navigate('../dashboard/template');
    };

    const [campaignList, setCampaignList] = useState([]);
    console.log('campaignList', campaignList);
    const FetchCampaignList = async () => {
        const { code, data } = await GetCampaignList(switchUser, undefined, 50);
        console.log('data:', data);
        const list = data?.list;
        if (code === 0) {
            Array.isArray(list) && setCampaignList(list);
        }
    };
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    useEffect(() => {
        FetchCampaignList();
    }, [triggerUpdate]);

    // Handle Expand area
    const [expanded, setExpanded] = useState('');

    const handleExpand = (panel) => (newExpanded) => {
        if (expanded !== panel) {
            setExpanded(newExpanded ? panel : false);
            console.log('panel', panel);
            setLocationList([]);
            getLocationList(panel);
        } else setExpanded();

        // getLocationDetails(panel.group_id);
    };

    // Get list of Locations under campaign
    const [locationList, setLocationList] = useState([]);
    console.log('locationList', locationList);

    // From API get data
    const getLocationList = async (panel) => {
        const data = await GetCampaignPlaybackGroupList(switchUser, panel.campaign_id);
        console.log('data', data);
        setLocationList(data.data.list);
    };

    return (
        <>
            <div className="m-10 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Start new campaign" />
                <div className="p-4 pt-0">
                    <p className="font-medium">
                        Click the "Add Campaign" button to start a new campaign
                    </p>
                    <div>
                        <Button variant="contained" onClick={handleAddCampaign}>
                            Add Campaign
                        </Button>
                    </div>
                </div>
            </div>
            <div className="m-10 md:m-10 mt-8 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Campaign Manager" />
                <div className="p-4 pt-0">
                    {campaignList.map((item, index) => (
                        <CampaignList
                            key={item.campaign_id}
                            item={item}
                            locationList={locationList}
                            expanded={expanded}
                            handleExpand={handleExpand}
                            currentUser={currentUser}
                            // handleCopyCampaign={handleCopyCampaign}
                            // handleDeleteCampaign={handleDeleteCampaign}
                            triggerUpdate={triggerUpdate}
                            setTriggerUpdate={setTriggerUpdate}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default CampaignManager;
