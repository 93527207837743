import { BottomSignUp, GetStarted, News } from '../components/website';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import GetStartedBg from '../images/GetStarted.png';
import HowWork1 from '../images/howwork1.png';
import HowWork2 from '../images/howwork2.png';
import HowWork3 from '../images/howwork3.png';
import HowWork4 from '../images/howworkor.png';
import HowWork5 from '../images/howwork5.png';
import HowWork6 from '../images/howwork6.png';
import { Stack } from '@mui/material';
import TutorialVideo from '../images/tutorial.mp4';
import WatchDemo from '../images/watchDemo.svg';
import { useStateContext } from '../contexts/ContextProvider';

const HowWork = () => {
    return (
        <div className="flex flex-col items-center w-full bg-custom-bg">
            {/* First row- Typography */}
            <div className="max-w-6xl mx-auto justify-around space-y-8 text-center items-center py-25 px-2">
                <div className="max-w-5xl mx-auto">
                    <h6 className="font-display font-bold text-4xl mb-3 md:text-5xl">
                        How it works{' '}
                    </h6>
                    <span>Get started with Digital Signage in 4 easy steps</span>
                    <div className="items-center justify-center mt-8 h-20 mx-auto flex space-x-6">
                        {/* <Button variant="contained" href="/" color='primary' style={{ color: '#ffffff', fontSize: '16px', fontWeight: 500, padding: '20px 32px', }} >
                            Get Started
                        </Button> */}
                        <Button
                            className="flex items-center"
                            variant="outlined"
                            style={{ borderColor: 'white', borderWidth: '2px' }}
                        >
                            <img src={WatchDemo} alt="icon" className="w-4 h-4 m-3" />
                            <span className="font-display font-bold text-base mr-3 text-customPrimary">
                                {' '}
                                Watch Demo
                            </span>
                        </Button>
                    </div>
                </div>
            </div>

            {/* Second row */}
            <div className="w-full pt-16 pb-16 bg-white h-150">
                <div className="max-w-6xl justify-between items-center mx-auto flex">
                    <div className="w-2/5 mr-auto">
                        <h3 className="font-display font-bold text-2xl mb-4 text-customPrimary">
                            {' '}
                            Step 1{' '}
                        </h3>
                        <h6 className="font-display font-bold text-3xl md:text-4xl">
                            Create a free Cybercast Account
                        </h6>
                        <p className="mt-3 mb-4">
                            Showcase your content exactly how you want to, with templates for all
                            screens and dimensions.
                        </p>
                    </div>
                    <img src={HowWork1} alt="image" className="w-1/2 ml-auto" />
                </div>
            </div>

            {/* Third row */}
            <div className="max-w-6xl mx-auto mt-24 text-center">
                <h6 className="font-display font-bold text-2xl mb-4 text-customPrimary">Step 2</h6>
                <h6 className="font-display font-bold text-3xl mb-2 md:text-4xl">
                    Connect your Cybercast Account with…
                </h6>
            </div>
            <div className="max-w-6xl flex flex-col sm:flex-row justify-between items-center mt-14 mb-24">
                <div
                    className="items-center mb-auto"
                    style={{ maxWidth: '500px', minWidth: '220px' }}
                >
                    <img src={HowWork2} alt="image" />
                    <div className="w-full pt-5 text-center space-y-3 px-6">
                        <span className="font-display font-bold text-2xl">
                            Your own Screen via our Digital Signage Players
                        </span>
                        <p>
                            Import content to your library from a file upload, shared link or
                            YouTube link{' '}
                        </p>
                    </div>
                    <div className="w-full px-5 text-center">
                        <a href="/" className="font-semibold text-customPrimary">
                            Learn More →
                        </a>
                    </div>
                </div>
                <img src={HowWork4} alt="image" className="w-20 m-4" />
                <div
                    className="items-center mb-auto"
                    style={{ maxWidth: '500px', minWidth: '220px' }}
                >
                    <img src={HowWork3} alt="image" />
                    <div className="w-full pt-5 text-center space-y-3 px-6">
                        <span className="font-display font-bold text-2xl">
                            Our Range of Pre-configured Hardware
                        </span>
                        <p>
                            Import content to your library from a file upload, shared link or
                            YouTube link{' '}
                        </p>
                    </div>
                    <div className="w-full px-5 text-center">
                        <a href="/" className="font-semibold text-customPrimary">
                            Explore the Range →
                        </a>
                    </div>
                </div>
            </div>
            {/* Fourth row */}
            <div className="w-full pt-12 bg-white pb-24 h-180">
                <div className="max-w-6xl justify-between items-center mx-auto flex">
                    <div className="w-2/5 mr-auto">
                        <h3 className="font-display font-bold text-2xl mb-4 text-customPrimary">
                            {' '}
                            Step 3{' '}
                        </h3>
                        <h6 className="font-display font-bold text-3xl md:text-4xl">
                            Design your content
                        </h6>
                        <p className="mt-3 mb-4">
                            Showcase your content exactly how you want to, with templates for all
                            screens and dimensions.
                        </p>
                        <Stack spacing={2} sx={{ mt: 5 }}>
                            <div className="flex items-center space-x-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="27"
                                    height="27"
                                    viewBox="0 0 27 27"
                                    fill="none"
                                >
                                    <path
                                        d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                                        fill="#01B5E2"
                                    />
                                    <path
                                        d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <h3 className="font-display font-bold text-xl text-customPrimary">
                                    1000+ templates to choose from
                                </h3>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="27"
                                    height="27"
                                    viewBox="0 0 27 27"
                                    fill="none"
                                >
                                    <path
                                        d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                                        fill="#01B5E2"
                                    />
                                    <path
                                        d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>

                                <h3 className="font-display font-bold text-xl text-customPrimary">
                                    Import Content from multiple sources
                                </h3>
                            </div>
                        </Stack>
                        <Button
                            size="large"
                            variant="contained"
                            href="/"
                            color="primary"
                            style={{
                                color: '#ffffff',
                                fontSize: '16px',
                                fontWeight: 500,
                                whiteSpace: 'nowrap',
                                padding: '20px 32px',
                                marginTop: '32px',
                            }}
                        >
                            View Tempaltes
                        </Button>
                    </div>
                    <img src={HowWork5} alt="image" className="w-1/2 ml-auto" />
                </div>
            </div>
            {/* Fifth row */}
            <div className="w-full pt-12 pb-12 h-156">
                <div className="max-w-6xl justify-between items-center mx-auto flex">
                    <img src={HowWork6} alt="image" className="w-1/2 mr-auto" />

                    <div className="w-2/5 ml-auto">
                        <h3 className="font-display font-bold text-2xl mb-4 text-customPrimary">
                            {' '}
                            Step 4{' '}
                        </h3>
                        <h6 className="font-display font-bold text-3xl md:text-4xl">
                            Publish your content
                        </h6>
                        <p className="mt-3 mb-4">
                            With direct access to our Developers, Technicians and Designers, you’ll
                            always feel supported.
                        </p>
                        <Stack spacing={2} sx={{ mt: 5 }}>
                            <div className="flex items-center space-x-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="27"
                                    height="27"
                                    viewBox="0 0 27 27"
                                    fill="none"
                                >
                                    <path
                                        d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                                        fill="#01B5E2"
                                    />
                                    <path
                                        d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <h3 className="font-display font-bold text-xl text-customPrimary">
                                    Schedule and manage ahead
                                </h3>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="27"
                                    height="27"
                                    viewBox="0 0 27 27"
                                    fill="none"
                                >
                                    <path
                                        d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                                        fill="#01B5E2"
                                    />
                                    <path
                                        d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>

                                <h3 className="font-display font-bold text-xl text-customPrimary">
                                    not provided!!!!!
                                </h3>
                            </div>
                        </Stack>
                        <Button
                            size="large"
                            variant="contained"
                            href="/"
                            color="primary"
                            style={{
                                color: '#ffffff',
                                fontSize: '16px',
                                fontWeight: 500,
                                whiteSpace: 'nowrap',
                                padding: '20px 32px',
                                marginTop: '32px',
                            }}
                        >
                            View Tempaltes
                        </Button>
                    </div>
                </div>
            </div>
            {/* Sixth row */}
            <div className="w-full bg-white mb-12">
                <div className="max-w-6xl mx-auto py-25 text-center ">
                    <h6 className="font-display font-bold text-2xl mb-3 ">
                        Watch our video tutorial
                    </h6>
                    <video
                        autoPlay
                        loop
                        muted
                        className="w-full object-cover rounded-xl"
                        style={{ objectPosition: 'center' }}
                    >
                        <source src={TutorialVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            {/* left row */}
            {/* <div className="w-full pt-16 pb-44">
                <News />
            </div> */}
            {/* Get started*/}
            <div
                className="w-full pt-20 pb-44 relative bg-cover bg-center h-screen flex justify-center"
                style={{ backgroundImage: `url(${GetStartedBg})`, height: '511px' }}
            >
                <GetStarted />
            </div>
            {/* bottom Get started*/}
            {/* <div className="w-full pt-10 pb-10 ">
                <BottomSignUp />
            </div> */}
        </div>
    );
};
export default HowWork;
