import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { AiFillDelete, AiFillEdit, AiFillFolder, AiFillFolderOpen } from 'react-icons/ai';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { RiDeleteBin6Fill } from 'react-icons/ri';
import _ from 'lodash';

const width = 40;
const height = 67.5;

const ResponsiveGrid = WidthProvider(Responsive);

const Landscape = ({
    handleDragStop,
    handleModify,
    widgetArray,
    handleOpenEdit,
    onRemoveItem,
    setCurrentTemplate,
    editedElement,
    handleEditElement,
    text,
}) => {
    console.log('================================================================:', widgetArray);
    return (
        <Box
            sx={{
                marginTop: '12px',
                //1920x1080
                width: 768,
                height: 500,
                backgroundColor: 'primary.white',
                border: '5px solid gray',
                '&:hover': {
                    backgroundColor: 'primary.dark',
                    opacity: [0.9, 0.8, 0.7],
                },
            }}
        >
            <ResponsiveGrid
                className="layout"
                compactType="horizontal"
                allowOverlap={true}
                measureBeforeMount={true}
                autoSize={false}
                rowHeight={20}
                cols={{ xs: 48, xxs: 48 }}
                resizeHandles={['s', 'e', 'se']}
                maxRows={16}
                onDragStop={(e) => handleDragStop(e)}
                onLayoutChange={(layouts) => handleModify(layouts)}
                width={100}
            >
                {widgetArray?.map((widget, index) => (
                    <div
                        className="reactGridItem bg-gray-100"
                        key={widget.element_alias}
                        data-grid={{
                            x: widget?.element_x / width,
                            y: widget?.element_y / height,
                            w: widget?.element_width / width,
                            h: widget?.element_height / height,
                            i: widget.element_alias,
                            minW: 1,
                            minH: 1,
                            isDraggable: true,
                            isResizable: true,
                        }}
                        style={{ zIndex: widget.element_layer }}
                    >
                        <div className="bg-slate-300 flex justify-between">
                            <div className=" ml-2">
                                <p className="text-xs m-0 mt-1">
                                    {widget.element_type === 'mix'
                                        ? 'Image & video'
                                        : widget.element_type.charAt(0).toUpperCase() +
                                          widget.element_type.slice(1)}{' '}
                                    Layer {widget?.element_layer}
                                </p>
                            </div>
                            <div className="mr-2 min-w-fit">
                                <Tooltip title="Edit" onClick={() => handleOpenEdit(widget)}>
                                    <IconButton style={{ fontSize: 14 }}>
                                        <AiFillEdit label="Edit" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete" onClick={() => onRemoveItem(widget)}>
                                    <IconButton style={{ fontSize: 14 }}>
                                        <RiDeleteBin6Fill />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ))}
            </ResponsiveGrid>
        </Box>
    );
};

export default Landscape;
