import React from 'react';

function Test3Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <g cliPath="url(#clip0_893_328)">
    <path d="M2.66699 13.4999C2.66699 13.0579 2.84259 12.634 3.15515 12.3214C3.46771 12.0088 3.89163 11.8333 4.33366 11.8333H13.3337" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.33366 1.83325H13.3337V15.1666H4.33366C3.89163 15.1666 3.46771 14.991 3.15515 14.6784C2.84259 14.3659 2.66699 13.9419 2.66699 13.4999V3.49992C2.66699 3.05789 2.84259 2.63397 3.15515 2.32141C3.46771 2.00885 3.89163 1.83325 4.33366 1.83325V1.83325Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_893_328">
      <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
  </defs>
</svg>
  )}

export default Test3Icon;