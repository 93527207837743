import React from 'react';

function Test1Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
  <path d="M13.1667 2.5H3.83333C3.09695 2.5 2.5 3.09695 2.5 3.83333V13.1667C2.5 13.903 3.09695 14.5 3.83333 14.5H13.1667C13.903 14.5 14.5 13.903 14.5 13.1667V3.83333C14.5 3.09695 13.903 2.5 13.1667 2.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.5 5.83337V11.1667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M5.83301 8.5H11.1663" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )}

export default Test1Icon;