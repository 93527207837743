import React from 'react';

function Test2Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <g clipPath="url(#clip0_893_384)">
    <path d="M12 5.83325H12.6667C13.3739 5.83325 14.0522 6.1142 14.5523 6.6143C15.0524 7.1144 15.3333 7.79267 15.3333 8.49992C15.3333 9.20716 15.0524 9.88544 14.5523 10.3855C14.0522 10.8856 13.3739 11.1666 12.6667 11.1666H12" stroke="white" strokeLinecap="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.33301 5.83325H11.9997V11.8333C11.9997 12.5405 11.7187 13.2188 11.2186 13.7189C10.7185 14.219 10.0403 14.4999 9.33301 14.4999H3.99967C3.29243 14.4999 2.61415 14.219 2.11406 13.7189C1.61396 13.2188 1.33301 12.5405 1.33301 11.8333V5.83325Z" stroke="white" strokeLinecap="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 1.16675V3.16675" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.66699 1.16675V3.16675" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.33301 1.16675V3.16675" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_893_384">
      <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
  </defs>
</svg>
  )}

export default Test2Icon;