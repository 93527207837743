import { BottomSignUp, GetStarted, PriceComparison, PriceTag } from '../components/website';
import { Card, CssBaseline, Divider, MobileStepper, Stack, Typography } from '@mui/material';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import GetStartedBg from '../images/GetStarted.png';
import Solutions from '../images/Solutions.png';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../contexts/ContextProvider';

const HomePage = () => {
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 66,
        height: 33,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 66,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(34px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#00BCE3',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 28,
            height: 28,
            borderRadius: 20,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 20,
            opacity: 1,
            backgroundColor: '#00BCE3',
            boxSizing: 'border-box',
        },
    }));

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    return (
        <div className="flex flex-col items-center w-full bg-custom-bg">
            {/* First Row - Typography */}
            <div className="max-w-6xl mx-auto justify-around space-y-8 text-center items-center py-25 px-2">
                <h6 className="font-display font-bold text-2xl text-customPrimary">PRICING</h6>
                <div className="max-w-5xl mx-auto">
                    <h6 className="font-display font-bold text-4xl mb-4 md:text-5xl">
                        Simple, transparent pricing{' '}
                    </h6>
                    <p>Discover what our innovative CMS platform can offer you</p>
                </div>
                <div className="flex space-x-2 items-center justify-center">
                    <Typography>General</Typography>
                    <AntSwitch
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'ant design' }}
                    />
                    <Typography>Business</Typography>
                </div>
            </div>

            {/* Second Row- card*/}
            <div className="w-full pt-20 pb-10 bg-white">
                <PriceTag checked={checked} />
            </div>
            {/* Third  Row- comparison*/}
            <PriceComparison checked={checked} />

            {/* Fourth Row- ad*/}
            <div className="w-full pb-20">
                <div className="max-w-5xl mx-auto ">
                    <div
                        className="flex flex-col md:flex-row flex-wrap justify-center items-center p-8 md:p-12 rounded-xl"
                        style={{ backgroundColor: '#E5FBFF' }}
                    >
                        <div
                            className="ml-0 flex-col md:flex "
                            style={{ minWidth: '280px', maxWidth: '600px' }}
                        >
                            <span className="font-display font-bold text-2xl ">
                                Need a Custom Digital Signage Solution?
                            </span>
                            <p className="mt-3 mb-4">
                                Cybercast provides high quality hardware, installation and support
                                services to businesses across Australia every day.
                            </p>
                        </div>
                        <Button
                            className="custom-btn mx-auto md:flex"
                            variant="contained"
                            href="./contact"
                            color="primary"
                            style={{
                                maxWidth: '280px',
                                whiteSpace: 'nowrap',
                                color: '#ffffff',
                                fontSize: '16px',
                                fontWeight: 500,
                                padding: '20px 32px',
                            }}
                        >
                            Contact Us for a Custom Quote
                        </Button>
                    </div>
                </div>
            </div>

            {/* Nineth row  Get started*/}
            <div
                className="w-full pt-20 pb-44 relative bg-cover bg-center h-screen flex justify-center"
                style={{ backgroundImage: `url(${GetStartedBg})`, height: '511px' }}
            >
                <GetStarted />
            </div>
            {/* Tenth row Get started*/}
            {/* <div className="w-full pt-10 pb-10 ">
                <BottomSignUp />
            </div> */}
        </div>
    );
};
export default HomePage;
