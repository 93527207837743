import { CopyDialog, DeleteDialog } from '../utils';
import { MdFavorite, MdOutlineFavoriteBorder } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { RiDeleteBin6Fill, RiEditBoxLine, RiFileCopyFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import CreateTemplateDialog from './CreateTemplateDialog';
import { EditTemplateDialog } from './';
import { FavouriteTemplate } from '../../../api/api';
import { Grid } from '@mui/material';
import { resetMedia } from '../../../redux/action';
import { styled } from '@mui/material/styles';

const TemplateGrid = ({
    item,
    template,
    setExpanded,
    setShowCampaignDesign,
    currentUser,
    triggerUpdate,
    setTriggerUpdate,
}) => {
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const groupType = item.group_type;
    // this state determines whether box B is shown or not
    const [isShown, setIsShown] = useState(false);
    const dispatch = useDispatch();

    // this function is called when the mouse hovers over box A
    const handleMouseOver = (item) => {
        setIsShown(item);
    };

    // this function is called when the mouse out box A
    const handleMouseOut = (item) => {
        setIsShown('');
    };

    // this function is to show * Campaign Design * field
    const handleShowCampaignDesign = (item) => {
        console.log('item', item);
        setShowCampaignDesign(item);
        dispatch(resetMedia());
        localStorage.setItem('CurrentCampaignDesign', JSON.stringify(item));
        setExpanded('');
    };

    //handle Edit template function
    const [openEditTemplateDialog, setOpenEditTemplateDialog] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState();
    const [editText, setEditText] = useState();
    const handleCloseEditTemplateDialog = () => {
        setOpenEditTemplateDialog(false);
    };
    const eidtTemplate = async (item) => {
        console.log(item);
        setCurrentTemplate(item);
        setOpenEditTemplateDialog(!openEditTemplateDialog);
    };
    // open edit template dialog
    const [openCreateTemplateDialog, setOpenCreateTemplateDialog] = useState(false);
    const handleCloseCreateTemplateDialog = () => {
        setOpenCreateTemplateDialog(false);
    };

    const element = template.element_list;

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const handleOpenDeleteDialog = (item) => {
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const [copyItem, setCopyItem] = useState();

    const handleOpenCopyDialog = (item) => {
        setCopyItem(item);
        setOpenCopyDialog(true);
    };

    const handleCloseCopyDialog = () => {
        setOpenCopyDialog(false);
    };

    // Handle favourite template
    const [isHovered, setIsHovered] = useState(false);

    const handleFavourite = async (template) => {
        console.log('Favourite');
        console.log('file: TemplateGrid.jsx:123 => handleFavourite => template:', template);
        // "template_favorite": "0、收藏模板标识、integer类型、0为未收藏模板、1为已收藏模板",
        if (template.template_favorite === 0) {
            const data = await FavouriteTemplate(
                switchUser,
                template.template_id,
                1, //
            );
            if (data.code === 0) {
                setTriggerUpdate(!triggerUpdate);
            }
            console.log('file: TemplateGrid.jsx:134 => handleFavourite => data:', data);
        } else {
            const data = await FavouriteTemplate(
                switchUser,
                template.template_id,
                0, //
            );
            if (data.code === 0) {
                setTriggerUpdate(!triggerUpdate);
            }
            console.log('file: TemplateGrid.jsx:148 => handleFavourite => remove:', data);
        }
    };

    // const

    return (
        <Grid item xs={4}>
            <div
                className="w-full h-full mr-2 mb-4  py-4 "
                style={{
                    minWidth: '240px',
                    maxWidth: '240px',
                    minHeight: '161px',
                    maxHeight: '207px',
                }}
                onMouseEnter={() => handleMouseOver(template)}
                onMouseLeave={() => handleMouseOut(template)}
            >
                <div
                    className="bg-slate-100 h-full w-full flex relative"
                    style={{ height: '137px' }}
                    onClick={() => handleShowCampaignDesign(template)}
                >
                    {element?.map((item, index) => (
                        <div
                            key={index + item}
                            className="cursor-pointer absolute"
                            style={{
                                backgroundColor:
                                    item.element_type === 'image'
                                        ? '#9CA3AF'
                                        : item.element_type === 'video' || item.element_type === 'mix'
                                        ? '#61AFA3'
                                        : item.element_type === 'text'
                                        ? '#FFBD88'
                                        : item.element_type === 'web'
                                        ? '#88C0FF'
                                        : '#9CA3AF',

                                width: (item?.element_width / 100) * 240,
                                height: (item?.element_height / 100) * 137,
                                top: (item?.element_y / 100) * 137,
                                left: (item?.element_x / 100) * 240,
                                maxHeight: '100%',
                                maxWidth: '100%',
                            }}
                        ></div>
                    ))}
                    {isShown === template && (
                        <div
                            className="absolute w-full bottom-0 bg-stone-300 flex flex-row p-1 z-50"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {groupType !== 2 && (
                                <div
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={() => handleFavourite(template)}
                                >
                                    {isHovered || template.template_favorite === 1 ? (
                                        <MdFavorite className="mr-1 cursor-pointer text-red-500" size="20px" />
                                    ) : (
                                        <MdOutlineFavoriteBorder
                                            className={`mr-1 cursor-pointer text-stone-500`}
                                            size="20px"
                                        />
                                    )}
                                </div>
                            )}
                            {groupType === 2 && (
                                <RiDeleteBin6Fill
                                    className="mr-1 cursor-pointer text-stone-500 hover:text-orange-400"
                                    size="20px"
                                    onClick={
                                        () => handleOpenDeleteDialog(template)
                                        // deleteFunc(item)
                                    }
                                />
                            )}
                            {groupType === 2 && (
                                <RiEditBoxLine
                                    className="mr-1 cursor-pointer text-stone-500 hover:text-orange-400"
                                    size="20px"
                                    onClick={() => eidtTemplate(template)}
                                />
                            )}
                            <RiFileCopyFill
                                className={`mr-1cursor-pointer text-stone-500
									${groupType !== 2 ? 'hover:text-red-500' : 'hover:text-orange-400'}`}
                                size="20px"
                                onClick={
                                    () => handleOpenCopyDialog(template)
                                    // copyFunc(item)
                                }
                            />
                        </div>
                    )}
                </div>
                <p className="my-1 text-slate-500 font-semibold" style={{ fontSize: '12px' }}>
                    {template.template_name}
                </p>
                {openDeleteDialog && (
                    <DeleteDialog
                        openDialog={openDeleteDialog}
                        handleCloseDialog={handleCloseDeleteDialog}
                        setTriggerUpdate={setTriggerUpdate}
                        text={'template'}
                        deleteItem={deleteItem}
                        triggerUpdate={triggerUpdate}
                    />
                )}
                {openCopyDialog && (
                    <CopyDialog
                        openCopyDialog={openCopyDialog}
                        handleCloseCopyDialog={handleCloseCopyDialog}
                        setTriggerUpdate={setTriggerUpdate}
                        text={'template'}
                        copyItem={copyItem}
                        triggerUpdate={triggerUpdate}
                    />
                )}
                {openEditTemplateDialog && (
                    <EditTemplateDialog
                        openEditTemplateDialog={openEditTemplateDialog}
                        handleCloseEditTemplateDialog={handleCloseEditTemplateDialog}
                        setOpenCreateTemplateDialog={setOpenCreateTemplateDialog}
                        currentUser={currentUser}
                        currentTemplate={currentTemplate}
                        setTriggerUpdate={setTriggerUpdate}
                        triggerUpdate={triggerUpdate}
                        editText={editText}
                        setEditText={setEditText}
                    />
                )}
                {openCreateTemplateDialog && (
                    <CreateTemplateDialog
                        item={template}
                        openCreateTemplateDialog={openCreateTemplateDialog}
                        handleCloseCreateTemplateDialog={handleCloseCreateTemplateDialog}
                        triggerUpdate={triggerUpdate}
                        setTriggerUpdate={setTriggerUpdate}
                        currentUser={currentUser}
                        currentTemplate={currentTemplate}
                        setCurrentTemplate={setCurrentTemplate}
                        editText={editText}
                        setEditText={setEditText}
                    />
                )}
            </div>
        </Grid>
    );
};

export default TemplateGrid;
