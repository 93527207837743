// import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { Box, Button, Card, List, ListItem, ListItemText, Popover, Stack, Tab, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';
import moment from 'moment';

const ScheduleList = ({ handleOpenScheduleDialog, tab, colors, handleDragEnd, currentScheduleList, getValidLastTwoDigits, removeMeridian }) => {
    return (
        <div className="cols-span-1" style={{ maxHeight: '60vh' }}>
            <div className="flex flex-col justify-center">
                <div className="flex flex-col justify-center items-center">
                    <Typography variant="h6">Schedules</Typography>
                </div>
                <div className="pt-2">
                    <Button
                        variant="contained"
                        sx={{
                            width: '90%',
                            marginRight: '10px',
                            marginLeft: '10px',
                            // marginBottom: '10px',
                            textTransform: 'none',
                            // padding: '8px',
                            fontWeight: '700 !important',
                            // height: '100%',
                        }}
                        onClick={() => handleOpenScheduleDialog(tab)}
                    >
                        <MdAdd size={20} />
                        {/* <p className="m-0 pl-2">New schedule</p> */}
                    </Button>
                </div>
            </div>

            <List
                style={{
                    height: '73vh',
                    overflow: 'scroll',
                    borderRadius: '8px',
                    paddingRight: '4px',
                    marginTop: '10px',
                }}
            >
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                // style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {currentScheduleList?.map((event, index) => (
                                    <Draggable key={event.schedule_id} draggableId={`index` + event.schedule_id} index={index}>
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <ListItem
                                                    key={event.schedule_id}
                                                    sx={{
                                                        backgroundColor: colors.find((x) => x.number === getValidLastTwoDigits(event.schedule_id)).color,
                                                        padding: '10px',
                                                        margin: '5px 10px',
                                                        borderRadius: '4px',
                                                        width: '95%',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleOpenScheduleDialog(tab, event)}
                                                >
                                                    <ListItemText
                                                        sx={{ margin: '0' }}
                                                        primary={
                                                            <Typography variant="h6" mt={0} noWrap title={event.schedule_name} sx={{ fontWeight: '600', margin: '0' }}>
                                                                {event.schedule_name}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <>
                                                                <Typography display="block" color="black" variant="caption">
                                                                    {event.schedule_frequency}
                                                                </Typography>
                                                                {event.schedule_frequency == 'Daily' ? (
                                                                    <Typography variant="caption" display="block" sx={{ fontSize: '10px' }}>
                                                                        {'Duration: ' +
                                                                            moment(event.start_date, 'DD/MMM/YYYY hh:mmA').format('DD/MMM/YYYY') +
                                                                            ' to ' +
                                                                            event.until_date +
                                                                            ' ' +
                                                                            moment(event.start_date, 'DD/MMM/YYYY hh:mmA').format('HH:mm') +
                                                                            '-' +
                                                                            moment(event.end_date, 'DD/MMM/YYYY hh:mmA').format('HH:mm')}
                                                                    </Typography>
                                                                ) : (
                                                                    <>
                                                                        <Typography variant="caption" display="block" sx={{ fontSize: '10px' }}>
                                                                            {'Start: ' + removeMeridian(event.start_date)}
                                                                        </Typography>
                                                                        <Typography variant="caption" display="block" sx={{ fontSize: '10px' }}>
                                                                            {'End: ' + removeMeridian(event.end_date)}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {/* ))} */}
            </List>
        </div>
    );
};

export default ScheduleList;
