import * as React from 'react';

import { Button, Container, Typography } from '@mui/material';

import AWS from 'aws-sdk';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import ListSubheader from '@mui/material/ListSubheader';
import { Resize } from './Api';
import Slider from '@mui/material/Slider';
import { SplitImage } from './Api';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import config from '../../../config/config';
import { useState } from 'react';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function ImageSegmentation({ user_id }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const [imageResolution, setImageResolution] = useState({
        width: null,
        height: null,
    });

    const [new1, setnew1] = useState(0);

    const [row, setrow] = useState(0);
    const [column, setcolumn] = useState(0);

    const [imageSize, setImageSize] = useState(null);

    const [itemData, setitemData] = useState(null);

    const [alertPNG, setalertPNG] = useState(false);

    const [loading, setloading] = useState(false);
    const [processing, setprocessing] = useState(null);

    const [S3URL, setS3URL] = useState(null);
    const [progress, setProgress] = React.useState(0);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setalertPNG(false);
        // Display image preview
        const reader = new FileReader();
        reader.onloadend = () => {
            //setImagePreview(reader.result);
        };
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= 20000000) {
            reader.readAsDataURL(file);

            // Get image resolution
            const img = new Image();
            img.onload = () => {
                setImageResolution({ width: img.width, height: img.height });
                setImageSize(file.size);
                console.log({ width: img.width, height: img.height });

                const upload = uploadS3(file);
            };
            img.src = URL.createObjectURL(file);
        } else {
            // Handle the case when an invalid file type is selected (not a PNG file)
            reader.readAsDataURL(file);

            // Get image resolution
            const img = new Image();
            img.onload = () => {
                setImageResolution({ width: img.width, height: img.height });
                setImageSize(file.size);
                console.log({ width: img.width, height: img.height });
            };
            img.src = URL.createObjectURL(file);

            setalertPNG(true);
            // Optionally, you can display a message to the user or take other actions.
        }
    };

    const uploadS3 = async (selectedFile) => {
        const S3_BUCKET = config[process.env.REACT_APP_ENV].REACT_APP_S3_BUCKET;

        const REGION = config[process.env.REACT_APP_ENV].REACT_APP_REGION;

        const filteredFilename = selectedFile.name.replace(/[^a-zA-Z0-9.]/g, '');

        AWS.config.update({
            accessKeyId: config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId,
            secretAccessKey: config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey,
        });

        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            //ACL: "public-read",
            Body: selectedFile,
            Bucket: S3_BUCKET,
            Key: user_id + '/' + filteredFilename,
        };
        console.log(params);

        //const data = myBucket.putObject(params);
        //console.log(data);
        setloading(true);

        var upload = myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt) => {
                console.log('Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%');
                setProgress(parseInt((evt.loaded * 100) / evt.total));
            })
            .promise();

        const response = await upload.then((err, data) => {
            console.log(err);
            console.log(upload);

            setloading(false);
            setProgress(0);
            setImagePreview(true);
        });

        setS3URL(
            'https://' + S3_BUCKET + '.s3.' + REGION + '.amazonaws.com/' + user_id + '/' + filteredFilename,
        );
        console.log(
            'https://' + S3_BUCKET + '.s3.' + REGION + '.amazonaws.com/' + user_id + '/' + filteredFilename,
        );
        //return data;
    };

    const handleUpload = async () => {
        // Implement your upload logic here
        // For example, you can use the FormData API to send the file to a server
        if (selectedFile) {
            const body = {
                image_url: S3URL,
                user_id: user_id,
                row: column, //column
                column: row, // row
            };
            console.log('body:', body);

            try {
                setprocessing(true);
                const data = await SplitImage(body);

                setprocessing(false);

                console.log('Resized Image Data:', data['message']);

                //const b = data["message"].map((img, index) => ({ img }));
                const sortedImages = data['message'].sort((a, b) => a.column - b.column);

                const maxColumn = Math.max(...sortedImages.map((item) => item.row));
                setnew1(maxColumn + 1);
                setitemData(sortedImages);
                console.log('sortedImages', sortedImages);

                //   setitemData([
                //     {
                //       img: data["message"][0],
                //       title: "Burger",
                //       author: "@rollelflex_graphy726",
                //     },
                //     {
                //       img: data["message"][1],
                //       title: "Burger",
                //       author: "@rollelflex_graphy726",
                //     },
                //   ]);
                //setResizedImage(data.message);

                // Handle the resized image data as needed
            } catch (error) {
                console.error('Error while resizing:', error);

                // Handle the error
            }
        }
    };

    const handleRefresh = () => {
        setitemData([]);
        setSelectedFile(null);
        setImagePreview(null);
        setalertPNG(false);
        console.log(row);
    };

    const handledownload = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'resized_image.jpg';
        link.click();
    };

    return (
        <div>
            <Container>
                <Typography variant="h5">Please Select your file</Typography>
                {selectedFile == null && (
                    <>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-button"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="upload-button">
                            <Button variant="contained" component="span">
                                Choose File
                            </Button>
                        </label>
                    </>
                )}
                {selectedFile !== null && (
                    <>
                        <Button variant="contained" color="error" onClick={handleRefresh}>
                            Clear
                        </Button>
                    </>
                )}

                <Typography variant="body1" gutterBottom>
                    {selectedFile && `Selected File: ${selectedFile.name}`}
                </Typography>
                {alertPNG == true && (
                    <div>
                        <Alert severity="error">
                            Please select a PNG/JPG file and size is less than 5MB{' '}
                        </Alert>
                    </div>
                )}
                {loading == true && (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={progress} />
                    </Box>
                )}

                {imagePreview && (
                    <>
                        <img
                            src={S3URL}
                            alt="Preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                marginTop: '10px',
                            }}
                        />
                        {imageResolution.width !== null && imageResolution.height !== null && (
                            <div>
                                <Typography variant="body1" gutterBottom>
                                    Image Resolution: {imageResolution.width} x {imageResolution.height}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Image Size: {(imageSize / 1024 / 1024).toFixed(2)} MB
                                </Typography>

                                <TextField
                                    id="outlined-basic"
                                    label="Row Size"
                                    type="number" // Allow only numbers
                                    //defaultValue={(imageSize / 1024 / 1024).toFixed(2)}
                                    value={row}
                                    onChange={(e) => {
                                        // Use a regular expression to filter out non-numeric characters
                                        const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                        if (e.target.value > 5) {
                                            setrow(5);
                                            console.log('row', e.target.value);
                                        } else {
                                            setrow(e.target.value);
                                            console.log('row', e.target.value);
                                        }
                                    }}
                                    variant="outlined"
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Column Size"
                                    type="number" // Allow only numbers
                                    //defaultValue={(imageSize / 1024 / 1024).toFixed(2)}
                                    value={column}
                                    onChange={(e) => {
                                        // Use a regular expression to filter out non-numeric characters
                                        const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                        if (e.target.value > 5) {
                                            setcolumn(5);
                                            console.log('column', e.target.value);
                                        } else {
                                            setcolumn(e.target.value);
                                            console.log('column', e.target.value);
                                        }
                                    }}
                                    variant="outlined"
                                />
                            </div>
                        )}
                        <Button variant="contained" color="primary" onClick={handleUpload}>
                            Upload
                        </Button>
                    </>
                )}

                {processing ? (
                    <Typography>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    </Typography>
                ) : (
                    <Typography>
                        {itemData && (
                            <>
                                <ImageList cols={new1}>
                                    {itemData?.map((item) => (
                                        <ImageListItem key={item.img}>
                                            <img
                                                //srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                src={item.img}
                                                alt="Resized Preview"
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '300px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                            <ImageListItemBar
                                                sx={{
                                                    background:
                                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                }}
                                                actionIcon={
                                                    <IconButton
                                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                        onClick={() => handledownload(item.img)}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                }
                                                //actionPosition="left"
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </>
                        )}
                    </Typography>
                )}
            </Container>
        </div>
    );
}
