import React, { useState } from 'react';
import Cybersnap1 from '../../images/snap1.png';
import Cybersnap2 from '../../images/snap2.png';
import Cybersnap3 from '../../images/snap3.png';
import Cybersnap4 from '../../images/snap4.png';
import Cybersnap5 from '../../images/snap5.png';
import Arrow from '../../images/arrow.svg';
import SnapIcon1 from '../../images/snapIcon1.svg';
import SnapIcon2 from '../../images/snapIcon2.svg';
import SnapIcon3 from '../../images/snapIcon3.svg';
import SnapIcon4 from '../../images/snapIcon4.svg';
import SnapIcon5 from '../../images/snapIcon5.svg';
import SnapIcon1Selected from '../../images/snapIcon1Selected.svg';
import SnapIcon2Selected from '../../images/snapIcon2Selected.svg';
import SnapIcon3Selected from '../../images/snapIcon3Selected.svg';
import SnapIcon4Selected from '../../images/snapIcon4Selected.svg';
import SnapIcon5Selected from '../../images/snapIcon5Selected.svg';

function ResponsiveSnap() {
    const [hoveredIndex, setHoveredIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const rowsData = [
        { icon: SnapIcon1, selectedIcon: SnapIcon1Selected, name: 'Schedule your content anytime, anywhere', description: 'Broadcast content to devices with a single click', image: Cybersnap1 },
        { icon: SnapIcon2, selectedIcon: SnapIcon2Selected, name: 'Simplicity by Design', description: 'For users of all experience levels', image: Cybersnap2 },
        { icon: SnapIcon3, selectedIcon: SnapIcon3Selected, name: 'Safely Secured', description: 'Keep your data protected', image: Cybersnap3 },
        { icon: SnapIcon4, selectedIcon: SnapIcon4Selected, name: 'Customizable Templates', description: 'Display your content however you want', image: Cybersnap4 },
        { icon: SnapIcon5, selectedIcon: SnapIcon5Selected, name: 'Dashboard Management', description: 'Manage your fleet of devices from a single hub', image: Cybersnap5 },

    ];
    // const displayedImage = selectedIndex !== null ? rowsData[selectedIndex].image : hoveredIndex !== null ? rowsData[hoveredIndex].image : null;

    return (
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row space-x-12">

            {/* Left Column: Contains several rows */}
            <div className="md:w-1/2 ">
                <div className='mt-0 mb-6'>
                    <h3 className='font-display font-bold text-2xl mb-4 text-customPrimary'
                        > SOFTWARE </h3>
                    <span className='font-display font-bold text-4xl md:text-5xl  mb-4 leading-tight ' >Bring your content to life with Our Platform.</span>
                </div>
                {rowsData.map((row, index) => (
                    <div
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(selectedIndex)}
                        onClick={() => {setSelectedIndex(index);setHoveredIndex(index)}}
                        className={`flex items-center justify-between x-8 py-6 rounded
                        ${index === selectedIndex ? 'bg-customBlue' : index === hoveredIndex ? 'bg-gray' : 'bg-white'}`} >
                        <img  src={index === selectedIndex ? row.selectedIcon : row.icon} 
                        alt={`${row.name} icon`} className="w-8 h-8 ml-4" />
                        <div className="mr-auto ml-6">
                            <h2 className="font-display font-bold text-base">{row.name}</h2>
                            <p className="font-display text-slate-400 text-base">{row.description}</p>
                        </div>
                        <img src={Arrow} alt={'arrow'} className="w-8 h-8 mr-4" />
                    </div>
                ))}
            </div>

            {/* Right Column: Contains an image */}
            <div className="md:w-1/2 my-auto">
                {hoveredIndex !== null && (
                    <img src={rowsData[hoveredIndex].image} alt={rowsData[hoveredIndex].name} className="w-full h-auto" />
                )}
            </div>

        </div>
    );
}

export default ResponsiveSnap;
