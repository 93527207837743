import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { CreateTemplateDialog, TemplateGrid } from '../components/dashboard/template';
import { GetTemplateElementTypeList, GetTemplateGroupList, GetTemplateList } from '../api/api';
import React, { useEffect, useState } from 'react';
import { RiDeleteBin6Fill, RiEditBoxLine, RiFileCopyFill } from 'react-icons/ri';

import { CampaignDesign } from '../components/dashboard/campaign';
import { Header } from '../components/dashboard';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0.5),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const Template = () => {
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    console.log('file: Template.jsx:77 => switchUser:', switchUser);

    const { currentUser, templateElement, setTemplateElement } = useStateContext();
    console.log('file: Template.jsx:65 => Template => templateElement:', templateElement);
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [templateGroup, setTemplateGroup] = useState([]);
    // console.log(
    // 	'file: Template.jsx:82 => Template => templateGroup:',
    // 	templateGroup
    // );
    const [favourite, setFavourite] = useState([]);
    const [general, setGeneral] = useState([]);
    const [custom, setCustom] = useState([]);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const getTemplateGroup = async () => {
        const data = await GetTemplateGroupList();
        setTemplateGroup(data?.data?.list?.list);
    };

    const getTemplateElement = async () => {
        const data = await GetTemplateElementTypeList();
        setTemplateElement(data?.data?.list);
        localStorage.setItem('element_type', JSON.stringify(data?.data?.list));
    };

    const getTemplateList = async (group_type) => {
        const data = await GetTemplateList(switchUser, group_type, undefined, 100);
        console.log('file: Template.jsx:109 => getTemplateList => data:', data);
        console.log('GetTemplateList', data.data.list);
        group_type === 0 && data.data.list
            ? setFavourite(data.data.list.reverse())
            : setFavourite([]);
        group_type === 1 && data.data.list && setGeneral(data.data.list.reverse());
        group_type === 2 && data.data.list && setCustom(data.data.list.reverse());
    };

    useEffect(() => {
        getTemplateGroup();
        getTemplateElement();
    }, []);

    // Handle Expand area
    const [expanded, setExpanded] = useState('');

    const handleExpand = (panel) => (newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        // console.log('panel', panel);
        panel.group_type === 0 && favourite.length <= 0 && getTemplateList(panel.group_type);
        panel.group_type === 1 && general.length <= 0 && getTemplateList(panel.group_type);
        panel.group_type === 2 && custom.length <= 0 && getTemplateList(panel.group_type);
    };

    useEffect(() => {
        getTemplateList(expanded.group_type);
        console.log(
            'file: Template.jsx:145 => useEffect => expanded.group_type:',
            expanded.group_type,
        );
        console.log('Trigger update ');
    }, [triggerUpdate, expanded]);

    const [showCampaignDesign, setShowCampaignDesign] = useState();

    //handle Open Create template Dialog
    const [openCreateTemplateDialog, setOpenCreateopenCreateTemplateDialog] = useState(false);
    const [currentItem, setCurrentItem] = useState();
    const handleOpenCreateTemplateDialog = (item) => {
        setCurrentItem(item);
        setOpenCreateopenCreateTemplateDialog(true);
    };
    const handleCloseCreateTemplateDialog = () => {
        setOpenCreateopenCreateTemplateDialog(false);
    };

    const [showEditCampaign, setShowEditCampaign] = useState();
    // console.log('showEditCampaign', showEditCampaign);
    // useEffect(() => {
    // 	if (JSON.parse(localStorage.getItem('editCurrentCampaign'))) {
    // 		setShowEditCampaign(JSON.parse(localStorage.getItem('editCurrentCampaign')));
    // 	}
    // }, []);
    return (
        <div className="md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <div>
                <Header title="Choose Template" />
                {templateGroup?.length > 0 &&
                    templateGroup.map((item, index) => (
                        <Accordion
                            key={item.group_name}
                            expanded={expanded === item}
                            onChange={handleExpand(item)}
                        >
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <div className="flex justify-between w-full">
                                    <div className="flex flex-row">{item.group_name}</div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className="mb-8 ">
                                {((currentUser.user_level === 0 &&
                                    (item.group_type === 1 || item.group_type === 2) &&
                                    !switchUser) ||
                                    (currentUser.user_level !== 0 && item.group_type === 2)) && (
                                    <div className="mb-8">
                                        <div className="flex justify-center">
                                            <Button
                                                variant="contained"
                                                onClick={() => handleOpenCreateTemplateDialog(item)}
                                            >
                                                Create Template
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid
                                        className="w-full m-0"
                                        container
                                        spacing={{ xs: 1, md: 2 }}
                                        columns={{
                                            xs: 4,
                                            sm: 8,
                                            md: 8,
                                            lg: 12,
                                        }}
                                    >
                                        {item.group_type === 0 &&
                                            favourite.length > 0 &&
                                            favourite.map((template, index) => (
                                                <TemplateGrid
                                                    key={template + index}
                                                    item={item}
                                                    template={template}
                                                    currentUser={currentUser}
                                                    triggerUpdate={triggerUpdate}
                                                    setTriggerUpdate={setTriggerUpdate}
                                                    setExpanded={setExpanded}
                                                    setShowCampaignDesign={setShowCampaignDesign}
                                                />
                                            ))}
                                        {item.group_type === 1 &&
                                            general.length > 0 &&
                                            general.map((template, index) => (
                                                <TemplateGrid
                                                    key={template + index}
                                                    item={item}
                                                    template={template}
                                                    currentUser={currentUser}
                                                    triggerUpdate={triggerUpdate}
                                                    setTriggerUpdate={setTriggerUpdate}
                                                    setExpanded={setExpanded}
                                                    setShowCampaignDesign={setShowCampaignDesign}
                                                />
                                            ))}
                                        {item.group_type === 2 &&
                                            custom.length > 0 &&
                                            custom.map((template, index) => (
                                                <TemplateGrid
                                                    key={template + index}
                                                    item={item}
                                                    template={template}
                                                    triggerUpdate={triggerUpdate}
                                                    setTriggerUpdate={setTriggerUpdate}
                                                    currentUser={currentUser}
                                                    setExpanded={setExpanded}
                                                    setShowCampaignDesign={setShowCampaignDesign}
                                                />
                                            ))}
                                    </Grid>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </div>
            {(showCampaignDesign || showEditCampaign) && (
                <CampaignDesign showCampaignDesign={showCampaignDesign} currentUser={currentUser} />
            )}
            {openCreateTemplateDialog && (
                <CreateTemplateDialog
                    item={currentItem}
                    openCreateTemplateDialog={openCreateTemplateDialog}
                    handleCloseCreateTemplateDialog={handleCloseCreateTemplateDialog}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                    currentUser={currentUser}
                />
            )}
        </div>
    );
};

export default Template;
