import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { GrChapterAdd, GrClose, GrDown, GrEdit, GrLock, GrTrash } from 'react-icons/gr';
import React, { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';

import LazyLoad from 'react-lazyload';
import { PreviewMediaDialog } from './';
import { TbCloudUpload } from 'react-icons/tb';

const ImageListItemBarCenter = styled(ImageListItemBar)(({ theme }) => ({
    ' .MuiImageListItemBar-titleWrap': {
        padding: '0',
    },
    '& .MuiImageListItemBar-title': {
        textAlign: 'center',
        fontSize: '14px',
        paddingBottom: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
}));
const ImageListSection = ({
    handleOpenUploadDialog,
    handleOpenDeleteDialog,
    chosenfolderMedia,
    item,
    currentUser,
    screenSize,
}) => {
    const [openPreviewMediaDialog, setOpenPreviewMediaDialog] = useState(false);
    const [previewMedia, setPreviewMedia] = useState({});

    const handleOpenPreviewMediaDialog = (e, item) => {
        e.stopPropagation();

        console.log('handleOpenPreviewMediaDialog => item:', item);
        setPreviewMedia(item);
        setOpenPreviewMediaDialog(true);
    };
    const handleClosePreviewMediaDialog = () => {
        setOpenPreviewMediaDialog(false);
    };

    useEffect(() => {
        // Set the cookie when the component mounts
        document.cookie = 'Set-Cookie: __Host-example=34d8g; SameSite=None; Secure; Path=/; Partitioned;';

        // Optionally, you might want to clean up the cookie when the component unmounts
        return () => {
            // Clear the cookie when the component unmounts (if needed)
            document.cookie =
                'myCookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure; Path=/; Partitioned;';
        };
    }, []); // The empty dependency array ensures that the effect runs only once when the component mounts

    return (
        <ImageList
            gap={10}
            cols={screenSize < 1512 ? (screenSize < 970 ? (screenSize < 700 ? 1 : 2) : 3) : 4}
            rowHeight={121}
        >
            {item.folder_type === 2 ? (
                currentUser.user_level === 0 && (
                    <div
                        className="cursor-pointer upload-div hover:bg-cyan-300 flex flex-col align-middle justify-center w-full h-full"
                        style={{
                            borderRadius: '8px',
                            backgroundColor: '#ffffff',
                            height: '250px',
                            width: '250px',
                        }}
                        onClick={handleOpenUploadDialog}
                    >
                        <div className="flex justify-center m-2">
                            <TbCloudUpload size={70} />
                        </div>
                        <div className="flex justify-center">
                            <p className="font-semi">Upload</p>
                        </div>
                    </div>
                )
            ) : (
                <div
                    className="cursor-pointer upload-div hover:bg-cyan-300 flex flex-col align-middle justify-center w-full h-full"
                    style={{
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        height: '250px',
                        width: '250px',
                    }}
                    onClick={handleOpenUploadDialog}
                >
                    <div className="flex justify-center m-2">
                        <TbCloudUpload size={70} />
                    </div>
                    <div className="flex justify-center">
                        <p className="font-semi">Upload</p>
                    </div>
                </div>
            )}
            {chosenfolderMedia?.length > 0 &&
                chosenfolderMedia?.map((media, index) => (
                    <ImageListItem
                        key={media.resource_url + index}
                        sx={{
                            // border: '2px solid rgba(18, 188, 215, 0.2)',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'rgba(18, 188, 215, 0.2)',
                            },
                            backgroundColor: '#ffffff',
                            minHeight: '250px',
                            height: '250px',
                            maxWidth: '250px',
                        }}
                        onClick={(e) => handleOpenPreviewMediaDialog(e, media)}
                    >
                        <div className="flex h-full w-full justify-center items-center">
                            {media.resource_type === 'image' ? (
                                <LazyLoad>
                                    <img
                                        // srcSet={`${media.resource_url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        style={{ maxHeight: '200px' }}
                                        src={media.resource_url}
                                        loading="lazy"
                                        className="max-w-full items-center p-3"
                                    />
                                </LazyLoad>
                            ) : (
                                <LazyLoad>
                                    <video
                                        // srcSet={`${media.resource_url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        style={{ height: '200px' }}
                                        loading="lazy"
                                        className="max-h-full max-w-full items-center p-3"
                                        controls
                                        controlsList="nodownload noremoteplayback nofullscreen noplaybackrate" // Remove download button
                                        disablePictureInPicture // Disable Picture-in-Picture mode
                                        disableRemotePlayback // Disable remote playback
                                        playsInline // Ensure the video is played inline on mobile devices
                                        src={media.resource_url}
                                    />
                                </LazyLoad>
                            )}
                        </div>

                        <ImageListItemBarCenter
                            title={media.resource_name}
                            position="below"
                            sx={{
                                height: '30px',
                                fontSize: '14px',
                            }}
                        />
                        {
                            (item.folder_type !== 2 || (item.folder_type === 2 && currentUser.user_level === 0)) && (
                                <ImageListItemBar
                                    sx={{
                                        background: 'transparent',
                                        padding: '6px',
                                    }}
                                    title={media.title}
                                    position="top"
                                    actionIcon={
                                        <IconButton
                                            sx={{
                                                color: 'white',
                                                zIndex: '1000',
                                            }}
                                            aria-label={`star ${media.resource_name}`}
                                            onClick={(e) => handleOpenDeleteDialog(e, media, 'media')}
                                        >
                                            <GrClose
                                                size={14}
                                                onClick={(e) => handleOpenDeleteDialog(e, media, 'media')}
                                            />
                                        </IconButton>
                                    }
                                    actionPosition="right"
                                />
                            )
                            // : (
                            //     <></>
                            // )
                        }
                    </ImageListItem>
                ))}
            <PreviewMediaDialog
                openPreviewMediaDialog={openPreviewMediaDialog}
                handleClosePreviewMediaDialog={handleClosePreviewMediaDialog}
                previewMedia={previewMedia}
            />
        </ImageList>
    );
};

export default ImageListSection;
