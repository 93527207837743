import './App.css';

import {
    AccountManager,
    AccountSettings,
    Activate,
    CampaignManager,
    DeviceManager,
    Home,
    Log,
    Login,
    MediaManager,
    PublishCampaign,
    Register,
    ScheduleCampaign,
    SetUpNewPassword,
    SwitchUser,
    Template,
    VerifyCodePage,
} from './pages';
import { Blogs, ContactUs, Features, Hardware, HomePage, HowItWorks, Page404, Pricing } from './publicPages';
import { BrightonGrammar, Epworth, Mitre, Sushi } from './blogs';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { Contract, OrderSummary, PaymentPage, Subscription } from './components/dashboard/accounting';
import { DashboardNotFound, Notification, TokenChecker } from './components/dashboard/utils';
import { Footer, Navbar, Sidebar } from './components/dashboard';
import React, { useEffect, useState } from 'react';
import { ScrollToTop, WebFooter, WebNavbar } from './components/website';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getLastApiCallTime, setLastApiCallTime } from './api/TokenManager';

import { Admin } from './components/dashboard/admin';
import { EditCampaign } from './components/dashboard/campaign';
import { UpdateTokenExpire } from './api/api';
// import { StripeSubscription } from './components/dashboard/profile/payment';
import WebBreadcrumbs from './components/website/WebBreadcrumbs';
import moment from 'moment';
import { useStateContext } from './contexts/ContextProvider';

const theme = createTheme({
    typography: {
        fontFamily:
            'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif',
    },
    palette: {
        primary: {
            main: '#09bff1',
            darker: '#053e85',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        gray: {
            light: '#f7f7f7',
            main: '#a9a9a9',
            dark: '#595959',
            contrastText: '#000',
        },
        white: {
            main: '#fff',
            dark: '#f7f7f7',
            contrastText: '#000',
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '10px',
                    padding: '20px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    // width: '150px',
                    height: '40px',
                    textTransform: 'none',
                },
                text: {
                    fontWeight: '',
                },
            },
        },
    },
});
const TOKEN_UPDATE_INTERVAL = 10 * 60 * 1000; // 10 mins
const updateToken = async () => {
    // update token
    const data = await UpdateTokenExpire();
};

function App() {
    // const navigate = useNavigate();
    const { currentUser, setCurrentUser, activeMenu } = useStateContext();
    const lastApiCallTime = getLastApiCallTime();
    console.log(
        'file: App.js:104 => App => getLastApiCallTime:',
        moment(lastApiCallTime).format('MMMM Do YYYY, h:mm:ss a'),
    );
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (process.env.REACT_APP_ENV === 'development') {
        console.log('This is the development environment');
    } else if (process.env.REACT_APP_ENV === 'production') {
        console.log('This is the production environment');
    } else {
        console.log(process.env.REACT_APP_ENV);
        console.log('This is a different environment');
    }

    const pathname = window.location.pathname;

    // Clear local storage if the pathname includes 'login'
    // Otherwise, set the current user if it exists in local storage
    useEffect(() => {
        if (pathname.includes('login')) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('switchUser');
            localStorage.removeItem('switchUserName');
        } else if (!pathname.includes('switch-user')) {
            const switchedUser = JSON.parse(localStorage.getItem('switchUser'));
            const user = JSON.parse(localStorage.getItem('currentUser'));
            if (user) {
                setCurrentUser(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
            }
        } else {
        }
    }, [pathname]);

    useEffect(() => {
        // 设置定时器检查是否需要更新 token
        // const intervalId = setInterval(checkAndUpdateToken, TOKEN_UPDATE_INTERVAL);
        // console.log('useEffect => intervalId:', intervalId);

        // 设置页面点击事件监听器
        const clickListener = () => {
            const lastApiCallTime = getLastApiCallTime();
            // console.log('clickListener => lastApiCallTime:', moment(lastApiCallTime).format('MMMM Do YYYY, h:mm:ss a'));
            const currentTime = Date.now();
            // console.log('clickListener => currentTime:', moment(currentTime).format('MMMM Do YYYY, h:mm:ss a'));

            console.log('clickListener => currentTime - lastApiCallTime:', currentTime - lastApiCallTime);
            if (currentTime - lastApiCallTime > TOKEN_UPDATE_INTERVAL) {
                console.log('大于十分钟了，如果用户点击页面更新token');
                updateToken();
                // setLastApiCallTime(currentTime);
            }
        };

        document.addEventListener('click', clickListener);

        return () => {
            // clearInterval(intervalId);
            document.removeEventListener('click', clickListener);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div className="w-full">
                <Notification />
                <BrowserRouter basename="/">
                    <div className="w-full flex relative dark:bg-main-dark-bg ">
                        {currentUser && pathname.includes('dashboard') && (
                            <>
                                {activeMenu ? (
                                    <div className="w-[320px] fixed dark:bg-secondary-dark-bg bg-white z-[100]">
                                        <Sidebar />
                                    </div>
                                ) : (
                                    <div className="w-0 dark:bg-secondary-dark-bg z-[100]">
                                        <Sidebar />
                                    </div>
                                )}
                            </>
                        )}

                        <div
                            className={`dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full flex flex-col mt-auto
							${activeMenu && currentUser && pathname.includes('dashboard') ? 'md:ml-80 ' : 'flex-2 '}`}
                        >
                            <div className="mb-auto">
                                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg  w-full z-[100]">
                                    {currentUser && pathname.includes('dashboard') ? <Navbar /> : ''}
                                </div>
                            </div>

                            <div className="mt-0 grow">
                                <Routes>
                                    <Route path="login" element={<Login />} />
                                    <Route path="/signup" element={<Register />} />
                                    <Route path="/activate" element={<Activate />} />
                                    <Route path="/reset-password" element={<SetUpNewPassword />} />
                                    <Route path="/switch-user" element={<SwitchUser />} />
                                    <Route path="/verify-code" element={<VerifyCodePage />} />
                                    {/* dashboard  */}
                                    <Route path="/" element={<WebNavbar />}>
                                        <Route index element={<HomePage />} />
                                        <Route path="pricing" element={<Pricing />} />
                                        <Route path="product/hardware" element={<Hardware />} />
                                        <Route path="product/features" element={<Features />} />
                                        <Route path="product/howitworks" element={<HowItWorks />} />
                                        <Route path="resources/all-blogs" element={<Blogs />} />
                                        <Route path="resources/top1" element={<BrightonGrammar />} />
                                        <Route path="resources/top2" element={<Epworth />} />
                                        <Route path="resources/top3" element={<Sushi />} />
                                        <Route path="resources/top4" element={<Mitre />} />
                                        <Route path="contact" element={<ContactUs />} />
                                        <Route
                                            path="contact/find/like/subscribe"
                                            element={
                                                <WebBreadcrumbs
                                                    sites={[
                                                        {
                                                            name: 'Contact us',
                                                            link: 'contact',
                                                        },
                                                        {
                                                            name: 'find us',
                                                            link: 'find',
                                                        },
                                                        {
                                                            name: 'like',
                                                            link: 'like',
                                                        },
                                                        {
                                                            name: 'subscribe',
                                                            link: 'subscribe',
                                                        },
                                                    ]}
                                                />
                                            }
                                        />
                                        <Route
                                            path="contact/find/like"
                                            element={
                                                <WebBreadcrumbs
                                                    sites={[
                                                        {
                                                            name: 'Contact us',
                                                            link: 'contact',
                                                        },
                                                        {
                                                            name: 'find us',
                                                            link: 'find',
                                                        },
                                                        {
                                                            name: 'like',
                                                            link: 'like',
                                                        },
                                                    ]}
                                                />
                                            }
                                        />
                                        <Route
                                            path="contact/find"
                                            element={
                                                <WebBreadcrumbs
                                                    sites={[
                                                        {
                                                            name: 'Contact us',
                                                            link: 'contact',
                                                        },
                                                        {
                                                            name: 'find us',
                                                            link: 'find',
                                                        },
                                                    ]}
                                                />
                                            }
                                        />
                                    </Route>

                                    {currentUser && (
                                        <Route>
                                            <Route path="/dashboard" element={<DeviceManager />} />
                                            <Route
                                                path="/dashboard/device-manager"
                                                element={<DeviceManager />}
                                            />
                                            <Route
                                                path="/dashboard/media-manager"
                                                element={<MediaManager />}
                                            />
                                            <Route
                                                path="/dashboard/campaign-manager"
                                                element={<CampaignManager />}
                                            />
                                            <Route
                                                path="/dashboard/schedule-campaign"
                                                element={<ScheduleCampaign />}
                                            />
                                            <Route
                                                path="/dashboard/publish-campaign"
                                                element={<PublishCampaign />}
                                            />
                                            {currentUser.user_level === 0 && (
                                                <Route
                                                    path="/dashboard/account-manager"
                                                    element={<AccountManager />}
                                                />
                                            )}
                                            <Route
                                                path="/dashboard/edit-campaign"
                                                element={<EditCampaign />}
                                            />
                                            <Route path="/dashboard/template" element={<Template />} />
                                            <Route
                                                path="/dashboard/account-settings"
                                                element={<AccountSettings />}
                                            />

                                            <Route path="/dashboard/contract" element={<Contract />} />
                                            <Route path="/dashboard/payment" element={<PaymentPage />} />
                                            <Route path="/dashboard/log" element={<Log />} />
                                            <Route path="/dashboard/admin" element={<Admin />} />
                                            <Route
                                                path="/dashboard/subscription"
                                                element={<Subscription />}
                                            />
                                            <Route path="/dashboard/*" element={<DashboardNotFound />} />
                                            <Route path="*" element={<Page404 />} />
                                        </Route>
                                    )}
                                </Routes>
                            </div>

                            {currentUser &&
                            (pathname.includes('dashboard') ||
                                pathname.includes('login') ||
                                pathname.includes('signup') ||
                                pathname.includes('activate')) ? (
                                <Footer />
                            ) : (
                                !(
                                    pathname.includes('dashboard') ||
                                    pathname.includes('login') ||
                                    pathname.includes('signup') ||
                                    pathname.includes('activate')
                                ) && <WebFooter />
                            )}
                        </div>
                    </div>
                    {/* {currentUser && (
                        <TokenChecker
                            token={token}
                            expirationTime={expirationTime}
                            onTokenExpired={handleTokenExpired}
                        />
                    )} */}
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
}

export default App;
