// config.js
// const env = process.env.REACT_APP_ENV || 'development';

const config = {
    development: {
        REACT_APP_API_URL: 'https://soniqcast.com.au/backstage/',
        REACT_APP_DEBUG: true,
        REACT_APP_IMAGE_API_URL: 'https://jo28hkjks3.execute-api.ap-southeast-2.amazonaws.com/dev',
        REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyCccvj5eqnL0Nhi_8udk5mzG5nxSqG_GDE',

        REACT_APP_S3_BUCKET: 'rek-soniqcast',
        REACT_APP_REGION: 'ap-southeast-2',

        REACT_APP_accessKeyId: 'AKIAZ3H4D43DEMWAIQ5Z',
        REACT_APP_secretAccessKey: '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',

        REACT_APP_RDS_API_URL: 'https://dnu21brci7.execute-api.ap-southeast-2.amazonaws.com/new',
        REACT_APP_PAYMENT_API_URL:
            'https://ysvghlj94e.execute-api.ap-southeast-2.amazonaws.com/dev',
    },
    production: {
        REACT_APP_API_URL: 'https://pro.cybercast.com.au/',
        REACT_APP_DEBUG: false,
        REACT_APP_IMAGE_API_URL: 'https://jo28hkjks3.execute-api.ap-southeast-2.amazonaws.com/prod',
        REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyCccvj5eqnL0Nhi_8udk5mzG5nxSqG_GDE',

        REACT_APP_S3_BUCKET: 'rek-cybercastpro',
        REACT_APP_REGION: 'ap-southeast-2',

        REACT_APP_accessKeyId: 'AKIAZ3H4D43DEMWAIQ5Z',
        REACT_APP_secretAccessKey: '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',

        REACT_APP_RDS_API_URL: 'https://dnu21brci7.execute-api.ap-southeast-2.amazonaws.com/new',
        REACT_APP_PAYMENT_API_URL:
            'https://ysvghlj94e.execute-api.ap-southeast-2.amazonaws.com/dev',
    },
};

export default config;
