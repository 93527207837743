import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ImageListItemBar, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { GrChapterAdd, GrClose, GrDown, GrEdit, GrLock, GrTrash } from 'react-icons/gr';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

const PreviewMediaDialog = ({ openPreviewMediaDialog, handleClosePreviewMediaDialog, previewMedia }) => {
    return (
        <Dialog open={openPreviewMediaDialog} fullScreen onClick={handleClosePreviewMediaDialog} PaperProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}>
            <Toolbar>
                <IconButton edge="end" color="inherit" onClick={handleClosePreviewMediaDialog} aria-label="close" sx={{ ml: 'auto', zIndex: '1000' }}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            {/* <DialogTitle>PreviewMedia</DialogTitle> */}
            <DialogContent sx={{ padding: '0px' }}>
                <div className="flex h-full w-full justify-center items-center">
                    {previewMedia.resource_type === 'image' ? (
                        <img src={previewMedia.resource_url} loading="lazy" className="max-w-full items-center p-3 max-h-full " />
                    ) : (
                        <video oading="lazy" className="max-h-full max-w-full items-center p-3" controls muted autoPlay src={previewMedia.resource_url} />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PreviewMediaDialog;
