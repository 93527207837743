import Button from '@mui/material/Button';
import React from 'react';

function BottomSignUp() {
    return (
        <div className="max-w-6xl mx-auto md:flex px-4 justify-between items-center">
            <div className="flex-col items-center justify-center min-w-80 mb-2">
                <h6 className="font-display font-bold text-xl text-slate-400">Stay in the loop</h6>
                <h6 className="font-display font-bold text-xl text-slate-400">
                    Sign up for the updates.
                </h6>
            </div>
            <div className="flex min-w-80 sm:ml-auto mb-2">
                <input
                    placeholder="email address"
                    style={{
                        width: '250px',
                        height: '42px',
                        marginRight: '12px',
                        borderRadius: '8px',
                        paddingLeft: '4px',
                    }}
                ></input>
                <Button
                    variant="contained"
                    href="./signup"
                    style={{
                        color: '#ffffff',
                        fontSize: '16px',
                        fontWeight: 500,
                        padding: '18px 18px',
                    }}
                >
                    Sign Up
                </Button>
            </div>
        </div>
    );
}
export default BottomSignUp;
