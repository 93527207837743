import {
    AddDeviceDialog,
    DeviceList,
    LocationDialog,
    Locations,
    MapDialog,
} from '../components/dashboard/device';
import { Badge, Box, Button, Tab, Tabs } from '@mui/material';
import { GetCount, GetDeviceList, GetLocationInfo, GetLocationList } from '../api/api';
import { Header, Pulse } from '../components/dashboard';
import React, { Suspense, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../contexts/ContextProvider';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -16,
        top: 8,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
const StyledTab = styled(Tab)(({ theme }) => ({
    paddingRight: '30px',
}));
const ariaLabel = { 'aria-label': 'description' };
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DeviceManager = () => {
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    const { currentUser } = useStateContext();
    // Handle Change Tabs
    const [tabValue, setTabValue] = useState(0);

    const handleTabs = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === 1) getDeviceList();
    };

    // get numbers of locations & devices
    const [numberList, setNumberList] = useState([]);
    console.log('file: DeviceManager.jsx:64 => DeviceManager => numberList:', numberList);
    const getNumbers = async () => {
        const data = await GetCount(switchUser);
        setNumberList(data.data);
    };

    // Handle Add Device Button
    const [openDeviceDialog, setOpenDeviceDialog] = useState(false);
    // const [addedDevice, setAddedDevice] = useState(true);
    const handleOpenDeviceDialog = () => {
        setOpenDeviceDialog(true);
    };

    const handleCloseDeviceDialog = () => {
        setOpenDeviceDialog(false);
    };

    //Get Device List
    const [assignedDeviceList, setAssignedDeviceList] = useState([]);
    const [unassignedDeviceList, setUnassignedDeviceList] = useState([]);
    console.log(
        'file: DeviceManager.jsx:92 => DeviceManager => unassignedDeviceList:',
        unassignedDeviceList,
    );
    const getDeviceList = async () => {
        const data = await GetDeviceList(switchUser);
        console.log('GetDeviceList:', data);
        setAssignedDeviceList(data?.data?.assign_device_list);
        setUnassignedDeviceList(data?.data?.no_assign_device_list);
    };

    // Get Locations
    const [locationList, setLocationList] = useState([]);

    console.log('locationList', locationList);
    const getLocations = async () => {
        const data = await GetLocationList(switchUser);
        console.log('GetLocationList:', data);
        const list = data?.data;
        if (data.code === 0) {
            Array.isArray(list) && setLocationList(list);
        }
    };

    //handle Open Location Dialog
    const [openLocation, setOpenLocation] = useState(false);
    const handleOpenLocationDialog = () => {
        setOpenLocation(true);
    };
    const handleCloseLocationDialog = () => {
        setOpenLocation(false);
    };

    const [triggerUpdate, setTriggerUpdate] = useState(false);

    useEffect(() => {
        getNumbers();
        getLocations();
        getDeviceList();
    }, [triggerUpdate]);

    //handle Open Map Dialog
    const [openMapDialog, setOpenMapDialog] = useState(false);
    const handleOpenMapDialog = () => {
        setOpenMapDialog(true);
    };
    const handleCloseMapDialog = () => {
        setOpenMapDialog(false);
    };

    return (
        <>
            <div className="m-10 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Getting Started" />
                <div className="p-4 pt-0">
                    <p className="font-medium ">
                        Step 1: Click on the Add Location button.
                        <br />
                        Step 2: Enter your location name.
                        <br />
                        Step 3: Add unassigned devices to your location.
                        <br />
                        Step 4: Click on the Save button.
                        <br />
                        Step 5: Proceed to Media Manager to upload your campaign material.
                        <br />
                        Step 6: Then proceed to Campaign Manager to create your campaign.
                        <br />
                        Step 7: Lastly, go to Schedule Campaign to publish your saved campaign.
                    </p>
                </div>
            </div>
            <div className="m-10 p-2 md:m-10 md:p-10 bg-white rounded-3xl">
                <Header title="Device Manager" />
                <div className="p-4 pt-0">
                    <p>This area will allow you to manage your groups and devices</p>

                    <Tabs
                        value={tabValue}
                        onChange={handleTabs}
                        // variant='scrollable'
                        // scrollButtons='auto'
                        // centered
                        aria-label="scrollable auto tabs"
                    >
                        <StyledTab
                            label={
                                <StyledBadge
                                    badgeContent={numberList?.device_group_num}
                                    color="primary"
                                >
                                    Locations
                                </StyledBadge>
                            }
                            iconPosition="end"
                            {...a11yProps(0)}
                        />
                        <StyledTab
                            label={
                                <StyledBadge badgeContent={numberList?.device_num} color="primary">
                                    All Devices
                                </StyledBadge>
                            }
                            {...a11yProps(1)}
                        />
                        {/* <Tab label="Manage Group Location" {...a11yProps(2)} /> */}
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <Suspense fallback={<p>Loading...</p>}>
                            <div className="flex w-full justify-between ">
                                <div className="flex flex-row ">
                                    <div className="mr-4">
                                        <Button
                                            variant="contained"
                                            onClick={handleOpenDeviceDialog}
                                        >
                                            Add Device
                                        </Button>
                                        <AddDeviceDialog
                                            openDeviceDialog={openDeviceDialog}
                                            handleCloseDeviceDialog={handleCloseDeviceDialog}
                                            currentUser={currentUser}
                                            setTriggerUpdate={setTriggerUpdate}
                                            triggerUpdate={triggerUpdate}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleOpenLocationDialog}
                                        >
                                            Add Location
                                        </Button>
                                        {openLocation && (
                                            <LocationDialog
                                                openLocation={openLocation}
                                                handleCloseLocationDialog={
                                                    handleCloseLocationDialog
                                                }
                                                unassignedDeviceList={unassignedDeviceList}
                                                currentUser={currentUser}
                                                setTriggerUpdate={setTriggerUpdate}
                                                triggerUpdate={triggerUpdate}
                                                text={'Add'}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <Button variant="outlined" onClick={handleOpenMapDialog}>
                                        Map
                                    </Button>
                                    {openMapDialog && (
                                        <MapDialog
                                            openMapDialog={openMapDialog}
                                            handleCloseMapDialog={handleCloseMapDialog}
                                            switchUser={switchUser}
                                            currentUser={currentUser}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="mt-4">
                                <Locations
                                    locationList={locationList}
                                    currentUser={currentUser}
                                    setTriggerUpdate={setTriggerUpdate}
                                    triggerUpdate={triggerUpdate}
                                    openLocation={openLocation}
                                    switchUser={switchUser}
                                    // locationListDetail={locationListDetail}
                                />
                            </div>
                        </Suspense>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <DeviceList
                            assignedDeviceList={assignedDeviceList}
                            currentUser={currentUser}
                            unassignedDeviceList={unassignedDeviceList}
                            locationList={locationList}
                            setTriggerUpdate={setTriggerUpdate}
                            triggerUpdate={triggerUpdate}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        Manage Group Location
                    </TabPanel>
                </div>
            </div>
        </>
    );
};

export default DeviceManager;
