import scroll1 from './scroll1.png';
import scroll2 from './scroll2.png';
import scroll3 from './scroll3.png';
import scroll4 from './scroll4.png';
import scroll5 from './scroll5.png';
import scroll6 from './scroll6.png';
import scroll7 from './scroll7.png';
import scroll8 from './scroll8.png';
import scroll9 from './scroll9.png';
import scroll10 from './scroll10.png';
import scroll11 from './scroll11.png';
import scroll12 from './scroll12.png';
import scroll13 from './scroll13.png';
import scroll14 from './scroll14.png';

const imagesList = [
    { src: scroll1, alt: 'Cybercast' },
    { src: scroll2, alt: 'Cybercast' },
    { src: scroll3, alt: 'Cybercast' },
    { src: scroll4, alt: 'Cybercast' },
    { src: scroll5, alt: 'Cybercast' },
    { src: scroll6, alt: 'Cybercast' },
    { src: scroll7, alt: 'Cybercast' },
    { src: scroll8, alt: 'Cybercast' },
    { src: scroll9, alt: 'Cybercast' },
    { src: scroll10, alt: 'Cybercast' },
    { src: scroll11, alt: 'Cybercast' },
    { src: scroll12, alt: 'Cybercast' },
    { src: scroll13, alt: 'Cybercast' },
    { src: scroll14, alt: 'Cybercast' },

  ];
  
  export default imagesList;
  