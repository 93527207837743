import { GetContractList, GetDeviceDetail } from '../../../api/api';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { DeviceDetailDialog } from './';
import { GetUserDetail } from '../../../api/api';
import { Header } from '../';
import { Pulse } from '../utils';
import { StripeSubscription } from './payment';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useStateContext } from '../../../contexts/ContextProvider';

const Subscription = () => {
    const { currentUser } = useStateContext();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    return (
        <div className="md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <Header
                title={`${
                    userDetails.customer_type === 2
                        ? 'Business'
                        : userDetails.customer_type === 1
                        ? 'General'
                        : ''
                } Subscription`}
            />
            <div className="pb-10 pl-10 pr-10 pt-0">
                <StripeSubscription
                    currentUser={currentUser}
                    switchUser={switchUser}
                    customerType={userDetails.customer_type}
                    username={userDetails.username}
                />
            </div>
        </div>
    );
};

export default Subscription;
