import news1 from '../images/news1.png';
import news2 from '../images/news2.png';

const BlogData = [
    {
        category: 'News & Trends',
        name: 'Meet the Superior Alternative to FlexSlider in WordPress',
        abstract: 'abstract',
        content: 'content',
        image: news1
    },
    {
        category: 'Help & Advice',
        name: 'Meet the Superior Alternative to FlexSlider in WordPress',
        abstract: 'abstract',
        content: 'content',
        image: news2
    },
    {
        category: 'News & Trends',
        name: 'Meet the Superior Alternative to FlexSlider in WordPress',
        abstract: 'abstract',
        content: 'content',
        image: news1
    },
    {
        category: 'Help & Advice',
        name: 'Meet the Superior Alternative to FlexSlider in WordPress',
        abstract: 'abstract',
        content: 'content',
        image: news2
    },
    {
        category: 'News & Trends',
        name: 'Meet the Superior Alternative to FlexSlider in WordPress',
        abstract: 'abstract',
        content: 'content',
        image: news1
    },
    {
        category: 'Help & Advice',
        name: 'Meet the Superior Alternative to FlexSlider in WordPress',
        abstract: 'abstract',
        content: 'content',
        image: news2
    },
    {
        category: 'News & Trends',
        name: 'Meet the Superior Alternative to FlexSlider in WordPress',
        abstract: 'abstract',
        content: 'content',
        image: news1
    },
    {
        category: 'Help & Advice',
        name: 'Meet the Superior Alternative to FlexSlider in WordPress',
        abstract: 'abstract',
        content: 'content',
        image: news2
    },
];
export default BlogData;