import * as React from 'react';

import { Button, Container, Typography } from '@mui/material';

import AWS from 'aws-sdk';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { ImageStyle } from './Api';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import ListSubheader from '@mui/material/ListSubheader';
import { Resize } from './Api';
import Slider from '@mui/material/Slider';
import { SplitImage } from './Api';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import config from '../../../config/config';
import { useState } from 'react';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function ImageVariation({ user_id }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(false);

    const [imageResolution, setImageResolution] = useState({
        width: null,
        height: null,
    });

    const [loading, setloading] = useState(false);
    const [processing, setprocessing] = useState(null);

    const [S3URL, setS3URL] = useState(null);

    const [imageSize, setImageSize] = useState(null);
    const [alertPNG, setalertPNG] = useState(false);

    const [repsonseURL, setrepsonseURL] = useState(null);

    const [progress, setProgress] = React.useState(0);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setalertPNG(false);
        // Display image preview
        const reader = new FileReader();
        reader.onloadend = () => {
            //setImagePreview(reader.result);
        };
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= 5000000) {
            reader.readAsDataURL(file);

            // Get image resolution
            const img = new Image();
            img.onload = () => {
                setImageResolution({ width: img.width, height: img.height });
                setImageSize(file.size);
                console.log({ width: img.width, height: img.height });
                if (img.width === img.height && img.width <= 1650) {
                    const d = uploadS3(file);
                } else {
                    setalertPNG(true);
                }
            };
            img.src = URL.createObjectURL(file);
        } else {
            // Handle the case when an invalid file type is selected (not a PNG file)
            reader.readAsDataURL(file);

            // Get image resolution
            const img = new Image();
            img.onload = () => {
                setImageResolution({ width: img.width, height: img.height });
                setImageSize(file.size);
                console.log({ width: img.width, height: img.height });
            };
            img.src = URL.createObjectURL(file);

            setalertPNG(true);
            // Optionally, you can display a message to the user or take other actions.
        }
    };

    const uploadS3 = async (selectedFile) => {
        const S3_BUCKET = config[process.env.REACT_APP_ENV].REACT_APP_S3_BUCKET;

        const REGION = config[process.env.REACT_APP_ENV].REACT_APP_REGION;

        const filteredFilename = selectedFile.name.replace(/[^a-zA-Z0-9.]/g, '');

        AWS.config.update({
            accessKeyId: config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId,
            secretAccessKey: config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey,
        });

        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            //ACL: "public-read",
            Body: selectedFile,
            Bucket: S3_BUCKET,
            Key: user_id + '/' + filteredFilename,
        };
        console.log(params);

        //const data = myBucket.putObject(params);
        //console.log(data);
        setloading(true);

        var upload = myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt) => {
                console.log('Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%');
                setProgress(parseInt((evt.loaded * 100) / evt.total));
            })
            .promise();

        await upload.then((err, data) => {
            console.log(err);
            console.log(upload);

            setloading(false);
            setProgress(0);
            setImagePreview(true);
        });

        setS3URL(
            'https://' + S3_BUCKET + '.s3.' + REGION + '.amazonaws.com/' + user_id + '/' + filteredFilename,
        );
        console.log(
            'https://' + S3_BUCKET + '.s3.' + REGION + '.amazonaws.com/' + user_id + '/' + filteredFilename,
        );
        //return data;
    };

    const CheckImage = async () => {
        if (imageResolution.width === imageResolution.height) {
            console.log('pass', imageResolution);

            const body = {
                image_url: S3URL,
                user_id: user_id,
            };

            setprocessing(true);

            const data = await ImageStyle(body);

            if (data['statusCode'] == 200) {
                console.log('ImageStyle Data:', data);
                setrepsonseURL(data.message);

                setprocessing(false);
            }
        } else {
            setalertPNG(true);
            console.log('fail', imageResolution);
        }
    };

    const handleDownload = () => {
        if (repsonseURL) {
            const link = document.createElement('a');
            link.href = repsonseURL;
            link.download = 'style.png';

            link.click();
        }
    };

    const handleRefresh = () => {
        setSelectedFile(null);
        setImageSize(null);
        setImagePreview(null);
        setalertPNG(false);
        setrepsonseURL(null);
    };

    return (
        <div>
            <Container>
                <Typography variant="h5">Please Select your file</Typography>
                {selectedFile == null && (
                    <>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-button"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="upload-button">
                            <Button variant="contained" component="span">
                                Choose File
                            </Button>
                        </label>
                    </>
                )}
                {selectedFile !== null && (
                    <>
                        <Button variant="contained" color="error" onClick={handleRefresh}>
                            Clear
                        </Button>
                    </>
                )}

                <Typography variant="body1" gutterBottom>
                    {selectedFile && `Selected File: ${selectedFile.name}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {selectedFile && `Resolution: ${imageResolution.width}x${imageResolution.height}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {selectedFile && ` Size: ${(imageSize / 1024 / 1024).toFixed(2)} MB`}
                </Typography>

                {alertPNG == true && (
                    <div>
                        <Alert severity="error">
                            Please select a square PNG/JPG file ( Max 1650 x 1650) and size is less than 4MB{' '}
                        </Alert>
                    </div>
                )}
                {imagePreview == true && (
                    <>
                        <img
                            src={S3URL}
                            alt="Preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                marginTop: '10px',
                            }}
                        />
                        <Typography>
                            <Button variant="contained" color="primary" onClick={CheckImage}>
                                Change style
                            </Button>
                        </Typography>
                    </>
                )}
            </Container>

            {loading == true && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={progress} />
                </Box>
            )}

            {processing ? (
                <Typography>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </Typography>
            ) : (
                <Typography>
                    {repsonseURL && (
                        <>
                            <img
                                src={repsonseURL}
                                alt="Preview"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '300px',
                                    marginTop: '10px',
                                }}
                            />
                            <Typography>
                                <Button variant="contained" color="secondary" onClick={handleDownload}>
                                    Download Image
                                </Button>
                            </Typography>
                        </>
                    )}
                </Typography>
            )}
        </div>
    );
}
