import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreateImage from './CreateImage';
import ImageVariation from './ImageVariation';
import ResizeImage from './ResizeImage';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import imageSegmentation from './ImageSegmentation';
import { useStateContext } from '../../../contexts/ContextProvider';

export default function ImageTools() {
    const { currentUser } = useStateContext();
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                width: '100%',
                typography: 'body1',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Resize" value="1" />
                        <Tab label="AI Image Generator" value="2" />
                        <Tab label="Image Styling" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <ResizeImage user_id={currentUser.user_id} />
                </TabPanel>
                <TabPanel value="2">
                    <CreateImage user_id={currentUser.user_id} />
                </TabPanel>
                <TabPanel value="3">
                    <ImageVariation user_id={currentUser.user_id} />
                </TabPanel>
            </TabContext>
        </Box>
    );
}
