import * as Yup from 'yup';

import { AddFolder, EditFolderNameDialog, ImageListSection } from '../components/dashboard/media';
import {
    AddMediaFolder,
    AddMediaResource,
    DeleteMediaResource,
    GetMediaCampaignUseNum,
    GetMediaFolderCampaignUseNum,
    GetMediaFolderList,
    GetMediaResourceList,
    UpdateMediaFolder,
} from '../api/api';
import {
    Box,
    Button,
    Drawer,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DeleteDialog, Pulse, UploadDialog } from '../components/dashboard/utils';
import { Field, Formik, useFormik } from 'formik';
import { GrChapterAdd, GrClose, GrDown, GrEdit, GrLock, GrTrash } from 'react-icons/gr';
import React, { useEffect, useState } from 'react';
import { RiArrowDownSLine, RiCloseCircleLine, RiFolderAddLine, RiTimeLine } from 'react-icons/ri';
import { alpha, styled } from '@mui/material/styles';
import { useNotification, useStateContext } from '../contexts/ContextProvider';

import { Header } from '../components/dashboard';
import { ImageTools } from '../components/dashboard/customised-image';
import { MdOutlineImportantDevices } from 'react-icons/md';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { TbCloudUpload } from 'react-icons/tb';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,

        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }),
);

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    marginTop: '10px',
    marginBottom: '10px',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0.5),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    overflow: 'auto',
    marginTop: '10px',
    marginBottom: '50px',
    maxHeight: '650px',
}));

const ImageListItemBarCenter = styled(ImageListItemBar)(({ theme }) => ({
    ' .MuiImageListItemBar-titleWrap': {
        padding: '0',
    },
    '& .MuiImageListItemBar-title': {
        textAlign: 'center',
        fontSize: '14px',
        paddingBottom: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
}));

const MediaManager = () => {
    const { currentUser, screenSize } = useStateContext();
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));

    const [folderType, setFolderType] = useState('');
    const [expanded, setExpanded] = useState();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [chosenfolderMedia, setChosenFolderMedia] = useState([]);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);

    //Get media folder list
    const [folderList, setFolderList] = useState([]);
    console.log('file: MediaManager.jsx:180 => MediaManager => folderList:', folderList);
    const getFolderList = async () => {
        const data = await GetMediaFolderList(switchUser, undefined, 100);
        if (data.code === 0) {
            setFolderList(data?.data?.list);
        }
    };

    useEffect(() => {
        getFolderList();
        ChosenFolderMedia(expanded);
    }, [triggerUpdate, switchUser]);

    useEffect(() => {
        setChosenFolderMedia([]);
    }, [expanded]);

    //select folder
    const [currentPage, setCurrentPage] = useState(0); // Current page number
    const [totalMedia, setTotalMedia] = useState();
    // Ensure consistent size for GetMediaResourceList in ChosenFolderMedia & handleLoadMore .
    const pageSize = 15;

    const ChosenFolderMedia = async (folder_id) => {
        const newPage = 1; // Set newPage to 1
        if (expanded !== folder_id) {
            setExpanded(folder_id);
            setFolderType(folderList.find((x) => x.folder_id === folder_id).folder_type);
            const data = await GetMediaResourceList(switchUser, folder_id, undefined, newPage, pageSize);
            console.log('data', data);
            if (data.code === 0) {
                setCurrentPage(newPage + 1);
                setTotalMedia(data.data.count);
                setChosenFolderMedia(data?.data?.list ? data?.data?.list : []);
            }
        } else {
            setExpanded();
        }
    };

    // handleLoadMore
    const handleLoadMore = async (folder_id) => {
        setCurrentPage((prev) => prev + 1);
        const data = await GetMediaResourceList(switchUser, folder_id, undefined, currentPage, pageSize);
        console.log('file: MediaManager.jsx:221 => handleLoadMore => data:', data);
        setChosenFolderMedia([...chosenfolderMedia, ...(data?.data?.list ? data?.data?.list : [])]);
    };

    // Open Edit Dialog
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [currentFolder, setCurrentFolder] = useState();
    const handleOpenEditDialog = (item) => {
        console.log('item:', item);
        // setFolderId(item.folder_id);
        setCurrentFolder(item);
        // setChangedFolderName(item.folder_name);
        setOpenEditDialog(true);
    };
    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    /**
     *
     * Add media to the folder
     *
     */
    // Add Media Resources to folder
    const handleOpenUploadDialog = () => {
        setOpenUploadDialog(true);
    };
    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
    };

    /***
     *
     * Delete dialog
     *
     */
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const [popUpInfo, setPopUpInfo] = useState();
    const [type, setType] = useState();

    const handleOpenDeleteDialog = (e, item, text) => {
        e.stopPropagation();
        console.log('item', item);
        // setPopUpInfo();
        const lowerCaseText = text?.toLowerCase();

        if (lowerCaseText === 'media') {
            console.log('text:', text);
            setType('media');
            handleGetNumber(item, text);
        } else {
            console.log('text:', text);
            setType('folder');
            handleGetNumber(item, text);
        }
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setPopUpInfo();
        setOpenDeleteDialog(false);
    };

    //  Get number of usage
    const handleGetNumber = async (item, text) => {
        var data;
        const lowerCaseText = text?.toLowerCase();
        if (lowerCaseText === 'media') {
            data = await GetMediaCampaignUseNum(item?.resource_id);
        } else {
            data = await GetMediaFolderCampaignUseNum(item?.folder_id);
        }

        console.log('data:', data.data);
        const total = data.data.total;
        if (total !== 0) {
            setPopUpInfo(
                data.data.total +
                    ' campaign are using media' +
                    (lowerCaseText !== 'media'
                        ? ' files in this folder.'
                        : '. \n Delete this media file will also delete the related campaigns & schedules. Are you sure to continue?'),
            );
        } else setPopUpInfo(' ');
    };

    // Drawer
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <ImageTools />
        </Box>
    );

    const filteredFolderList =
        currentUser.user_level !== 0 ? folderList?.filter((x) => x.folder_type !== 2) : folderList;
    return (
        <div>
            <div className="m-10 md:m-10 my-24 p-2 md:p-10 bg-white rounded-3xl">
                <Header title="Media Manager" />
                <AddFolder
                    currentUser={currentUser}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                />
            </div>
            <div className="m-20 p-2 md:m-10 md:p-10 bg-white rounded-3xl">
                <div className="flex flex-row justify-between">
                    <Header title="Media Folders" />
                    <Button
                        sx={{ marginTop: '15px', marginRight: '22px' }}
                        variant="outlined"
                        onClick={toggleDrawer('right', true)}
                    >
                        Customise Image
                    </Button>
                </div>
                <Drawer
                    anchor={'right'}
                    open={state['right']}
                    // variant="persistent"
                    onClose={toggleDrawer('right', false)}
                >
                    {list('right')}
                </Drawer>
                <div className="p-4 pt-0 ">
                    {filteredFolderList?.map((item) => (
                        <Accordion
                            key={item.folder_id}
                            expanded={expanded === item.folder_id}
                            onChange={(e) => {
                                e.stopPropagation();
                                setCurrentPage((prev) => (prev = 1));
                                ChosenFolderMedia(item.folder_id);
                            }}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                expandIcon={
                                    <GrDown
                                        sx={{
                                            fontSize: '0.9rem',
                                            color: '#12acd7',
                                        }}
                                    />
                                }
                            >
                                <div className="flex justify-between w-full ml-2.5">
                                    <div className="flex flex-row">
                                        <Typography>{item.folder_name}</Typography>
                                    </div>
                                    {item.folder_type !== 2 ||
                                    (item.folder_type === 2 && currentUser.user_level === 0) ? (
                                        <div className="flex flex-row z-50">
                                            {item.folder_type !== 2 && (
                                                <Tooltip
                                                    title="Edit folder name"
                                                    onClick={(e) => {
                                                        // e.stopPropagation();
                                                        handleOpenEditDialog(item, 'folder');
                                                    }}
                                                >
                                                    <div>
                                                        <IconButton size="small">
                                                            <GrEdit size={15} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            <Tooltip
                                                title="Delete folder"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpenDeleteDialog(e, item, 'mediaFolder');
                                                }}
                                            >
                                                <div>
                                                    <IconButton size="small">
                                                        <GrTrash size={15} />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{ backgroundColor: 'rgb(248, 249, 249)', marginBottom: '0px' }}
                            >
                                <div className=" w-full border-slate-300 mb-8">
                                    <ImageListSection
                                        handleOpenUploadDialog={handleOpenUploadDialog}
                                        handleOpenDeleteDialog={handleOpenDeleteDialog}
                                        chosenfolderMedia={chosenfolderMedia}
                                        item={item}
                                        currentUser={currentUser}
                                        screenSize={screenSize}
                                    />
                                </div>
                                {chosenfolderMedia?.length != totalMedia &&
                                    chosenfolderMedia?.length != 0 && (
                                        <div className="w-full flex justify-center">
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleLoadMore(item.folder_id)}
                                            >
                                                <span className="text-lg">Load More</span>
                                            </Button>
                                        </div>
                                    )}
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    {openUploadDialog && (
                        <UploadDialog
                            currentUser={currentUser}
                            folderId={expanded}
                            openUploadDialog={openUploadDialog}
                            setOpenUploadDialog={setOpenUploadDialog}
                            handleCloseUploadDialog={handleCloseUploadDialog}
                            setTriggerUpdate={setTriggerUpdate}
                            triggerUpdate={triggerUpdate}
                            folderType={folderType}
                        />
                    )}
                </div>
            </div>
            {openEditDialog && (
                <EditFolderNameDialog
                    currentUser={currentUser}
                    switchUser={switchUser}
                    currentFolder={currentFolder}
                    openEditDialog={openEditDialog}
                    handleCloseEditDialog={handleCloseEditDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    triggerUpdate={triggerUpdate}
                />
            )}

            {openDeleteDialog && popUpInfo && (
                <DeleteDialog
                    openDialog={openDeleteDialog}
                    handleCloseDialog={handleCloseDeleteDialog}
                    setTriggerUpdate={setTriggerUpdate}
                    text={type}
                    deleteItem={deleteItem}
                    triggerUpdate={triggerUpdate}
                    expanded={expanded}
                    switchUser={switchUser}
                    popUpInfo={popUpInfo}
                    setPopUpInfo={setPopUpInfo}
                    // resourceId={resourceId}
                />
            )}
        </div>
    );
};

export default MediaManager;
