import {
    ChangePwd,
    Configurations,
    ProfileDetails,
    TwoStepVerification,
} from '../components/dashboard/account-settings';
import React, { useEffect, useState } from 'react';

import { GetUserInfo } from '../api/api';
import { useStateContext } from '../contexts/ContextProvider';

const AccountSettings = () => {
    const { currentUser } = useStateContext();
    const [profileDetail, setProfileDetail] = useState();
    console.log('AccountSettings => profileDetail:', profileDetail);
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const [updateProfile, setUpdateProfile] = useState(true);

    /**
     *
     * Handle get profile detail
     *
     */
    const handleGetProfileDetail = async () => {
        const { data, code } = await GetUserInfo(switchUser ? switchUser : 1);
        console.log('handleGetProfileDetail => data:', data);
        if (code === 0) {
            setProfileDetail(data);
        }
    };

    useEffect(() => {
        handleGetProfileDetail();
    }, [updateProfile]);

    return (
        <div className="md:m-10 mt-48 p-2 md:p-10 bg-white rounded-3xl flex justify-center items-center">
            <div className="p-4 pt-0 w-4/5">
                <ProfileDetails
                    currentUser={currentUser}
                    profileDetail={profileDetail}
                    setUpdateProfile={setUpdateProfile}
                    updateProfile={updateProfile}
                />
                <Configurations
                    profileDetail={profileDetail}
                    setUpdateProfile={setUpdateProfile}
                    updateProfile={updateProfile}
                />
                <ChangePwd />
                <TwoStepVerification
                    profileDetail={profileDetail}
                    setUpdateProfile={setUpdateProfile}
                    updateProfile={updateProfile}
                />
            </div>
        </div>
    );
};

export default AccountSettings;
