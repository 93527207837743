import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { CopyCampaign, CopyTemplate } from '../../../api/api';
import React, { useEffect, useState } from 'react';

import { Pulse } from '../utils';
import { useNotification } from '../../../contexts/ContextProvider';

const DeviceDetailDialog = ({ openDeviceDetailDialog, handleCloseDeviceDetailDialog, deviceDetail }) => {
    console.log(
        'file: DeviceDetailDialog.jsx:8 => DeviceDetailDialog => openDeviceDetailDialog:',
        openDeviceDetailDialog,
    );
    return (
        <>
            <Dialog open={openDeviceDetailDialog} fullWidth={true} maxWidth="md">
                <DialogTitle sx={{ fontSize: '18px', fontWeight: 'bold' }}>Contract History</DialogTitle>
                <DialogContent sx={{ maxHeight: '450px' }}>
                    {Array.isArray(deviceDetail) && deviceDetail.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="contract history table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            Contract term
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            Start Date
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            End Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deviceDetail?.map((contract) => (
                                        <TableRow>
                                            <TableCell align="center">{contract.terms}</TableCell>
                                            <TableCell align="center">{contract.start_date}</TableCell>
                                            <TableCell align="center">{contract.end_date}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Pulse />
                    )}
                </DialogContent>

                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={handleCloseDeviceDetailDialog}>
                        Cancel
                    </Button>
                    <Button variant="contained">Copy</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeviceDetailDialog;
