import { AiFillDelete, AiFillEdit, AiFillFolder, AiFillFolderOpen } from 'react-icons/ai';
import { DeleteDevice, GetDeviceInfo } from '../../../api/api';
import { DeleteDialog, Pulse } from '../utils';
import { GrDown, GrEdit, GrNext, GrSystem, GrTrash } from 'react-icons/gr';
import { IconButton, Input, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import EditDeviceDialog from './EditDeviceDialog';
import { FiMonitor } from 'react-icons/fi';
import { MdOutlineImportantDevices } from 'react-icons/md';
import { MdOutlineMonitor } from 'react-icons/md';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { set } from 'lodash';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../../../contexts/ContextProvider';

// import { RiDeleteBin6Fill, RiFolder3Fill, RiSettings2Fill } from 'react-icons/ri';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<GrDown sx={{ fontSize: '0.9rem', color: '#12acd7' }} />} {...props} />)(({ theme }) => ({
    marginTop: '10px',
    marginBottom: '10px',
    // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(2),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const DeviceList = ({ assignedDeviceList, unassignedDeviceList, locationList, setTriggerUpdate, triggerUpdate }) => {
    console.log('unassignedDeviceList', unassignedDeviceList);
    console.log('assignedDeviceList', assignedDeviceList);
    const [assignedExpanded, setAssignedExpanded] = useState('');
    const [unassignedExpanded, setUnassignedExpanded] = useState('');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const handleAssignedExpand = (panel) => (newExpanded) => {
        console.log('panel', panel);
        setAssignedExpanded(newExpanded ? (panel === assignedExpanded ? false : panel) : false);
    };
    const handleUnassignedExpand = (panel) => (newExpanded) => {
        console.log('panel', panel);
        setUnassignedExpanded(newExpanded ? (panel === unassignedExpanded ? false : panel) : false);
    };

    // Fetch detail of all the devices
    const [assignedDeviceDetail, setAssignedDeviceDetail] = useState([]);
    const [unassignedDeviceDetail, setUnassignedDeviceDetail] = useState([]);

    // Open edit device dialog
    const [deviceDetail, setDeviceDetail] = useState();
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const getDetail = async (item) => {
        const data = await GetDeviceInfo(item.device_id);
        console.log('file: DeviceList.jsx:87 => getDetail => data:', data);
        setDeviceDetail(data.data);
    };
    const handleOpenEditDialog = (item) => {
        console.log('item', item);
        getDetail(item);
        // localStorage.setItem(
        // 	'currentDevice',
        // 	JSON.stringify(DeviceDetailList.find((x) => x.device_id === item.device_id))
        // );
        // setDeviceDetail(DeviceDetailList.find((x) => x.device_id === item.device_id));
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const handleOpenDeleteDialog = (item) => {
        console.log('item', item);
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <div>
            <Accordion expanded={unassignedExpanded === 'Unassigned'} onChange={handleUnassignedExpand('Unassigned')}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    // expandIcon={unassignedExpanded==='Unassigned' ? <AiFillFolderOpen /> : <AiFillFolder />}
                >
                    <div className="flex justify-between w-full">
                        <div className="flex flex-row">
                            <Typography>Unassigned Devices</Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <List dense={true}>
                        {unassignedDeviceList ? (
                            unassignedDeviceList?.map((item, index) => (
                                <ListItem
                                    className="hover:bg-cyan-100/50 rounded-lg cursor-pointer"
                                    key={item.device_name}
                                    onClick={() => handleOpenEditDialog(item)}
                                    secondaryAction={
                                        <div className="flex flex-row">
                                            <Tooltip title="Device Settings">
                                                <div>
                                                    <IconButton size="small">
                                                        <GrEdit size={15} />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                            {currentUser.user_level === 0 && (
                                                <Tooltip
                                                    title="Delete Device"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenDeleteDialog(item);
                                                    }}
                                                >
                                                    <div>
                                                        <IconButton size="small">
                                                            <GrTrash size={15} />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    }
                                >
                                    <ListItemIcon>
                                        <FiMonitor style={{ color: item?.device_status === 'OFFLINE' ? 'red' : 'green', fontSize: '20px' }} />
                                    </ListItemIcon>

                                    <div>
                                        <p className="text-center m-0 ">
                                            {item?.device_name}{' '}
                                            <span className="text-[10px] text-black-500  px-1 rounded pb-1 ">
                                                <span className={`${item?.device_status === 'OFFLINE' ? 'text-red-500' : 'text-green-500'} text-sx font-bold`}>{item?.device_status}</span>
                                                {item?.device_status === 'OFFLINE' && ` Last online: ${item?.last_online}`}
                                            </span>
                                        </p>
                                        {/* <p className="m-0">{item?.device_name}</p>
                                        <span className="text-xs text-gray-500 bg-red-100">
                                            [Status: <span className={item?.device_status === 'OFFLINE' ? 'text-red-500' : 'text-green-500'}>{item?.device_status}</span>| Last online: {item?.last_online}]
                                        </span>{' '} */}
                                    </div>
                                </ListItem>
                            ))
                        ) : (
                            <Pulse />
                        )}
                    </List>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={assignedExpanded === 'Assigned'} onChange={handleAssignedExpand('Assigned')}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    // expandIcon={assignedExpanded==='Assigned' ? <AiFillFolderOpen /> : <AiFillFolder />}
                >
                    <div className="flex justify-between w-full">
                        <div className="flex flex-row">
                            <Typography>Assigned Devices</Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <List dense={true}>
                        {assignedDeviceList?.map((item, index) => (
                            <ListItem
                                className="hover:bg-cyan-100/50 rounded-lg cursor-pointer"
                                key={item.device_name}
                                onClick={() => handleOpenEditDialog(item)}
                                secondaryAction={
                                    <div className="flex flex-row">
                                        <Tooltip title="Device Settings">
                                            <div>
                                                <IconButton size="small">
                                                    <GrEdit />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        {currentUser.user_level === 0 && (
                                            <Tooltip
                                                title="Delete Device"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpenDeleteDialog(item);
                                                }}
                                            >
                                                <div>
                                                    <IconButton size="small">
                                                        <GrTrash />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        )}
                                    </div>
                                }
                            >
                                <ListItemIcon>
                                    <FiMonitor style={{ color: item?.device_status === 'OFFLINE' ? 'red' : 'green', fontSize: '20px' }} />
                                </ListItemIcon>
                                <div>
                                    <p className="text-center m-0 ">
                                        {item?.device_name}{' '}
                                        <span className="text-[10px] text-black-500  px-1 rounded pb-1 ">
                                            <span className={`${item?.device_status === 'OFFLINE' ? 'text-red-500' : 'text-green-500'} text-sx font-bold`}>{item?.device_status}</span>
                                            {item?.device_status === 'OFFLINE' && ` Last online: ${item?.last_online}`}
                                        </span>
                                    </p>
                                    {/* <p className="m-0">{item?.device_name}</p>
                                    <span className="text-xs text-gray-500 bg-red-100">
                                        [Status: <span className={item?.device_status === 'OFFLINE' ? 'text-red-500' : 'text-green-500'}>{item?.device_status}</span>| Last online: {item?.last_online}]
                                    </span>{' '} */}
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            {openEditDialog && (
                <EditDeviceDialog
                    openEditDialog={openEditDialog}
                    handleCloseEditDialog={handleCloseEditDialog}
                    deviceDetail={deviceDetail}
                    currentUser={currentUser}
                    locationList={locationList}
                    triggerUpdate={triggerUpdate}
                    setTriggerUpdate={setTriggerUpdate}
                />
            )}
            {openDeleteDialog && <DeleteDialog openDialog={openDeleteDialog} handleCloseDialog={handleCloseDeleteDialog} setTriggerUpdate={setTriggerUpdate} text={'device'} deleteItem={deleteItem} triggerUpdate={triggerUpdate} />}
        </div>
    );
};

export default DeviceList;
