import React from 'react';
import Image1 from '../images/blogBrightonImage1.png';
import Image2 from '../images/blogBrightonImage2.png';
import Image3 from '../images/blogBrightonImage3.png';


const BrightonGrammar = () => {
  return (
    <div className="container mx-auto max-w-6xl bg-white p-20 my-20">
      <h1 className="text-4xl text-display text-center px-auto mb-2 font-bold">Digital Signage Solutions for </h1>
      <h1 className="text-4xl text-display text-center px-auto mb-3 font-bold">Brighton Grammar School</h1>

      <p className="text-base text-gray-400 mb-12 text-display text-center">9/8/2022</p>
      <img
        src={Image1}
        alt="Description"
        className="w-full h-auto my-4"
      />
      <p className="text-base text-gray-700 mb-4">
        Cybercast offers a stable, flexible, and easy-to-use platform to help schools and universities deliver a high-quality educational experience throughout campuses and classrooms.
      </p>
      <section className="mb-10">
        <h2 className="text-3xl text-display mb-8 font-semibold">About
          the Client</h2>
        <p className="text-base text-gray-700 mb-4">
          Brighton Grammar is a leading private boys' school in Melbourne, with strong community traditions and a culture of excellence.
        </p>
        <p className="text-base text-gray-700 mb-4">
          Founded in 1882, the school has a non-selective enrolment policy with over 1,400 students from ELC to VCE.        </p>

      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Opportunity</h2>
        <p className="text-base text-gray-700 mb-4">
          Progressive schools and universities are turning to digital signage to meet the challenge of managing communications with thousands of students, staff, and visitors on large campuses. As well as innovating how, educational material is delivered and consumed in the classrooms. A great example of this lies at Brighton Grammar School (BGS)
        </p>
        <p className="text-base text-gray-700 mb-4">
          Brighton Grammar School was looking at various digital solutions to solve separate set of challenges and goals across all areas of the school and Cybercast was the answer to achieving them.                </p>
          <img
        src={Image2}
        alt="Description"
        className="w-full h-auto my-4"
      />
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Solution</h2>
        <p className="text-base text-gray-700 mb-4">

          The Cybercast digital signage solution improves how they connect people on campus and better delivers education. Its usage enhances learning, promotes safety, improves information dissemination, elevates branding, facilitates sporting matches, and provides entertainment.
        </p>

        <p className="text-base  font-semibold mb-2 px-8">
          •	Enhance Classroom Learning with Interactive Touch Displays
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Enhance the learning experience with quality digital displays and interactive touch screens that’s compatible with other media integration and technology. The touch experience provides hands-on learning and collaboration, especially important for foundation primary years.
        </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Promote Safety via Quality Digital Displays
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Remind students, faculty and visitors about personal hygiene and safe distancing measures; keep the school community safe with emergency alerts.
        </p>

        <p className="text-base text-gray-700 font-semibold mb-2 px-8">
          •	Communicate Campus Events with Kiosks
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Cultivate strong student and staff communities by displaying campus news and events.
        </p>

        <p className="text-base text-gray-700 font-semibold mb-2 px-8">
          •	Boost Branding with Reception and
          Auditorium Signage
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Create a pleasant vibe for visitors and strengthen branding by featuring welcome messages on digital screens at the school lobby.
        </p>

        <p className="text-base text-gray-700 font-semibold mb-2 px-8">
          •	Facilitate Sporting Matches with Outdoor LED Digital Display
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          A super bright, high-resolution outdoor LED screen is versatile enough to keep scores of a sporting game, as well as publishing other types of multimedia and application to engage crowds at outdoor venues.
        </p>
        <img
        src={Image3}
        alt="Description"
        className="w-full h-auto my-4"
      />
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Results & Benefits</h2>
        <p className="text-base text-gray-700 mb-4">
          Over 100 screens have been deployed at Brighton Grammer School. The benefits of digital signage in Education are countless. Cybercast is able to facilitate these benefits with an array of quality digital displays and a powerful, user-friendly content management system. Allowing BGS to design and publish compelling and timely content to the relevant audience.
        </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Redefine campus-wide communication
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Capture the attention of students, faculty, and visitors with vivid digital displays. Update
          information easily, in seconds.
        </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Connect with students & faculty members         </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Digital display screens for schools promote a sense of community by featuring highlights of
          student life and campus events.
        </p>

        <p className="text-base text-gray-700 font-semibold mb-2 px-8">
          •	Revamp brand messaging        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Welcome visitors with stunning digital displays, providing an impressive first and lasting impression. Showcase student achievements at the reception area.        </p>

        <p className="text-base text-gray-700 font-semibold mb-2 px-8">
          •	Engage and entertain visitors
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Keep visitors entertained with live news feeds, weather reports and sports updates. As well as keeping sporting crowds on the ‘edge of their seat’ with a responsive vivid scoreboard.        </p>

        <p className="text-base text-gray-700 font-semibold mb-2 px-8">
          •	Enhance learning        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Digital signage for schools makes learning more fun and enjoyable by visual simulation of ideas and concepts.        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Future</h2>
        <p className="text-base text-gray-700 mb-4">
          Tusted by top educational institutions, Cybercast enables schools and universities to drive digital transformation and create enriched learning environments.        </p>
        <p className="text-base text-gray-700 mb-4">

          Other applications to explore & adopt includes:
        </p>
        <p className="text-base font-semibold mb-2 px-8">
          •	Classroom scheduling
        </p>
        <p className="text-base font-semibold mb-2 px-8">
          •	Social media videowalls
        </p>
        <p className="text-base font-semibold mb-2 px-8">
          •	Wayfinding and interactive kiosks
        </p>
        <p className="text-base font-semibold mb-2 px-8">
          •	Digital bulletin and menu boards
        </p>
      </section>
    </div>
  );
};

export default BrightonGrammar;
