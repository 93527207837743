import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';

import { AddMediaFolder } from '../../../api/api';
import { useNotification } from '../../../contexts/ContextProvider';

const FolderNameInput = styled(TextField)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        height: '30px',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: '5px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));
const AddFolder = ({ triggerUpdate, setTriggerUpdate, currentUser }) => {
    const notify = useNotification();
    const [folderName, setFolderName] = useState('');

    //Add Media Folder
    const handleAddFolder = async () => {
        const data = await AddMediaFolder(folderName);
        console.log('file: AddFolder.jsx:49 => handleAddFolder => data:', data);
        if (data.code === 0) {
            setFolderName('');

            setTriggerUpdate(!triggerUpdate);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Folder was added successfully!',
                },
            });
        } else {
            setFolderName('');
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: data.msg,
                },
            });
        }
    };

    return (
        // <div>
        <div className="p-4 pt-0">
            <FolderNameInput
                sx={{
                    margin: '2px',
                    padding: '0',
                    marginRight: '8px',
                }}
                placeholder="Folder Name"
                margin="normal"
                name="folder_name"
                onChange={(e) => setFolderName(e.target.value)}
                value={folderName}
                variant="outlined"
            />
            <Button className="h-8" variant="contained" onClick={() => handleAddFolder()}>
                Add Folder
            </Button>
        </div>
        // </div>
    );
};

export default AddFolder;
