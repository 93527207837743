import { Box, Button, FormControl, MenuItem, Select, Tab, Tabs } from '@mui/material';
// import FormControl from '@mui/material/FormControl';
import { GetCyberpoint, GetPriceList } from './Api';
import { Getalldevice, Paycyberpoint } from './Api';
import react, { useEffect, useState } from 'react';

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-bootstrap/Modal';
//import PaypalPay from './PaypalPay';
import PointsDialog from './PointsDialog';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
// import Select from '@mui/material/Select';
import StripePay from './StripePay';
// import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { current } from '@reduxjs/toolkit';
// import Tabs from 'react-bootstrap/Tabs';
import moment from 'moment';
import { styled } from '@mui/material/styles';

/// mui select

const StyledTab = styled(Tab)(({ theme }) => ({
    // borderRadius: '50%',
    // paddingRight: '30px',
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div sx={{ p: 3 }}>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const DeviceSummary = ({ user_id, customerType, username }) => {
    const body = { userId: user_id };

    /**
     *
     *
     * Handle change tabValue
     *
     */
    const [tabValue, setTabValue] = useState(0);
    console.log('file: DeviceSummary.jsx:65 => DeviceSummary => tabValue:', tabValue);

    const handleChangeTab = (e, newValue) => {
        console.log('file: DeviceSummary.jsx:67 => handleChangeTab => newValue:', newValue);
        if (newValue === 0) {
            getDeviceList(body);
        }

        if (newValue === 1) {
            console.log(selectedrows);
        }
        setTabValue(newValue);
    };

    // const [activeTab, setActiveTab] = useState('device');

    useEffect(() => {
        console.log('file: DeviceSummary.jsx:81 => useEffect => tabValue:', tabValue);
        if (tabValue === 0) {
            getDeviceList(body);

            setSelectedrows([]);
        }
    }, [tabValue]);

    const [devices, setdevices] = useState([]);
    const getDeviceList = async () => {
        //console.log(abc)

        const data = await Getalldevice(body);
        console.log('GetDeviceList:', data.message);

        const total = data.message;
        total.map((dev) => {
            dev.type = '1 year';
        });

        setdevices(total);
    };

    const getstartdate = (expire_time) => {
        const x = moment.unix(expire_time);
        const y = moment();

        if (x > y) {
            return x.format('DD/MM/YYYY');
        } else {
            return y.format('DD/MM/YYYY');
        }
    };
    const getenddate = (expire_time, type) => {
        const enddate = () => {
            const a = moment.unix(expire_time);
            const b = moment();
            if (a > b) {
                return a;
            }
            return b;
        };

        if (type === '3 months') {
            return enddate().add(3, 'month').format('DD/MM/YYYY');
        }
        if (type === '6 months') {
            return enddate().add(6, 'month').format('DD/MM/YYYY');
        }
        if (type === '1 year') {
            return enddate().add(1, 'year').format('DD/MM/YYYY');
        }
    };
    const unitprice = (type, dev) => {
        if (type === '6 months') {
            if (isBusinessCustomer == true) {
                dev['price'] = price_list.BS180;
                return dev['price'];
            } else {
                dev['price'] = price_list.GS180;
                return dev['price'];
            }
        }
        if (type === '1 year') {
            if (isBusinessCustomer == true) {
                dev['price'] = price_list.BS360;
                return dev['price'];
            } else {
                dev['price'] = price_list.GS360;
                return dev['price'];
            }
        }
    };

    const [selectedrows, setSelectedrows] = useState([]);
    console.log('file: DeviceSummary.jsx:185 => DeviceSummary => selectedrows:', selectedrows);
    const handleRenewTypeChange = (id, type) => {
        const updatedRows = [...devices];
        const rowToUpdate = updatedRows.find((row) => row.id === id);
        rowToUpdate.type = type;
        rowToUpdate.new_end = getenddate(rowToUpdate.expire_time, type);

        // Update the selectedrows state based on the updated renew type

        setdevices(updatedRows);
        console.log(`Row ${id} renew type changed to ${type}`);
        console.log('row to update', rowToUpdate);
        console.log('update row', updatedRows);
    };

    const totalPrice = selectedrows.reduce((accumulator, row) => accumulator + row.price, 0);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        console.log('file: DeviceSummary.jsx:206 => handleChange => checked:', checked);

        if (checked) {
            let tempUser = devices.map((dev) => {
                if (dev.subscription === 'true') {
                    dev['new_start'] = getstartdate(dev.expire_time);
                    dev['new_end'] = getenddate(dev.expire_time, dev.type);

                    return { ...dev, selected: !checked };
                } else {
                    dev['new_start'] = getstartdate(dev.expire_time);
                    dev['new_end'] = getenddate(dev.expire_time, dev.type);

                    return { ...dev, selected: checked };
                }
            });
            setdevices(tempUser);
            if (tempUser.filter((x) => x.selected === true)) {
                setSelectedrows(tempUser.filter((x) => x.selected === true));
            } else {
                setSelectedrows([]);
            }

            console.log('tempUser', tempUser);
        } else {
            let tempUser = devices.map((dev) => ({
                ...dev,
                selected: checked,
            }));
            setdevices(tempUser);
            if (tempUser.filter((x) => x.selected === true)) {
                setSelectedrows(tempUser.filter((x) => x.selected === true));
            } else {
                setSelectedrows([]);
            }

            console.log('tempUser', tempUser);
        }
    };

    const handleRSelect = (dev, allrow) => {
        const updatedRows = [...devices];
        const selectedRow = updatedRows.find((row) => row.id === dev.id);
        selectedRow.selected = !selectedRow.selected;
        setdevices(updatedRows);

        if (selectedRow.selected === true) {
            dev['new_start'] = getstartdate(dev.expire_time);
            dev['new_end'] = getenddate(dev.expire_time, dev.type);

            setSelectedrows([...selectedrows, dev]);
        } else {
            setSelectedrows(selectedrows.filter((r) => r !== dev));
        }
        //console.log("rrrrrrrrrrrrrrrrr", updatedRows);
    };

    ///
    const oneYearArray = selectedrows.filter((item) => item.type === '1 year');

    const numberOfOneYearItems = oneYearArray.length;

    const sixMonthsArray = selectedrows.filter((item) => item.type === '6 months');

    const numberOfsixMonthsItems = sixMonthsArray.length;

    const subtotal = (totalPrice / 1.1).toFixed(2);
    const gst = (totalPrice - subtotal).toFixed(2);

    ///
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [ppshow, setppShow] = useState(false);
    const pphandleClose = () => setppShow(false);
    const pphandleShow = () => setppShow(true);

    /// set customer type

    const [isBusinessCustomer, setIsBusinessCustomer] = useState(customerType === 2 ? true : false);
    const handleSwitchChange = () => {
        if (customerType === 2 && isBusinessCustomer) {
            const confirmed = window.confirm(
                "Are you certain about transitioning from a business customer to a general one? This switch entails forfeiting several exclusive benefits, such as the loss of unlimited storage and warranty coverage. It's crucial to carefully consider these implications before proceeding with the change.",
            );
            if (confirmed) {
                setIsBusinessCustomer(!isBusinessCustomer);
            }
        } else {
            setIsBusinessCustomer(!isBusinessCustomer);
        }

        console.log('bbb');
    };
    const customer_type = isBusinessCustomer ? 'Business' : 'General';

    const [price_list, setprice_list] = useState({
        BS360: 10,
        BS180: 10,

        GS180: 10,
        GS360: 10,
    });

    const [price_id_list, setprice_id_list] = useState({});

    const getPriceList = async () => {
        const data_list = await GetPriceList();
        console.log('GetPriceList:', data_list.message);

        const extractedData = {};
        const extractedIdData = {}; //get the price id data

        // Iterate through the object and extract the "amount" values
        for (const key in data_list.message) {
            if (data_list.message.hasOwnProperty(key)) {
                extractedData[key] = data_list.message[key].amount;
                extractedIdData[key] = data_list.message[key].price;
            }
        }

        setprice_list(extractedData);
        setprice_id_list(extractedIdData);
    };
    const [cyberPoints, setCyberPoints] = useState(0);
    console.log('file: DeviceSummary.jsx:328 => DeviceSummary => cyberPoints:', cyberPoints);
    const handleGetPoints = async () => {
        const data = await GetCyberpoint(user_id);
        console.log('file: DeviceSummary.jsx:333 => handleGetPoints => data:', data);
        setCyberPoints(data.message);
    };
    useEffect(() => {
        getPriceList();
        handleGetPoints();
        console.log('GetAmountList:', price_list);
        console.log('GetIdList:', price_id_list);
    }, []);

    // Handle show cyberpoints dialog
    const [openPointsDialog, setOpenPointsDialog] = useState(false);

    const handleOpenPointsDialog = () => {
        setOpenPointsDialog(true);
    };
    const handleClosePointsDialog = () => {
        setOpenPointsDialog(false);
    };

    return (
        <div>
            <Tabs value={tabValue} onChange={handleChangeTab} centered>
                <StyledTab
                    value={0}
                    label={'1. Select device'}
                    iconPosition="end"
                    {...a11yProps(0)}
                />
                <StyledTab value={1} label={'2. Review'} {...a11yProps(1)} disabled />
                {/* <StyledTab label={'Contract'} {...a11yProps(1)} /> */}
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <div style={{ textAlign: 'right' }}>
                    <Button
                        variant="contained"
                        onClick={(e) => handleChangeTab(e, 1)}
                        disabled={selectedrows.length === 0}
                    >
                        next
                    </Button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Form>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label={customer_type}
                            checked={isBusinessCustomer}
                            onChange={handleSwitchChange}
                        />
                    </Form>
                </div>
                <div>
                    {devices?.length > 0 ? (
                        <div className="overflow-x-scroll">
                            {/* {selectedrows?.length > 0 ? (
                                <p className="text-center">Total price is {totalPrice}</p>
                            ) : (
                                <p className="text-center"></p>
                            )} */}

                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>
                                            <Form.Check
                                                type="checkbox"
                                                checked={
                                                    devices.filter((dev) => dev?.selected === true)
                                                        .length +
                                                        devices.filter(
                                                            (dev) => dev.subscription === 'true',
                                                        ).length ===
                                                    devices.length
                                                }
                                                onChange={handleChange}
                                                name="allSelect"
                                            />
                                        </th>

                                        <th>Device ID</th>
                                        <th>Device Name</th>
                                        <th>Group Name</th>
                                        <th>Expire Date</th>
                                        <th>Renew Type</th>
                                        <th>New Start Date</th>
                                        <th>New End Date</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {devices.map((dev) => {
                                        //dev['isChecked'] = false
                                        //dev.type = 'renewtype'
                                    })}

                                    {devices &&
                                        devices.map((dev) => (
                                            <tr
                                                key={dev.id}
                                                title={
                                                    dev.subscription === 'true'
                                                        ? 'Subscription is active. This row is disabled. Please manage your subcription in subcription page.'
                                                        : ''
                                                }
                                            >
                                                <td>
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        //checked={selectedrows.includes(dev)}
                                                        checked={dev?.selected || false}
                                                        onChange={() => handleRSelect(dev)}
                                                        disabled={dev.subscription === 'true'}
                                                    />
                                                </td>
                                                {/* </div> */}
                                                <td>{dev.id}</td>
                                                <td>{dev.device_name}</td>
                                                <td>{dev.group_name}</td>
                                                <td>
                                                    {moment
                                                        .unix(dev.expire_time)
                                                        .format('DD/MM/YYYY')}
                                                </td>

                                                <td>
                                                    <FormControl
                                                        sx={{
                                                            m: 1,
                                                            // minWidth: 120,
                                                        }}
                                                        size="small"
                                                    >
                                                        <Select
                                                            labelId="select-row-label"
                                                            id="select-row"
                                                            value={dev.type}
                                                            disabled={dev.subscription === 'true'}
                                                            onChange={(e) =>
                                                                handleRenewTypeChange(
                                                                    dev.id,
                                                                    e.target.value,
                                                                )
                                                            }
                                                        >
                                                            <MenuItem value="1 year">
                                                                1 year
                                                            </MenuItem>
                                                            <MenuItem value="6 months">
                                                                6 months
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </td>

                                                <td>{getstartdate(dev.expire_time)}</td>

                                                <td>{getenddate(dev.expire_time, dev.type)}</td>
                                                <td>${unitprice(dev.type, dev)}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                    ) : (
                        <div>
                            <p></p>
                        </div>
                    )}
                </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <div className="mb-4" style={{ textAlign: 'right' }}>
                    <Button variant="contained" onClick={(e) => handleChangeTab(e, 0)}>
                        back
                    </Button>
                </div>
                <Card className="text-center">
                    <Card.Header>Confirm Your Order</Card.Header>
                    <Card.Body>
                        {oneYearArray?.length > 0 ? (
                            <div>
                                Cybercast Subscription {customer_type} Edition - Yearly x{' '}
                                {numberOfOneYearItems}
                                <div>
                                    {oneYearArray.map((element) => {
                                        return (
                                            <Badge bg="secondary" key={element.id}>
                                                {element.id}
                                            </Badge>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}

                        {sixMonthsArray?.length > 0 ? (
                            <div>
                                Cybercast Subscription {customer_type} Edition - 6 Monthly x{' '}
                                {numberOfsixMonthsItems}
                                <div>
                                    {sixMonthsArray.map((element) => {
                                        return (
                                            <Badge bg="secondary" key={element.id}>
                                                {element.id}
                                            </Badge>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <div className="flex flex-col">
                            <span>Subtotal: ${subtotal}</span>
                            <span>GST: ${gst}</span>
                            <span>Total: ${totalPrice}</span>
                        </div>
                    </Card.Body>
                    <Card.Footer className="text-muted">Select Payment Method</Card.Footer>
                </Card>

                <div className="d-flex justify-content-center ">
                    <Row>
                        <Col>
                            <Card style={{ width: '18rem', margin: '10px' }}>
                                <Card.Body>
                                    <Card.Title>Pay With CyberPoints</Card.Title>
                                    <Card.Text>Free Payment Available: {cyberPoints}</Card.Text>
                                    <div className="w-full flex justify-center">
                                        <Button
                                            sx={{ margin: '10px' }}
                                            variant="contained"
                                            // disabled={totalPrice >= cyberPoints}
                                            onClick={handleOpenPointsDialog}
                                        >
                                            Go Checkout
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                            <PointsDialog
                                openPointsDialog={openPointsDialog}
                                handleClosePointsDialog={handleClosePointsDialog}
                                userId={user_id}
                                selectedrows={selectedrows}
                                cyberPoints={cyberPoints}
                                totalPrice={totalPrice}
                                customerType={customerType}
                            />
                        </Col>

                        <Col>
                            <Card style={{ width: '18rem', margin: '10px' }}>
                                <Card.Body>
                                    <Card.Title>Debit/Credit Card</Card.Title>
                                    <Card.Text>You will be redirected to Stripe</Card.Text>
                                    <div className="w-full flex justify-center">
                                        <Button
                                            sx={{ margin: '10px' }}
                                            variant="contained"
                                            onClick={handleShow}
                                        >
                                            Go Checkout
                                        </Button>
                                    </div>
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Debit/Credit Card</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>You will be redirected to Stripe.</p>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <Button variant="outlined" onClick={handleClose}>
                                                Close
                                            </Button>

                                            <StripePay
                                                user_id={user_id}
                                                username={username}
                                                selectedrows={selectedrows}
                                                numberOfsixMonthsItems={numberOfsixMonthsItems}
                                                numberOfOneYearItems={numberOfOneYearItems}
                                                isBusinessCustomer={isBusinessCustomer}
                                                price_id_list={price_id_list}
                                                customerType={customerType}
                                            />
                                        </Modal.Footer>
                                    </Modal>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </TabPanel>
        </div>
    );
};

export default DeviceSummary;
