import 'bootstrap/dist/css/bootstrap.min.css';

import * as Yup from 'yup';

import { AddMediaDialog, AddTextDialog, AddURLDialog, MediaWidget, TextWidget, WebWidget } from './';
// import { AddMediaDialog, AddTextDialog, AddURLDialog } from './';
import {
    AddMediaResource,
    GetCampaignResizeModeList,
    GetCampaignTransitionEffectList,
    GetMediaFolderList,
    GetMediaResourceList,
    UpdateCampaign,
} from '../../../api/api';
import { Box, Button, DialogTitle, FormControl, FormLabel, MenuItem, Stack, TextField } from '@mui/material';
import { Field, Formik, useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { addMedia, resetMedia } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';

import Carousel from 'react-bootstrap/Carousel';
import { Header } from '../';
import { useNavigate } from 'react-router-dom';

const ELEMENT_COLORS = {
    image: '#9CA3AF',
    video: '#61AFA3',
    mix: '#61AFA3',
    text: '#FFBD88',
    web: '#88C0FF',
};
const style = {
    labelText: {
        minWidth: '100px',
        display: 'flex',
        textAlign: 'start',
        // paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
        color: 'black',
    },
};
const EditCampaign = () => {
    const navigate = useNavigate();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const dispatch = useDispatch();

    // const currentCampaign = JSON.parse(localStorage.getItem('editCurrentCampaign' || {}));
    // console.log('currentCampaign', currentCampaign);
    /**
     *
     *  Get state from redux
     * 	List of media
     *
     */
    const media = useSelector((state) => state.handleMedia);
    console.log('media', media);

    /**
     * GetCampaignTransitionEffectList
     */
    const [effectList, setEffectList] = useState([]);
    console.log('EditCampaign => effectList:', effectList);
    const GetEffectList = async () => {
        const data = await GetCampaignTransitionEffectList();
        setEffectList(data?.data?.list);
    };

    useEffect(() => {
        handleGetResizeMode();
        GetEffectList();
    }, []);

    const [openAddMediaDialog, setOpenAddMediaDialog] = useState(false);
    const [currentElement, setCurrentElement] = useState([]);
    const [currentTextJSON, setCurrentTextJSON] = useState();
    const [openTextDialog, setOpenTextDialog] = useState(false);
    const [openWebDialog, setOpenWebDialog] = useState(false);

    const [newElement, setNewElement] = useState([]);
    const [currentElementList, setCurrentElementList] = useState([]);
    console.log('currentElementList:', currentElementList);
    const [currentTemplate, setCurrentTemplate] = useState();
    const [resizeMode, setResizeMode] = useState([]);
    console.log('currentTemplate:', currentTemplate);
    const currentCampaign = JSON.parse(localStorage.getItem('editCurrentCampaign'));
    console.log('file: EditCampaign.jsx:99 => EditCampaign => currentCampaign:', currentCampaign);
    const [selectedResizeMode, setSelectedResizeMode] = useState(
        currentCampaign?.detail_list[0]?.resize_mode ? currentCampaign?.detail_list[0]?.resize_mode : '',
    );
    console.log('selectedResizeMode:', selectedResizeMode);
    useEffect(() => {
        setCurrentElementList([currentCampaign?.detail_list[0]?.template_element]);
        // setAppliedMedia([]);
        const detailList = currentCampaign?.detail_list;
        console.log('detailList:', detailList);
        const elementList = [];

        for (let i = 0; i < detailList?.length; i++) {
            elementList.push(detailList[i].template_element);
        }
        console.log('elementList 1:', elementList);

        const newList = detailList?.map(({ template_element, ...rest }) => rest);
        elementList.map((element) => {
            const matchedElement = newList.find((x) => x.element_id === element.element_id);
            if (matchedElement) {
                element.detail_list = matchedElement;
                return element;
            }
        });

        setCurrentTemplate(elementList);
        // setSelectedResizeMode(elementList[0]?.detail_list?.resize_mode);
    }, [localStorage.getItem('editCurrentCampaign')]);
    const handleCloseTextDialog = () => {
        setOpenTextDialog(false);
    };
    const handleOpenChooseMeidaDialog = (item) => {
        console.log('file: EditCampaign.jsx:109 => handleOpenChooseMeidaDialog => item:', item);
        setCurrentElement(() => media?.find((x) => x.element_id === item.element_id));
        localStorage.setItem('current_element', JSON.stringify(item));
        // setOpenAddMediaDialog(true);
        if (item.element_type === 'video' || item.element_type === 'image' || item.element_type === 'mix') {
            setOpenAddMediaDialog(true);
        }
        if (item.element_type === 'text') {
            setOpenTextDialog(true);
            setCurrentTextJSON(
                media?.find((x) => x.element_id === item.element_id)
                    ? media?.find((x) => x.element_id === item.element_id)
                    : null,
            );
        }
        if (item.element_type === 'web') {
            setOpenWebDialog(true);
        }
    };
    const handleCloseWebDialog = () => {
        setOpenWebDialog(false);
    };

    // const [appliedMeida, setAppliedMedia] = useState([]);
    const [campaignName, setCampaigName] = useState(currentCampaign?.campaign_name);
    console.log('campaignName:', campaignName);
    const handleChange = (e) => {
        setCampaigName(e.target.value);
    };

    const HandleUpdateCampaign = async () => {
        const element_list = currentCampaign.detail_list;
        console.log('element_list:', element_list);

        console.log('file: EditCampaigxn.js:147 => constnew_list=media.map => media:', media);
        const new_list = media.map(({ effect_duration, element_id, transition_effect, resource_list }) => ({
            effect_duration: effect_duration ? effect_duration : 1,
            element_id,
            transition_effect: transition_effect ? transition_effect : 2,
            detail_list: resource_list,
        }));

        console.log('new_list:', new_list);

        const reformedList = new_list.map(({ detail_list, ...rest }) => ({
            ...rest,
            resize_mode: selectedResizeMode,
            effect_duration: detail_list?.length > 1 ? rest.effect_duration : 0,
            transition_effect: detail_list?.length > 1 ? rest.transition_effect : 0,
            content_list: detail_list.map((i) => {
                console.log('file: EditCampaign.jsx:185 => content_list:detail_list.map => i:', i);
                if (i.resource_type === 'image' || i.resource_type === 'video') {
                    return {
                        resource_duration:
                            i.resource_type === 'image' && detail_list.length > 1 ? i.resource_duration : 0,
                        resource_id: i.resource_id,
                        resource_text: JSON.stringify({}),
                    };
                }
                if (i.resource_type === 'text') {
                    return {
                        resource_duration: 0,
                        resource_id: 0,
                        resource_text: JSON.stringify(i.resource_text),
                    };
                }
                if (i.resource_type === 'web') {
                    return {
                        resource_duration: 0,
                        resource_id: 0,
                        resource_text: JSON.stringify({}),
                        resource_website: i.resource_website,
                    };
                }
            }),
            detail_id: currentCampaign?.detail_list?.find((d) => d.element_id === rest.element_id)?.detail_id,
        }));

        console.log('reformedList:', reformedList);
        const final_list = element_list.map((el) => {
            const matched_base = reformedList.find((base) => base.element_id === el.element_id);
            console.log('matched_base:', matched_base);
            if (matched_base) {
                return matched_base;
            } else {
                return {
                    effect_duration: 0,
                    resize_mode: 0,
                    element_id: el.element_id,
                    transition_effect: 0,
                    content_list: [],
                };
            }
        });
        console.log('final_list:', final_list);

        const { code, data, msg } = await UpdateCampaign(
            switchUser === 1 ? undefined : switchUser,
            currentCampaign?.campaign_id,
            campaignName,
            final_list,
        );
        console.log('data', data);
        if (code === 0) {
            navigate('../dashboard/campaign-manager', { replace: true });
            dispatch(resetMedia());
            localStorage.removeItem('editCurrentCampaign');
        }
        // else {
        //     alert(msg);
        // }
    };

    const handleGetResizeMode = async (item) => {
        const { code, data, msg } = await GetCampaignResizeModeList();
        if (code === 0) {
            console.log('GetCampaignResizeModeList:', data);
            setResizeMode(data?.list.filter((x) => x.resize_mode !== 2 && x.resize_mode !== 3));
        } else {
            // alert(msg);
            console.log('file: EditCampaign.jsx:235 => handleGetResizeMode => msg:', msg);
        }
    };

    const handleSelectResizeMode = (e) => {
        const { value } = e.target;
        console.log('value:', value);
        setSelectedResizeMode(value);
    };

    // Handle Cancel Edit
    const handleCancelEdit = () => {
        dispatch(resetMedia());
        navigate('../dashboard/campaign-manager');
    };

    const getResizeStyle = () => ({
        objectFit:
            selectedResizeMode === 1
                ? 'contain'
                : selectedResizeMode === 2
                ? 'none'
                : selectedResizeMode === 3
                ? 'none'
                : selectedResizeMode === 4
                ? 'fill'
                : 'cover',
        width: selectedResizeMode === 2 ? '100%' : selectedResizeMode === 3 ? 'auto' : '100%',
        height: selectedResizeMode === 2 ? 'auto' : selectedResizeMode === 3 ? '100%' : '100%',
    });

    const applyTextStyle = (styleConfig) => ({
        color: styleConfig?.font_colour,
        backgroundColor: styleConfig?.background_color,
        textAlign: styleConfig?.text_layout,
        fontSize: `${styleConfig?.font_size}px !important`,
        fontWeight: styleConfig?.font_style?.includes('B') ? 'bold !important' : 'normal',
        fontStyle: styleConfig?.font_style?.includes('I') ? 'italic' : 'normal',
        textShadow: styleConfig?.font_style?.includes('A') ? ' 1px 1px 2px black' : 'none',
    });
    const TextWidgetElement = ({ element }) => (
        <div className="w-full h-full">
            {[element?.detail_list?.resource_content.find((x) => x.resource_type === 'text')]?.map((m, index) =>
                m?.resource_text && m?.resource_text.enable_scrolling === 1 ? (
                    <marquee
                        key={m.element_id}
                        className="w-full h-full"
                        behavior="scroll"
                        direction={m.resource_text.scrolling_direction}
                        scrollamount={m.resource_text?.scrolling_speed}
                        style={applyTextStyle(m.resource_text)}
                    >
                        {m.resource_text.text_content}
                    </marquee>
                ) : (
                    m.element_id === element.element_id && (
                        <p className="w-full h-full" style={applyTextStyle(m.resource_list[0])}>
                            {m.resource_list[0]?.resource_text.text_content}
                        </p>
                    )
                ),
            )}
        </div>
    );

    const MediaWidgetElement = ({ element, item }) => {
        console.log('file: CampaignDetailDialog.jsx:92 => MediaWidgetElement => element:', element);
        console.log('file: CampaignDetailDialog.jsx:92 => CampaignDetailDialog => item:', item);
        const carouselRef = useRef(null);
        const videoRef = useRef(null);
        const intervalIdRef = useRef(null); // 新增这一行
        const [activeIndex, setActiveIndex] = useState(0);
        const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
        console.log('file: MediaWidget.jsx:7 => MediaWidget => item:', item);
        console.log('file: MediaWidget.jsx:7 => MediaWidget => media:', media);

        // Enable auto play the video
        const handleSelect = (selectedIndex, e) => {
            console.log('==================================');
            console.log('进入了 handleSelect block！！！');
            console.log('file: MediaWidget.jsx:30 => handleSelect => selectedIndex:', selectedIndex);
            console.log('==================================');
            setActiveIndex(selectedIndex);
        };

        const handleVideoEnded = () => {
            console.log('==================================');
            console.log('进入了视频结束block！！！');
            console.log('==================================');
            // Check if the last video has ended before transitioning to the next item
            const nextIndex = currentVideoIndex + 1;
            if (nextIndex < item.resource_content.length) {
                const nextResource = item.resource_content[nextIndex];

                // Check if the next resource is a video
                if (nextResource.resource_type === 'video') {
                    carouselRef.current?.next();
                    console.log('==================================');
                    console.log('本次视频，执行了下一个视频！！！');
                } else {
                    // If the next resource is an image, reset the video index and trigger the autoplay logic
                    setActiveIndex(nextIndex);
                }
            } else {
                // If the last video has ended, reset the index to 0
                setCurrentVideoIndex(0);
                setActiveIndex(0);
            }
        };

        useEffect(() => {
            console.log('==================================');
            console.log('进入了 activeIndex block！！！');
            console.log('==================================');
            const activeMedia = media?.find((x) => x.element_id === item.element_id);
            console.log('useEffect => activeIndex:', activeIndex);
            const selectedResource = activeMedia?.resource_list[activeIndex];
            console.log('useEffect => selectedResource:', selectedResource);

            if (selectedResource) {
                if (selectedResource.resource_type === 'video') {
                    setCurrentVideoIndex(activeIndex);
                    console.log('==================================');
                    console.log('进入了 activeIndex video');
                    console.log('==================================');

                    const videoElement = document.getElementById(
                        `video-${selectedResource.resource_id}-${activeIndex}`,
                    );
                    if (videoElement) {
                        console.log('useEffect => videoElement:', videoElement);
                        videoElement.pause();
                        videoElement.currentTime = 0;
                        videoElement.play();
                        console.log('确保视频加载成功，并成功播放');
                    }
                }

                // Check if it's an image, and handle autoplay based on the resource type
                if (selectedResource.resource_type === 'image') {
                    const duration = selectedResource.resource_duration * 1000;
                    if (duration) {
                        console.log('==================================');
                        console.log('duration 执行了，时间戳开始：', Date.now());
                        console.log('==================================');

                        const intervalId = setInterval(() => {
                            // 使用函数式的形式更新 activeIndex
                            // setActiveIndex((prevIndex) => (prevIndex + 1) % item?.resource_content?.length);
                            carouselRef.current?.next();
                            console.log('展示了下一张图片，时间戳：', Date.now());
                        }, duration);

                        return () => {
                            clearInterval(intervalId);
                            console.log('清除了 interval，时间戳结束：', Date.now());
                        };
                    }
                }
            }
        }, [activeIndex, media]);
        console.log('MediaWidgetElement => media:', media);

        return (
            <Carousel
                ref={carouselRef}
                onSelect={handleSelect}
                activeIndex={activeIndex}
                className="absolute h-full w-full"
                indicators={false}
                controls={false}
                interval={null}
                fade={item?.transition_effect === 1}
                slide={item?.transition_effect !== 1}
            >
                {media?.length > 0 &&
                    media?.map((x) =>
                        x?.resource_list?.map(
                            (m, index) =>
                                x.element_id === element.element_id &&
                                m && (
                                    <Carousel.Item
                                        key={`${m.resource_id}-${index}`}
                                        className="absolute h-full w-full"
                                        style={{
                                            transition: `transform ${item.effect_duration}s ease`,
                                        }}
                                    >
                                        {m?.resource_type === 'image' ? (
                                            <img style={getResizeStyle()} src={m.resource_url} />
                                        ) : (
                                            <video
                                                ref={videoRef}
                                                id={`video-${m.resource_id}-${index}`}
                                                loading="lazy"
                                                className="items-center"
                                                style={getResizeStyle()}
                                                controls
                                                muted // Add the muted attribute
                                                onEnded={handleVideoEnded}
                                                src={m.resource_url}
                                            />
                                        )}
                                    </Carousel.Item>
                                ),
                        ),
                    )}
            </Carousel>
        );
    };

    const WebWidgetElement = ({ element }) => {
        console.log('EditCampaign => element:', element);
        return (
            <div className="w-full h-full">
                {media
                    ?.find((w) => w.element_id === element?.element_id)
                    ?.resource_list?.map((m, index) => (
                        <div className="w-full h-full" key={`${m.resource_text}-${index}`}>
                            <iframe src={m.resource_website} title="Embedded Page" width="100%" height="100%"></iframe>
                        </div>
                    ))}
            </div>
        );
    };

    return (
        <div className="md:m-10 mt-24 p-2  md:p-10 bg-white rounded-3xl">
            <Header title="Edit Campaign" />
            <div className="p-4 pt-0">
                <TextField
                    fullWidth
                    label="Campaign Name"
                    margin="normal"
                    name="name"
                    value={campaignName}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div className="w-full flex flex-col">
                    {currentCampaign?.element_layout === 0 ? (
                        <div
                            className="relative"
                            style={{
                                marginTop: '12px',
                                //1920x1080
                                width: 650,
                                height: 370,
                                backgroundColor: 'primary.white',
                                border: '5px solid gray',
                            }}
                        >
                            {currentTemplate?.map((element) =>
                                currentCampaign?.detail_list?.map(
                                    (item, index) =>
                                        element.element_id &&
                                        item.element_id && (
                                            <div
                                                key={`${element.element_id}-${index}`}
                                                className="absolute border-dashed border-1 border-orange-600"
                                                style={{
                                                    backgroundColor: ELEMENT_COLORS[item.element_type],
                                                    width: (element?.element_width / 100) * 640,
                                                    height: (element?.element_height / 100) * 360,
                                                    top: (element?.element_y / 100) * 360,
                                                    left: (element?.element_x / 100) * 640,
                                                }}
                                                onClick={() =>
                                                    element.element_type !== 'web' &&
                                                    handleOpenChooseMeidaDialog(element)
                                                }
                                            >
                                                <div
                                                    className={`${
                                                        element.element_type === 'web'
                                                            ? 'cursor-pointer widget absolute'
                                                            : 'widget absolute'
                                                    }`}
                                                >
                                                    <p
                                                        className={'text-sm m-0'}
                                                        onClick={() =>
                                                            element.element_type === 'web'
                                                                ? handleOpenChooseMeidaDialog(element)
                                                                : ''
                                                        }
                                                    >
                                                        {element.element_type}
                                                    </p>
                                                </div>
                                                {element.element_type === 'image' ||
                                                element.element_type === 'video' ||
                                                element.element_type === 'mix' ? (
                                                    <MediaWidgetElement element={element} item={item} />
                                                ) : element.element_type === 'text' ? (
                                                    <TextWidgetElement element={element} />
                                                ) : (
                                                    <WebWidgetElement element={element} />
                                                )}
                                            </div>
                                        ),
                                ),
                            )}
                        </div>
                    ) : (
                        <div
                            className="relative"
                            style={{
                                marginTop: '12px',
                                //1920x1080
                                width: 490,
                                height: 721,
                                backgroundColor: 'primary.white',
                                border: '5px solid gray',
                            }}
                        >
                            {currentTemplate?.map((element) =>
                                currentCampaign?.detail_list?.map(
                                    (item, index) =>
                                        element.element_id &&
                                        item.element_id && (
                                            <div
                                                key={`${item}-${index}`}
                                                className="cursor-pointer absolute border-dashed border-1 border-orange-600"
                                                style={{
                                                    backgroundColor: ELEMENT_COLORS[item.element_type],
                                                    width: (element?.element_width / 100) * 480,
                                                    height: (element?.element_height / 100) * 711,
                                                    top: (element?.element_y / 100) * 711,
                                                    left: (element?.element_x / 100) * 480,
                                                }}
                                                onClick={() => handleOpenChooseMeidaDialog(element)}
                                            >
                                                <div className="widget absolute z-10 ">
                                                    <p className="text-sm m-0">{element.element_type}</p>
                                                </div>
                                                {element.element_type === 'image' ||
                                                element.element_type === 'video' ||
                                                element.element_type === 'mix' ? (
                                                    <MediaWidgetElement element={element} item={item} />
                                                ) : element.element_type === 'text' ? (
                                                    <TextWidgetElement element={element} />
                                                ) : (
                                                    <WebWidgetElement element={element} />
                                                )}
                                            </div>
                                        ),
                                ),
                            )}
                        </div>
                    )}
                    <div className="md:w-full ">
                        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {resizeMode.length > 0 && (
                                <TextField
                                    fullWidth
                                    select
                                    margin="normal"
                                    label="Image Responsive to Template"
                                    name="resize"
                                    value={selectedResizeMode}
                                    onChange={(e) => handleSelectResizeMode(e)}
                                    variant="outlined"
                                >
                                    {resizeMode?.map((option) => (
                                        <MenuItem key={option?.resize_mode} value={option?.resize_mode}>
                                            {option?.resize_mode_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        </FormControl>
                    </div>
                </div>
                {openAddMediaDialog && (
                    <AddMediaDialog
                        openAddMediaDialog={openAddMediaDialog}
                        setOpenAddMediaDialog={setOpenAddMediaDialog}
                        currentUser={currentUser}
                        // setAppliedMedia={setAppliedMedia}
                        currentElement={currentElement}
                        setCurrentElement={setCurrentElement}
                        setNewElement={setNewElement}
                        effectList={effectList}
                        element_id={currentElement.element_id}
                        action={'edit'}
                    />
                )}
                {openTextDialog && (
                    <AddTextDialog
                        openTextDialog={openTextDialog}
                        handleCloseTextDialog={handleCloseTextDialog}
                        currentTextJSON={currentTextJSON}
                        // currentTextJSON={}
                        // setOpenAddMediaDialog={setOpenAddMediaDialog}
                        currentUser={currentUser}
                        // setAppliedMedia={setAppliedMedia}
                        currentElement={currentElement}
                        setCurrentElement={setCurrentElement}
                        // setNewElement={setNewElement}
                        // effectList={effectList}
                        // element_id={currentElement.element_id}
                        // action={'new'}
                        // setTextJson={setTextJson}
                    />
                )}
                {openWebDialog && (
                    <AddURLDialog
                        openWebDialog={openWebDialog}
                        handleCloseWebDialog={handleCloseWebDialog}
                        status="edit"
                        currentUser={currentUser}
                        currentElement={currentElement}
                        setCurrentElement={setCurrentElement}
                    />
                )}
                <Stack direction="row" spacing={2} className="flex justify-end w-full mt-4">
                    <Button variant="outlined" onClick={() => handleCancelEdit()}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => HandleUpdateCampaign()}>
                        Save
                    </Button>
                </Stack>
            </div>
        </div>
    );
};

export default EditCampaign;
