import {
	Button,
	Dialog,
	DialogTitle,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import React, { useState } from 'react';

function OrderSummaryPopup({
	openOrderDetails,
	orderDetails,
	handleCloseOrderDetails,
}) {
	return (
		<Dialog open={openOrderDetails} onClose={handleCloseOrderDetails}>
			<DialogTitle>Detailed Device Information</DialogTitle>
			<div style={{ padding: '16px' }}>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Device ID</TableCell>
								<TableCell>Start Date</TableCell>
								<TableCell>End Date</TableCell>
								<TableCell>Contract Type</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{orderDetails.map((each) => (
								<TableRow className='order_info' key={each.id}>
									<TableCell>{each.id}</TableCell>
									<TableCell>{each.new_start}</TableCell>
									<TableCell>{each.new_end}</TableCell>
									<TableCell>{each.type}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</Dialog>
	);
}

export default OrderSummaryPopup;
