import { List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { GetOperationLogList } from '../api/api';
import { Header } from '../components/dashboard';
import { Pulse } from '../components/dashboard/utils';
import { useStateContext } from '../contexts/ContextProvider';

const Log = () => {
    const { currentUser } = useStateContext();
    console.log('file: Log.jsx:75 => Log => currentUser:', currentUser);
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [logCount, setLogCount] = useState();
    const [logList, setLogList] = useState([]);
    console.log('file: Log.jsx:74 => Log => logList:', logList);
    const [logDetails, setLogDetails] = useState();
    const handleLoadMore = async () => {
        setCurrentPage((prev) => prev + 1);
        const { code, data } = await GetOperationLogList(currentPage + 1, 100);
        console.log('file: Log.jsx:85 => handleLoadMore => data:', data);
        if (code === 0) {
            setLogList([...logList, ...data.list]);
        }
    };

    const handleGetLog = async () => {
        const { code, data } = await GetOperationLogList(currentPage, 100);
        if (code === 0) {
            setLogCount(data.count);

            setLogList(data?.list);
        }
    };

    useEffect(() => {
        handleGetLog();
    }, []);

    const handleClickLog = (selectedLog) => {
        console.log('file: Log.jsx:114 => handleClickLog => selectedLog:', selectedLog);
        setLogDetails(selectedLog);
    };
    return (
        <div className="m-10 p-2 md:m-10 md:p-10 bg-white rounded-3xl">
            <Header title="Log" />
            {logList.length > 0 ? (
                <div className="grid sm:grid-cols-2 gap-4 divide-x divide-soild xs:grid-cols-1">
                    <div className="p-2 w-full md:overflow-auto">
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 480,
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                        >
                            <ListSubheader>{`Logs: ${logCount}`}</ListSubheader>

                            {logList.map((log) => (
                                <li key={`item-${log.log_id}-${log.log_id}`}>
                                    <ul>
                                        {/* {[0, 1, 2].map((item) => ( */}
                                        <ListItemButton
                                            // key={`item-${log}-${log}`}
                                            value={log}
                                            onClick={() => handleClickLog(log)}
                                        >
                                            <ListItemText
                                                primary={`${log.log_id} ${log.operation_result} ${log.created_date}`}
                                            ></ListItemText>
                                        </ListItemButton>

                                        {/* ))} */}
                                    </ul>
                                </li>
                            ))}
                            <ListItemButton onClick={handleLoadMore}>
                                <ListItemText
                                    primary={<div className="flex justify-center">Load more</div>}
                                />
                            </ListItemButton>
                        </List>
                    </div>
                    {logDetails && (
                        <div className="pl-4 pt-2 pr-2 md:pt-0 pb-6">
                            <div className="mx-4 h-full pr-2 overflow-auto">
                                <h3 className=" font-bold mb-2">Details</h3>
                                <div className="flex flex-row justify-between ">
                                    <div>
                                        <p>Log ID: {logDetails.log_id}</p>{' '}
                                    </div>
                                    <div>
                                        <p>Operation ID: {logDetails.operation_id}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col ">
                                    <p>Behaviour: {logDetails.operation_behavior}</p>{' '}
                                    <p>Method Name: {logDetails.operation_method_name}</p>
                                    <p>Module: {logDetails.operation_module}</p>
                                    <p>Result: {logDetails.operation_result}</p>
                                    <p>User: {logDetails.operation_user_name}</p>
                                </div>
                                <p>Change Detail:</p>
                                <p className="break-words">{logDetails.change_detail}</p>
                                <p>Before Data:</p>
                                <p className="break-words">{logDetails.before_data}</p>
                                <p>After Data:</p>
                                <p className="break-words">{logDetails.after_data}</p>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Pulse />
            )}
        </div>
    );
};

export default Log;
