import React from 'react';
import Image1 from '../images/blogMitreimage1.png';
import Image2 from '../images/blogMitreimage2.png';
import Image3 from '../images/blogMitreimage3.png';
import Image4 from '../images/blogMitreimage4.png';

const Mitre = () => {
  return (
    <div className="container mx-auto max-w-6xl bg-white p-20 my-20">
      <h1 className="text-4xl text-display text-center px-auto mb-2 font-bold">Digital Signage Solutions for Retail</h1>
      <p className="text-base text-gray-400 mb-12 text-display text-center">14/9/2022</p>

      <img
        src={Image1}
        alt="Description"
        className="w-full h-auto my-4"
      />
      <p className="text-base text-gray-700 mb-4">
        How Independent Hardware Group (IHG) used Cybercast digital signage platform to deliver a unique customer user experience for their Mitre 10 hardware stores and enhance their brand image.
      </p>
      <section className="mb-10">
        <h2 className="text-3xl text-display mb-8 font-semibold">About
          the Client</h2>
        <p className="text-base text-gray-700 mb-4">
          Mitre 10, formed in 1959, is a large player in the Australian home improvement and hardware industry.        </p>
        <p className="text-base text-gray-700 mb-4">
          The Mitre 10 group comprises of Australia's largest independent home improvement and hardware wholesaler to the industry and an independent and local retail network of over 300 Mitre 10 and True Value Hardware stores.
        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">Overview</h2>
        <p className="text-base text-gray-700 mb-4">
          Present an entire new ‘home connect’ IoT product range inside stores that would inform and educate customers as well as their in-store staff. The new range of IoT products is a break-away from Mitre 10’s traditional hardware supply. So it requires a digital solution to present and compliment the range and enhance the store’s image as a digital supplier.        </p>
        <p className="text-base text-gray-700 mb-4">
          The catalogue range includes over 100 products by 7 different suppliers. The solution needs to take into consideration their customer’s shopping behaviour, which was previously formulated internally by Mitre 10. Mitre 10 needs the ability to add and remove products, as well as update images and descriptions. Remote control is required for one single screen, as well as all screens across every store. Source of content would be driven manually from head office via local directories. Integration with Mitre 10’s existing intranet product portal is required for product consistency and updates.
        </p>
        <img
          src={Image2}
          alt="Description"
          className="w-full h-auto my-4"
        />
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Solution</h2>
        <p className="text-base text-gray-700 mb-4">

          Cybercast – Cloud Digital Signage would be used as the platform to control content publishing, monitoring and managing of screens.        </p>

        <img
          src={Image3}
          alt="Description"
          className="w-full h-auto my-4"
        /><p className="text-base  font-semibold mb-2 px-8">
          •	Digital Touchscreen Kiosks
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Digital kiosk with touch screen capabilities was chosen to present the content and interface with customers and staff. Mitre 10 would engage their store fitter to physically wrap the kiosk and house them in custom-built timber cabinets to match the store’s décor.        </p>

        <p className="text-base text-gray-700  mb-4 px-8">
          Data capture would be built into the app for activity tracking; what products are touched and searched. Staged rollouts would be adopted.
          Debut of the kiosks would take place at the IHG’s Industry Expo where it would be presented to store managers and staff. This would be followed by rollouts to their ‘sapphire’ (premium) stores and then to the rest of other stores.
        </p>
        <p className="text-base text-gray-700 font-semibold mb-2 px-8">
          •	LED Videowall
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Further promotional reinforcement was achieved via the deployment of an LED videowall. Rich, multi-media content is broadcast to the wall for maximum impact and communication        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          These walls complimented the kiosk solution as it compelled customers to interact with the kiosk ‘Connected Homes’ application to learn more about the products, thus driving customer engagement.        </p>

      </section>
      <img
        src={Image4}
        alt="Description"
        className="w-full h-auto my-4"
      />
      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Results & Benefits</h2>
        <p className="text-base text-gray-700 mb-4">
          Promotional efforts were published to the screen as an advertising channel. The interactive app encouraged customer engagement and learning.
          Staff interaction with the screen allowed
          self-learning. Intelligence derived from activity tacking drove marketing initiatives and product sourcing, reducing the number of products they purchase and host.
        </p>
      </section>

    </div>
  );
};

export default Mitre;
