import { BottomSignUp, FeaturesSlider, GetStarted, News } from '../components/website';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import BannerBg from '../images/bannerBg.png';
import Button from '@mui/material/Button';
import GetStartedBg from '../images/GetStarted.png';
import { Stack } from '@mui/material';
import image1 from '../images/featurepage1.png';
import image2 from '../images/featurepage2.png';
import image3 from '../images/featurepage3.png';
import image4 from '../images/featurepage4.png';
import image5 from '../images/featurepage5.png';
import { useStateContext } from '../contexts/ContextProvider';

const Features = () => {
    return (
        <div className="flex flex-col items-center w-full bg-custom-bg">
            {/* First row- Typography */}
            <div className="max-w-6xl mx-auto justify-around space-y-8 text-center items-center py-25 px-2">
                <div className="max-w-5xl mx-auto">
                    <h6 className="font-display font-bold text-4xl mb-3 md:text-5xl">
                        Cybercast Platform Features{' '}
                    </h6>
                    <span>Discover what our innovative CMS platform can offer you</span>
                </div>
            </div>
            {/* Second row - Feature intro */}
            <div className="w-full flex justify-center mx-auto px-2">
                <FeaturesSlider />
            </div>
            {/* third row */}
            <div className="hidden md:block md:w-full md:h-198 md:pt-20 md:pb-20 bg-customPrimary relative overflow-hidden">
                <div className="max-w-6xl mx-auto relative z-20">
                    <div className="ml-0 mt-48 " style={{ maxWidth: '450px' }}>
                        <span className="font-display font-bold text-5xl text-white">
                            Fully bespoke solutions
                        </span>
                        <p className="mt-3 text-white">
                            Want a solution tailored to your business’ needs? We create custom
                            bespoke solutions to bring your ideas to life.
                        </p>
                    </div>
                </div>
                <img
                    src={BannerBg}
                    alt="BannerBg"
                    className="absolute z-10"
                    style={{
                        // maxWidth: 'none', // Override max-width
                        height: '792px',
                        top: '100%', // Move the image to the bottom
                        left: '100%', // Move the image to the right
                        transform: 'translate(-100%, -100%)',
                    }}
                />
            </div>
            {/* fourth row Feature des */}
            <div className="max-w-6xl mx-auto mt-24 ">
                <h6 className="font-display font-bold text-4xl mb-3 md:text-5xl">
                    Easy Content Creation{' '}
                </h6>
            </div>
            <div className="max-w-6xl md:flex sm:flex-row justify-between items-center space-x-6 mt-14 mb-24">
                <div
                    className="md:w-1/3 items-center mb-auto"
                    style={{ maxWidth: '400px', minWidth: '220px' }}
                >
                    <img src={image1} alt="image" />
                    <div className="w-full pt-5 text-center space-y-3 px-6">
                        <span className="font-display font-bold text-2xl">
                            Import Content from multiple sources
                        </span>
                        <p>
                            Import content to your library from a file upload, shared link or
                            YouTube link{' '}
                        </p>
                    </div>
                </div>
                <div
                    className="md:w-1/3 items-center mb-auto"
                    style={{ maxWidth: '400px', minWidth: '220px' }}
                >
                    <img src={image2} alt="image" />
                    <div className="w-full pt-5 text-center space-y-3 px-6">
                        <span className="font-display font-bold text-2xl">
                            Supports Interactivity
                        </span>
                        <p>Interactive templates with full touchscreen capability</p>
                    </div>
                </div>
                <div
                    className="md:w-1/3 items-center mb-auto"
                    style={{ maxWidth: '400px', minWidth: '220px' }}
                >
                    <img src={image3} alt="image" />
                    <div className="w-full pt-5 text-center space-y-3 px-6">
                        <span className="font-display font-bold text-2xl">
                            Multiple Templates for all screen types
                        </span>
                        <p>
                            Showcase your content exactly how you want to, with templates for all
                            screens and dimensions.
                        </p>
                    </div>
                </div>
            </div>
            {/* Fourth row */}
            <div className="w-full pt-25 pb-25 bg-white">
                <div className="max-w-6xl justify-center items-center mx-auto md:flex">
                    <div className="md:w-1/2 mx-auto px-12">
                        <h6 className="font-display font-bold text-4xl md:text-5xl">
                            AI & Data analysis{' '}
                        </h6>
                        <p className="mt-3 mb-4">
                            our system has the ability to accurately track and count the number of
                            individuals walking through the shopping centre. Using advanced
                            technology, we can provide real-time data on foot traffic, allowing you
                            to gain valuable insights into customer behaviour and make informed
                            business decisions.
                        </p>
                    </div>
                    <img src={image4} alt="image" className="md:w-1/2 px-12" />
                </div>
            </div>
            {/* Fifth row */}
            <div className="w-full pt-25 pb-25">
                <div className="max-w-6xl justify-center items-center mx-auto md:flex">
                    <img src={image5} alt="image" className="md:w-1/2 px-12" />

                    <div className="md:w-1/2 mx-auto  px-12">
                        <h6 className="font-display font-bold text-4xl md:text-5xl">
                            End to End Support{' '}
                        </h6>
                        <p className="mt-3 mb-4">
                            With direct access to our Developers, Technicians and Designers, you’ll
                            always feel supported.
                        </p>
                    </div>
                </div>
            </div>
            {/* Sixth row */}
            {/* <div className="w-full pt-25 pb-44">
                <News />
            </div> */}
            <div
                className="w-full pt-20 pb-44 relative bg-cover bg-center h-screen flex justify-center"
                style={{ backgroundImage: `url(${GetStartedBg})`, height: '511px' }}
            >
                <GetStarted />
            </div>
            {/* <div className="w-full pt-10 pb-10 ">
                <BottomSignUp />
            </div> */}
        </div>
    );
};
export default Features;
