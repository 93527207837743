import {
    Badge,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
const Header = ({ category, title, badgeText }) => (
    <div className="p-4">
        <p className="text-lg text-gray-400">{category}</p>
        <div className="flex flex-row">
            <div>
                <p className="text-xl font-bold tracking-tight text-slate-900" style={{ color: '#12acd7' }}>
                    {title}
                </p>
            </div>
            <div>
                {/* {badgeText} */}
                {badgeText && (
                    <div
                        className={`ml-3  font-bold items-center px-2 h-full  text-[11px] uppercase rounded-full flex flex-row ${
                            badgeText.toLowerCase() === 'disabled'
                                ? 'bg-rose-200 text-rose-600 '
                                : 'bg-green-200 text-green-600  '
                        }`}
                    >
                        {badgeText.toLowerCase() !== 'disabled' ? (
                            <>
                                <span className="text-green-600">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        role="presentation"
                                        focusable="false"
                                    >
                                        <path
                                            fill="rgb(0, 121, 77)"
                                            fill-rule="evenodd"
                                            d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12m5.414-.929 2.829 2.828 6.364-6.364L18.02 8.95l-7.778 7.778L6 12.485z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </span>
                                <span
                                    className="font-bold ml-1 normal-case "
                                    style={{ color: 'rgb(0, 121, 77)' }}
                                >
                                    {badgeText}
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="text-green-600 invisible" style={{ display: 'none' }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        role="presentation"
                                        focusable="false"
                                    >
                                        <path
                                            fill="rgb(0, 121, 77)"
                                            fill-rule="evenodd"
                                            d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12m5.414-.929 2.829 2.828 6.364-6.364L18.02 8.95l-7.778 7.778L6 12.485z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </span>
                                <span
                                    className="font-bold ml-1 normal-case text-red-600"
                                    // style={{ color: 'rgb(0, 121, 77)' }}
                                >
                                    {badgeText}
                                </span>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    </div>
);

export default Header;
