import { BottomSignUp, GetStarted } from '../components/website';

import GetStartedBg from '../images/GetStarted.png';
import React from 'react';

const Page404 = () => {
    return (
        <div className="w-full items-center bg-custom-bg">
            <div className=" flex flex-col items-center justify-center bg-white h-screen text-center ">
                <h6 className="font-display font-bold text-5xl md:text-6xl">404</h6>
                <span className="mt-3 ">Woopoose! The page doesn't exist.</span>
            </div>

            {/*  Get started*/}
            <div
                className="w-full pb-44 relative bg-cover bg-center h-screen flex justify-center"
                style={{ backgroundImage: `url(${GetStartedBg})`, height: '511px' }}
            >
                <GetStarted />
            </div>
            {/*  bottom Get started */}
            {/* <div className="w-full pt-10 pb-10 ">
                <BottomSignUp />
            </div> */}
        </div>
    );
};
export default Page404;
