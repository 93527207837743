import { Card, List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { GetDeviceList, GetUserList } from '../../../api/api';
import React, { useEffect, useState } from 'react';

import { Header } from '../';
import { Pulse } from '../utils';
import { useStateContext } from '../../../contexts/ContextProvider';
import useWebSocket from '../../../api/websocket';

const Admin = () => {
    const { currentUser } = useStateContext();
    const [userList, setUserList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [selectedDevice, setSelectedDevice] = useState({});

    console.log('file: Log.jsx:75 => Log => currentUser:', currentUser);

    const [deviceDetail, setDeviceDetail] = useState();

    useEffect(() => {
        setUserList(JSON.parse(localStorage.getItem('allUsers')));
    }, [localStorage.getItem('allUsers')]);

    const handleSelectedUser = (user, index) => {
        console.log('file: Log.jsx:114 => handleClickLog => user:', user);
        setSelectedUser(user);
        setSelectedIndex(index);
        handleGetUserDevice(user.user_id);
    };

    const handleGetUserDevice = async (id) => {
        const data = await GetDeviceList(id);
        console.log('handleGetUserDevice => data:', data.data.assign_device_list);
        setDeviceList(data.data.assign_device_list);
    };

    const handleSelectedDevice = (device) => {
        setSelectedDevice(device);
        handleSendMessage(device.device_id);
    };
    const { messages, sendMessage, sendHeartbeat } = useWebSocket('ws://13.55.17.16:8080/ws');
    console.log('Admin => sendMessage:', sendMessage);
    console.log('Admin => messages:', messages);

    // useEffect(() => {
    //     // 每60秒发送一次心跳检测
    //     const heartbeatInterval = setInterval(() => {
    //         sendHeartbeat();
    //     }, 60000);

    //     return () => clearInterval(heartbeatInterval);
    // }, [sendHeartbeat]);

    // 示例：发送一个消息
    const handleSendMessage = (deviceId) => {
        sendHeartbeat();
        sendMessage({ client_type: 1, message_type: 'showDevicePlayCampaign', device_id: deviceId });
    };
    // // 发送获取设备最新播放广告数据消息
    // const handleGetDevicePlayCampaign = (deviceId) => {
    //     const message = {
    //         client_type: 1,
    //         message_type: 'showDevicePlayCampaign',
    //         device_id: deviceId,
    //     };
    //     sendData(message);
    // };

    return (
        <div className="m-10 p-2 md:m-10 md:p-10 bg-white rounded-3xl">
            <Header title="Admin" />
            {userList.length > 0 ? (
                <div className="grid sm:grid-cols-6 gap-2 divide-x divide-solid xs:grid-cols-1">
                    <div className="p-2 w-full md:overflow-auto sm:col-span-2 grid-rows-2 h-[530px]">
                        <div className="mb-3">
                            <Card sx={{ height: 240, paddingBottom: '25px' }}>
                                <h3 className="ml-4 font-bold my-2">Users</h3>
                                <List
                                    sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        // maxHeight: 220,
                                        height: '100%',
                                        padding: '10px',
                                        // paddingBottom: '30px',
                                    }}
                                    subheader={<li />}
                                >
                                    {userList.map((user, index) => (
                                        <ListItemButton
                                            selected={selectedIndex === index}
                                            key={`item-${user.user_id}-${user.username}`}
                                            sx={{ borderBottom: '1px solid #e0e0e0', paddingY: '0' }}
                                            value={user}
                                            onClick={() => handleSelectedUser(user, index)}
                                        >
                                            <ListItemText primary={user.username}></ListItemText>
                                        </ListItemButton>
                                    ))}
                                    {/* <ListItemButton onClick={handleLoadMore}>
                                    <ListItemText
                                        primary={<div className="flex justify-center">Load more</div>}
                                    />
                                </ListItemButton> */}
                                </List>
                            </Card>
                        </div>
                        <div>
                            <Card sx={{ height: 240 }}>
                                <div className="flex justify-between mx-3 my-2">
                                    <h3 className="font-bold mb-2">Device</h3>{' '}
                                    {selectedUser && (
                                        <h3 className="font-bold mb-2 ">[{selectedUser.username}]</h3>
                                    )}
                                </div>

                                <List
                                    sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 220,
                                        '& ul': { padding: 0 },
                                    }}
                                    subheader={<li />}
                                >
                                    {deviceList.map((device) => (
                                        <ListItemButton
                                            key={`item-${device.device_id}-${device.device_name}`}
                                            sx={{ borderBottom: '1px solid #e0e0e0', paddingY: '0' }}
                                            value={device}
                                            onClick={() => handleSelectedDevice(device)}
                                        >
                                            <ListItemText primary={device.device_name}></ListItemText>
                                        </ListItemButton>
                                    ))}
                                    {/* <ListItemButton onClick={handleLoadMore}>
                                    <ListItemText
                                        primary={<div className="flex justify-center">Load more</div>}
                                    />
                                </ListItemButton> */}
                                </List>
                            </Card>
                        </div>
                    </div>
                    {/* {logDetails && (
                        <div className="pl-4 pt-2 pr-2 md:pt-0 pb-6 sm:col-span-4">
                            <div className="mx-4 h-full pr-2 overflow-auto">
                                <h3 className=" font-bold mb-2">Device Information</h3>
                                <div className="flex flex-row justify-between ">
                                    <div>
                                        <p>Log ID: {logDetails.log_id}</p>{' '}
                                    </div>
                                    <div>
                                        <p>Operation ID: {logDetails.operation_id}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col ">
                                    <p>Behaviour: {logDetails.operation_behavior}</p>{' '}
                                    <p>Method Name: {logDetails.operation_method_name}</p>
                                    <p>Module: {logDetails.operation_module}</p>
                                    <p>Result: {logDetails.operation_result}</p>
                                    <p>User: {logDetails.operation_user_name}</p>
                                </div>
                                <p>Change Detail:</p>
                                <p className="break-words">{logDetails.change_detail}</p>
                                <p>Before Data:</p>
                                <p className="break-words">{logDetails.before_data}</p>
                                <p>After Data:</p>
                                <p className="break-words">{logDetails.after_data}</p>
                            </div>
                        </div>
                    )} */}
                </div>
            ) : (
                <Pulse />
            )}
        </div>
    );
};

export default Admin;
