import React from 'react';
import Arrow from '../../images/arrow.svg';
import BlogData from '../../data/blogs';

function Article({ article }) {
    return (
        <div className="shadow-md bg-white flex  md:flex-row gap-10 w-full p-8 rounded-xl items-center">
            <img src={article.image} alt={article.name} className="h-36 w-36" />
            <div >
                <h4 className='font-display text-slate-500'>{article.category}</h4>
                <p className='font-display font-bold text-2xl'>{article.name}</p>
            </div>
            <img src={Arrow} alt={'arrow'} className="w-8 h-8" />
        </div>
    );
}

function BlogList({ category }) {
    return (
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 pb-25 pt-12 z-50">
            {BlogData.map((article, index) => (
                category === 'All Post' || article.category === category ?
                <Article key={index} article={article} /> : null
            ))}
        </div>
    );
}

export default BlogList;
