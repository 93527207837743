import React from 'react';
import Image1 from '../images/blogSushiimage1.png';
import Image2 from '../images/blogSushiimage2.png';
import Image3 from '../images/blogSushiimage3.png';

const Sushi = () => {
  return (
    <div className="container mx-auto max-w-6xl bg-white p-20 my-20">
      <h1 className="text-4xl text-display text-center px-auto mb-2 font-bold">In-store Digital Promotion Display </h1>

      <p className="text-base text-gray-400 mb-12 text-display text-center">9/8/2022</p>
      <img
        src={Image1}
        alt="Description"
        className="w-full h-auto my-4"
      />
      <p className="text-base text-gray-700 mb-4">
        This case study explores why and how Sushi Hub uses Cybercast Digital Signage throughout their network of shops for its centralised digital display of promotional offers to communicate, engage and interact with their valued customers.
      </p>
      <section className="mb-10">
        <h2 className="text-3xl text-display mb-8 font-semibold">About
          the Client</h2>
        <p className="text-base text-gray-700 mb-4">
          Sushi Hub opened its first store on 16 October 2006 in an alleyway in Cabramatta, NSW. It now has more than 120 stores operating Australia.        </p>
        <p className="text-base text-gray-700 mb-4">
          Sushi Hub constantly works to deliver the freshest, top-quality sushi to customers. Sushi Hub products are sourced locally and delivered daily to ensure the best quality.
        </p>
        <p className="text-base text-gray-700 mb-4">
          Sushi is the heart and soul of Sushi Hub. Making and serving sushi is a labour of love between the chefs and customers. Sushi Hub’s love of classical sushi has resulted in the creation of
          a sublime menu that is full of flavours.
        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Need</h2>
        <p className="text-base text-gray-700 mb-4">
          A major priority for Sushi Hub was to save cost by replacing their printed posters they send out to stores. Posters were slow to deploy and extremely expensive, limiting the number of campaigns they could afford to run with messages getting outdated quickly. Without a centralised control over the marketing collateral and deployment, HQ could not control if correct content was being displayed; or any content at all. They wanted to ensure accuracy throughout their promotions.        </p>
        <p className="text-base text-gray-700 mb-4">
          Another major priority was to offer a more attractive and inviting retail environment for customers, with seasonal promotions advertised on screens to open new avenues for additional revenue.They needed a centralise system they can control remotely.
        </p>
        <img
        src={Image2}
        alt="Description"
        className="w-full h-auto my-4"
      />
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Solution</h2>
        <p className="text-base text-gray-700 mb-4">
          After evaluating several solutions, they selected Cybercast for a pilot test, to see if it will fulfill their needs. Cybercast exceeded expectations.
        </p>
        <p className="text-base text-gray-700 mb-4">
          After a successful pilot period, screens were installed in all shops across Australia. The digital screens were sleek and clean with vivid, vibrant picture quality. Cybercast was able to offer a wide range of screens to meet a variety of customer engagement objectives and to retro fit some unique store layouts. The screens deployed included 16” slim line displays for the shop counter, 88” long displays for compelling content, capacitive touch displays for customer interaction and robust commercial 4K displays. Robust enough to run all day, 7 days a week.
          Additionally, all screens arrive at shops pre-configured and paired. Relevant content is published in an instant with Cybercast ‘Plug & Play’ Solution.
        </p>
        <p className="text-base text-gray-700 mb-4">
          Currently, Sushi Hub uses Cybercast to display videos and images for seasonal promotions. Content is general promotional, cross-selling and/or up-selling, while also keeping customers entertained. The ultimate goal is of course, to boost sales.
        </p>
        <img
        src={Image3}
        alt="Description"
        className="w-full h-auto my-4"
      />
      </section>
      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">How it Works</h2>
        <p className="text-base text-gray-700 mb-4">
          The content on the screens helps stimulate impulse purchases; and provides them with entertainment while they wait to order. That’s the value of digital displays.        </p>
        <p className="text-base text-gray-700 mb-4">
          All Screens are synced centrally. Sushi Hub can manage all their screens from HQ; and push content when and where they want to show it. Where necessary, they can also allow local branches to display their own content for ad-hoc promotions, events, etc.
        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Results & Benefits</h2>
        <p className="text-base text-gray-700 mb-4">
          With screens for all their outlets synced centrally and the ability to push content easily where and whenever required, Sushi Hub are now able to maximise their opportunity to generate additional revenue with digital promotion displays. The efficient and effective utilisation of digital signage has enabled Sushi Hub to reduce operational costs significantly. By providing their customers a more modern retail environment, Sushi Hub has also raised their profile as an industry leader being an innovative digital signage adopter.
        </p>
        <p className="text-base text-gray-700 mb-4">
          Sushi Hub’s core business is selling Sushi. Cybercast’s end-to-end solution with exceptional level of support takes away the hassle of maintenance and service of devices, so the marketing, operational and commercial teams at Sushi Hub can focus on more important matters to the company’s business growth.                </p>
        <p className="text-base text-gray-700 mb-4">
          he results and benefits are clear. Cybercast digital signage helps Sushi Hub reduce operational costs and communicate with their customers with the right content, at the right time, in the right place and in a very simple way. Since the deployment of Cybercast digital signage, Sushi Hub has experienced high percentage of sales revenue growth.                </p>
      </section>

    </div>
  );
};

export default Sushi;
