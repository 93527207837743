import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button, Container, Typography } from "@mui/material";
import { useState } from "react";
import { Resize } from "./Api";
import ResolutionResize from "./ResolutionResize";
import ImageSegmentation from "./ImageSegmentation";

export default function ResizeImage({ user_id }) {
  console.log("aaa", user_id);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label="Change Resolution & Size"></Tab>

        <Tab label="Image Segmentation" />
      </Tabs>
      {value === 0 && <ResolutionResize user_id={user_id} />}
      {value === 1 && <ImageSegmentation user_id={user_id} />}
    </div>
  );
}
