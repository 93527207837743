import { AlertTitle, Snackbar, Stack } from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import React from 'react';
import { useNotification } from '../../../contexts/ContextProvider';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({ data, alertType }) => {
    const {
        state: { alert },
        dispatch,
    } = useNotification();

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        // 	return;
        // }
        dispatch({ type: 'UPDATE_ALERT', payload: { ...alert, open: false } });
    };

    return (
        <>
            {alert.open && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Snackbar open={alert.open} autoHideDuration={5000} onClose={handleClose}>
                        <Alert
                            onClose={handleClose}
                            severity={alert.severity}
                            sx={{ width: '100%' }}
                        >
                            {alert.message}
                        </Alert>
                    </Snackbar>
                </Stack>
            )}
        </>
    );
};
export default Notification;
