import { SplitImage } from "./Api";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button, Container, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import { Resize } from "./Api";

import InputAdornment from "@mui/material/InputAdornment";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

import AWS from "aws-sdk";
import LinearProgress from "@mui/material/LinearProgress";

import { CreateImages } from "./Api";

//const user_id = "1";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function CreateImage({ user_id }) {
  const [inputValue, setInputValue] = useState(null);

  const [processing, setprocessing] = useState(null);

  const [repsonseURL, setrepsonseURL] = useState(null);

  const [warning, setwarning] = useState(null);

  const handleButtonClick = async () => {
    if (inputValue) {
      setprocessing(true);

      console.log(user_id);

      const body = { user_id: user_id, prompt: inputValue };

      console.log(body);

      const data = await CreateImages(body);

      console.log("Input Value:", data["message"]);
      setrepsonseURL(data["message"]);

      setprocessing(null);
    } else {
      setwarning(true);
    }
  };

  const handleDownload = () => {
    if (repsonseURL) {
      const link = document.createElement("a");
      link.href = repsonseURL;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div>
      <Typography>
        <TextField
          required
          id="multiline-input"
          label="Say Something"
          multiline
          rows={4}
          variant="filled"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setwarning(false);
          }}
        />
        {warning == true && (
          <div>
            <Alert severity="error">Invalid Input </Alert>
          </div>
        )}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        Generate
      </Button>

      {processing ? (
        <Typography>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </Typography>
      ) : (
        <Typography>
          {repsonseURL && (
            <>
              <img
                src={repsonseURL}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "300px",
                  marginTop: "10px",
                }}
              />
              <Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDownload}
                >
                  Download Image
                </Button>
              </Typography>
            </>
          )}
        </Typography>
      )}
    </div>
  );
}
