import { BottomSignUp, GetStarted, HardwareList, News } from '../components/website';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import GetStartedBg from '../images/GetStarted.png';
import { useStateContext } from '../contexts/ContextProvider';

const Hardware = () => {
    return (
        <div className="flex flex-col items-center w-full bg-custom-bg">
            {/* First Column - Typography */}
            <div className="max-w-6xl mx-auto justify-around space-y-8 text-center items-center py-25 px-2">
                <div className="max-w-5xl mx-auto">
                    <h6 className="font-display font-bold text-4xl md:text-5xl">
                        Find the right Hardware for you{' '}
                    </h6>
                    <p className="mt-4 mb-4">
                        Talk to us to create your unique digital experience today.
                    </p>
                </div>
            </div>
            <HardwareList />

            <div className="w-full pb-12">
                <div className="max-w-5xl mx-auto ">
                    <div
                        className="flex justify-between items-center p-12 mr-auto rounded-xl"
                        style={{ backgroundColor: '#FcFcFc' }}
                    >
                        <div className="ml-0" style={{ width: '60%' }}>
                            <span className="font-display font-bold text-2xl ">
                                Shop all your Signage Hardware
                            </span>
                            <p className="mt-3 ">
                                Cybercast provides high quality hardware, installation and support
                                services to businesses across Australia every day.
                            </p>
                        </div>
                        <Button
                            className="custom-btn"
                            variant="contained"
                            color="primary"
                            style={{
                                whiteSpace: 'nowrap',
                                color: '#ffffff',
                                fontSize: '16px',
                                fontWeight: 500,
                                padding: '20px 32px',
                                marginLeft: 'auto',
                            }}
                            href="https://soniq.com/au/"
                        >
                            Shop Now
                        </Button>
                    </div>
                </div>
            </div>
            {/* <div className="w-full pt-16 pb-24">
                <News />
            </div> */}
            {/*  Get started*/}
            <div
                className="w-full pt-20 pb-44 relative bg-cover bg-center h-screen flex justify-center"
                style={{ backgroundImage: `url(${GetStartedBg})`, height: '511px' }}
            >
                <GetStarted />
            </div>
            {/*  Get started*/}
            {/* <div className="w-full pt-10 pb-10 ">
                <BottomSignUp />
            </div> */}
        </div>
    );
};
export default Hardware;
