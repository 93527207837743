import * as React from 'react';

import { Button, Container, Typography } from '@mui/material';

import AWS from 'aws-sdk';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import { Resize } from './Api';
import Slider from '@mui/material/Slider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import config from '../../../config/config';
import { useState } from 'react';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const marks = [
    {
        value: 100,
        label: '100',
    },
    {
        value: 512,
        label: '512',
    },
    {
        value: 1024,
        label: '1024',
    },
    {
        value: 1600,
        label: '1600',
    },
];

export default function ResolutionResize({ user_id }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [resizedImage, setResizedImage] = useState(null);
    const [url, seturl] = useState(null);

    const [imageResolution, setImageResolution] = useState({
        width: null,
        height: null,
    });
    const [imagewidth, setimagewidth] = useState(null);
    const [imageheight, setimageheight] = useState(null);
    const [new_width, setnew_width] = useState(null);
    const [new_height, setnew_height] = useState(null);

    const [target_size, settarget_size] = useState(null);

    const [alertPNG, setalertPNG] = useState(false);

    const [loading, setloading] = useState(false);
    const [processing, setprocessing] = useState(null);

    const [S3URL, setS3URL] = useState(null);
    const [progress, setProgress] = React.useState(0);

    const handlewidth = (value) => {
        setnew_width(value);
        return `${value}`;
    };
    const handleheight = (value) => {
        setnew_height(value);
        return `${value}`;
    };

    const [imageSize, setImageSize] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setalertPNG(false);
        // Display image preview
        const reader = new FileReader();
        reader.onloadend = () => {
            //setImagePreview(reader.result);
        };
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= 20000000) {
            reader.readAsDataURL(file);

            // Get image resolution
            const img = new Image();
            img.onload = () => {
                setImageResolution({ width: img.width, height: img.height });
                setimagewidth(img.width);
                setimageheight(img.height);
                setImageSize(file.size);

                console.log({ width: img.width, height: img.height });

                const upload = uploadS3(file);
            };
            img.src = URL.createObjectURL(file);
        } else {
            // Handle the case when an invalid file type is selected (not a PNG file)
            reader.readAsDataURL(file);

            // Get image resolution
            const img = new Image();
            img.onload = () => {
                setImageResolution({ width: img.width, height: img.height });
                setimagewidth(img.width);
                setimageheight(img.height);
                setImageSize(file.size);
                console.log({ width: img.width, height: img.height });
            };
            img.src = URL.createObjectURL(file);

            setalertPNG(true);
            // Optionally, you can display a message to the user or take other actions.
        }
    };

    const uploadS3 = async (selectedFile) => {
        const S3_BUCKET = config[process.env.REACT_APP_ENV].REACT_APP_S3_BUCKET;

        const REGION = config[process.env.REACT_APP_ENV].REACT_APP_REGION;

        const filteredFilename = selectedFile.name.replace(/[^a-zA-Z0-9.]/g, '');

        AWS.config.update({
            accessKeyId: config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId,
            secretAccessKey: config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey,
        });

        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            //ACL: "public-read",
            Body: selectedFile,
            Bucket: S3_BUCKET,
            Key: user_id + '/' + filteredFilename,
        };
        console.log(params);

        //const data = myBucket.putObject(params);
        //console.log(data);
        setloading(true);

        var upload = myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt) => {
                console.log('Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%');
                setProgress(parseInt((evt.loaded * 100) / evt.total));
            })
            .promise();

        const response = await upload.then((err, data) => {
            console.log(err);
            console.log(upload);

            setloading(false);
            setProgress(0);
            setImagePreview(true);
        });

        setS3URL(
            'https://' + S3_BUCKET + '.s3.' + REGION + '.amazonaws.com/' + user_id + '/' + filteredFilename,
        );
        console.log(
            'https://' + S3_BUCKET + '.s3.' + REGION + '.amazonaws.com/' + user_id + '/' + filteredFilename,
        );
        //return data;
    };

    const handleUpload = async () => {
        // Implement your upload logic here
        // For example, you can use the FormData API to send the file to a server
        if (selectedFile) {
            const body = {
                user_id: user_id,
                image_url: S3URL,
                new_height: new_height,
                new_width: new_width,
                target_size_bytes: target_size * 1024 * 1024,
            };

            try {
                setprocessing(true);
                const data = await Resize(body);
                setprocessing(false);
                console.log('Resized Image Data:', data);
                setResizedImage(data.message);
                seturl(data.message);
                // Handle the resized image data as needed
            } catch (error) {
                console.error('Error while resizing:', error);
                // Handle the error
            }
        }
    };

    const handleDownload = () => {
        if (resizedImage) {
            const link = document.createElement('a');
            link.href = url;
            link.download = 'resized_image.jpg';
            link.click();
        }
    };

    const handleRefresh = () => {
        setImageResolution({
            width: null,
            height: null,
        });
        setSelectedFile(null);
        setImagePreview(null);
        setalertPNG(false);
        setResizedImage(null);
        //console.log(row);
    };

    return (
        <div>
            <Container>
                <Typography variant="h5">Please Select your file</Typography>
                {selectedFile == null && (
                    <>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-button"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="upload-button">
                            <Button variant="contained" component="span">
                                Choose File
                            </Button>
                        </label>
                    </>
                )}
                {selectedFile !== null && (
                    <>
                        <Button variant="contained" color="error" onClick={handleRefresh}>
                            Clear
                        </Button>
                    </>
                )}

                <Typography variant="body1" gutterBottom>
                    {selectedFile && `Selected File: ${selectedFile.name}`}
                </Typography>
                {alertPNG == true && (
                    <div>
                        <Alert severity="error">
                            Please select a PNG/JPG file and size is less than 20MB{' '}
                        </Alert>
                    </div>
                )}
                {loading == true && (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={progress} />
                    </Box>
                )}
                {imagePreview && (
                    <>
                        <img
                            src={S3URL}
                            alt="Preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '300px',
                                marginTop: '10px',
                            }}
                        />
                        {imageResolution.width !== null && imageResolution.height !== null && (
                            <div>
                                <Typography variant="body1" gutterBottom>
                                    Image Resolution: {imageResolution.width} x {imageResolution.height}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Image Size: {(imageSize / 1024 / 1024).toFixed(2)} MB
                                </Typography>

                                <Slider
                                    aria-label="Always visible"
                                    defaultValue={imagewidth}
                                    getAriaValueText={handlewidth}
                                    step={10}
                                    marks={marks}
                                    max={imagewidth}
                                    valueLabelDisplay="on"
                                />
                                <Slider
                                    aria-label="Always visible"
                                    defaultValue={imageheight}
                                    getAriaValueText={handleheight}
                                    step={10}
                                    marks={marks}
                                    max={imageheight}
                                    valueLabelDisplay="on"
                                />
                                <TextField
                                    required
                                    id="outlined-basic"
                                    label="Target Size"
                                    type="number" // Allow only numbers
                                    defaultValue={(imageSize / 1024 / 1024).toFixed(2)}
                                    value={target_size}
                                    onChange={(e) => {
                                        // Use a regular expression to filter out non-numeric characters
                                        const numericValue = e.target.value.replace(/[^0-9]/g, '');

                                        if (e.target.value > (imageSize / 1024 / 1024).toFixed(2)) {
                                            settarget_size((imageSize / 1024 / 1024).toFixed(2));
                                            console.log('sss', e.target.value);
                                        } else {
                                            settarget_size(e.target.value);
                                            console.log('sss', e.target.value);
                                        }
                                    }}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                                        max: 5, // Maximum value allowed
                                    }}
                                />
                            </div>
                        )}
                        <Button variant="contained" color="primary" onClick={handleUpload}>
                            Edit
                        </Button>
                    </>
                )}

                {processing ? (
                    <Typography>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    </Typography>
                ) : (
                    <Typography>
                        {resizedImage && (
                            <>
                                <Typography variant="h6">Resized Image Preview</Typography>
                                <img
                                    src={url}
                                    alt="Resized Preview"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '300px',
                                        marginTop: '10px',
                                    }}
                                />
                                <Typography variant="body1" gutterBottom>
                                    Image Resolution: {new_width} x {new_height}
                                </Typography>

                                <div>
                                    <Button variant="contained" color="secondary" onClick={handleDownload}>
                                        Download
                                    </Button>
                                </div>
                            </>
                        )}
                    </Typography>
                )}
            </Container>
        </div>
    );
}
