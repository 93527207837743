import React from 'react';
import news1 from '../../images/news1.png';
import news2 from '../../images/news2.png';
import news3 from '../../images/news3.png';
import news4 from '../../images/news4.png';
import news5 from '../../images/news5.png';
import news6 from '../../images/news6.png';
import news7 from '../../images/news7.png';
import news8 from '../../images/news8.png';

function Article({ article }) {
    return (
        <div className="mt-4 flex items-center">
            <img src={article.image} alt={article.name} className="h-14 w-20" />
            {/* object-cover */}
            <div className="ml-8">
                <h4 className="font-display text-slate-500 text-xs">{article.subCategory}</h4>
                <p>{article.name}</p>
            </div>
        </div>
    );
}

function ArticleColumn({ category, articles }) {
    return (
        <div className="shadow-lg bg-white flex flex-col w-full mx-auto p-12 rounded-2xl">
            <div className="flex justify-between items-center">
                <h3 className="font-display text-2xl font-bold">{category}</h3>
                <button
                    className="font-display font-bold text-customPrimary"
                    href="./resources/all-blogs"
                >
                    Read More →
                </button>
            </div>
            {articles.map((article) => (
                <Article key={article.id} article={article} />
            ))}
        </div>
    );
}

function News() {
    const columnData = [
        {
            category: 'From The Blog',
            articles: [
                {
                    id: 1,
                    subCategory: 'RESOURCES',
                    name: 'Meet the Superior Alternative to FlexSlider in WordPress',
                    image: news8,
                },
                {
                    id: 2,
                    subCategory: 'RESOURCES',
                    name: 'Impressive Automatic Slideshow Examples',
                    image: news1,
                },
                {
                    id: 3,
                    subCategory: 'RESOURCES',
                    name: 'Beautiful Food Website Templates for Food Enthusiasts',
                    image: news3,
                },
                {
                    id: 4,
                    subCategory: 'RESOURCES',
                    name: 'Beautiful Food Website Templates for Food Enthusiasts',
                    image: news2,
                },
                // Add more articles as needed
            ],
        },
        {
            category: 'Popular Resources',
            articles: [
                {
                    id: 5,
                    subCategory: 'FAQ',
                    name: 'Optimizing Load Speed and Performance',
                    image: news4,
                },
                {
                    id: 6,
                    subCategory: 'FAQ',
                    name: 'Quick Setup – Slider Revolution',
                    image: news5,
                },
                {
                    id: 7,
                    subCategory: 'VIDEO TUTORIAL',
                    name: 'Create a Basic Responsive Slider',
                    image: news6,
                },
                { id: 8, subCategory: 'MANUAL', name: 'Get Productive Fast', image: news7 },
                // Add more articles as needed
            ],
        },
    ];

    return (
        <div className="max-w-6xl mx-auto">
            <div className="mx-auto text-center space-y-5 mb-8">
                <h6 className="font-display font-bold text-2xl text-customPrimary">RESOURCES</h6>
                <h6 className="font-display font-bold text-4xl  md:text-5xl">
                    Read our Latest News{' '}
                </h6>
            </div>
            <div className="max-w-screen-xl mx-auto py-4">
                <div className="flex flex-col md:flex-row gap-10">
                    {columnData.map((column, index) => (
                        <ArticleColumn
                            key={index}
                            category={column.category}
                            articles={column.articles}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default News;
