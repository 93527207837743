import * as Yup from 'yup';

import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    MenuItem,
    NativeSelect,
    OutlinedInput,
    Radio,
    RadioGroup,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Field, Formik, useFormik } from 'formik';
import { GrChapterAdd, GrClose, GrDown, GrEdit, GrLock, GrTrash } from 'react-icons/gr';
import React, { useEffect, useRef, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
    RiDeleteBin6Fill,
    RiFolder3Fill,
    RiLockFill,
    RiLockUnlockFill,
    RiSettings2Fill,
} from 'react-icons/ri';

import { DenseInput } from '../utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GetTemplateElementTypeList } from '../../../api/api';
import { MdOutlineImportantDevices } from 'react-icons/md';
import { MdPadding } from 'react-icons/md';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../../../contexts/ContextProvider';

const style = {
    labelText: {
        minWidth: '200px',
        display: 'flex',
        // textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
    },
    label: {
        minWidth: '150px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
    button: {
        textTransform: 'none',
        minWidth: '100px',
    },
};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:before': {
        display: 'none',
    },

    // backgroundColor: '#12acd7',
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    marginTop: '10px',
    marginBottom: '10px',
    padding: '0px',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    // '& .MuiAccordionSummary-content': {
    //     marginLeft: theme.spacing(1),
    // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '0px',
}));
const EditElement = ({
    widgetArray,
    editElementDialog,
    handleCloseEdit,
    currentElement,
    targetLayout,
    editedElement,
    setEditedElement,
    handleModify,
    elementTagList,
}) => {
    console.log('file: EditElement.jsx:136 => currentElement:', currentElement);
    console.log('file: EditElement.jsx:136 => widgetArray:', widgetArray);
    //templateElement,
    const templateElement = JSON.parse(localStorage.getItem('element_type'));
    console.log('file: EditElement.jsx:141 => templateElement:', templateElement);
    const formik = useFormik({
        initialValues: {
            element_type: currentElement?.element_type ? currentElement?.element_type : 'image',
            position_x: currentElement?.element_x,
            position_y: currentElement?.element_y,
            width: currentElement?.element_width,
            height: currentElement?.element_height,
            layer: currentElement?.element_layer,
            tagName: currentElement?.element_tag ? currentElement?.element_tag : '',
            actions: currentElement?.element_tag_event_list
                ? typeof currentElement?.element_tag_event_list === 'string'
                    ? JSON.parse(currentElement?.element_tag_event_list).map((x) => ({
                          tagEffect: x.tag_effect,
                          controlTags: x.control_tags.join(','),
                          tagUrl: x.jump_url,
                      }))
                    : currentElement?.element_tag_event_list.map((x) => ({
                          tagEffect: x.tag_effect,
                          controlTags:
                              typeof x.control_tags === 'string'
                                  ? JSON.parse(x.control_tags).join(',')
                                  : x.control_tags.join(','),
                          tagUrl: x.jump_url,
                      }))
                : [
                      {
                          tagEffect: '',
                          tagUrl: '',
                          controlTags: '',
                      },
                  ],
        },
        enableReinitialize: true,
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            const betweenX = (position) => {
                if (position < 0) return 0;
                if (targetLayout === 0) {
                    if (position > 1920 - currentElement?.element_width) {
                        return 1920 - currentElement?.element_width;
                    } else {
                        return position;
                    }
                }
                if (targetLayout === 1) {
                    if (position > 1080 - currentElement?.element_width) {
                        return 1080 - currentElement?.element_width;
                    } else {
                        return position;
                    }
                }
            };
            const betweenY = (position) => {
                if (position < 0) return 0;
                if (targetLayout === 0) {
                    if (position > 1080 - currentElement?.element_height) {
                        return 1080 - currentElement?.element_height;
                    } else {
                        return position;
                    }
                }
                if (targetLayout === 1) {
                    if (position > 1920 - currentElement?.element_height) {
                        return 1920 - currentElement?.element_height;
                    } else {
                        return position;
                    }
                }
            };
            const {
                // element_alias,
                element_type,
                position_x,
                position_y,
                width,
                height,
                layer,
                actions,
                tagName,
            } = values;
            const data = {
                element_alias: currentElement.element_alias,
                element_type: element_type,
                element_x: betweenX(parseInt(position_x)),
                element_y: betweenY(parseInt(position_y)),
                element_width: maxWidth(parseInt(width)),
                element_height: maxHeight(parseInt(height)),
                element_layer: parseInt(layer),
                element_tag: tagName,
                element_tag_event_list: tagName
                    ? JSON.stringify(
                          actions.map((x) => ({
                              tag_effect: x.tagEffect,
                              control_tags: x.controlTags.split(','),
                              jump_url: x.tagUrl,
                          })),
                      )
                    : null,
            };
            console.log('data:', data);
            setEditedElement(data);
            // handleModify(undefined, 'edit');
            handleCloseEdit();
        },
    });
    const maxWidth = (width) => {
        if (width < 0) return 0;
        if (targetLayout === 0) {
            if (width > 1920) {
                return 1920;
            } else {
                return width;
            }
        } else {
            if (width > 1080) {
                return 1080;
            } else {
                return width;
            }
        }
    };
    const maxHeight = (height) => {
        if (height < 0) return 0;
        if (targetLayout !== 0) {
            if (height > 1920) {
                return 1920;
            } else {
                return height;
            }
        } else {
            if (height > 1080) {
                return 1080;
            } else {
                return height;
            }
        }
    };
    // Action field
    const handleAddAction = () => {
        formik.setFieldValue('actions', [
            ...formik.values.actions,
            { tagName: '', controlTags: '', tagEffect: '', tagUrl: '' },
        ]);
    };
    const handleRemoveAction = (indexToRemove) => {
        if (formik.values.actions.length === 1) {
            // If only one action is present, don't remove it
            formik.setFieldValue('actions', [
                { tagName: '', controlTags: '', tagEffect: '', tagUrl: '' },
            ]);
            return;
        }

        const updatedActions = formik.values.actions.filter((_, index) => index !== indexToRemove);
        formik.setFieldValue('actions', updatedActions);
    };
    // formik.values.element_type;
    console.log('file: EditElement.jsx:232 => formik.values.tagEffect:', formik.values.tagEffect);
    return (
        <Dialog
            open={editElementDialog}
            onClose={handleCloseEdit}
            fullWidth
            maxWidth="sm"
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">Edit element</DialogTitle>
            <DialogContent>
                {templateElement && (
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                            <FormLabel id="element_type" sx={style.labelText}>
                                Select element type
                            </FormLabel>
                            <DenseInput
                                error={Boolean(
                                    formik.touched.element_type && formik.errors.element_type,
                                )}
                                fullWidth
                                select
                                helperText={
                                    formik.touched.element_type && formik.errors.element_type
                                }
                                margin="normal"
                                aria-labelledby="element_type"
                                name="element_type"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.element_type}
                                variant="outlined"
                            >
                                {templateElement?.map((x) => (
                                    <MenuItem value={x.element_type} key={x + x.element_type}>
                                        {x.element_type === 'mix'
                                            ? 'Image & video'
                                            : x.element_type_name}
                                    </MenuItem>
                                ))}
                            </DenseInput>
                        </FormControl>
                        <FormControl
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                            <FormLabel id="layer" sx={style.labelText}>
                                Layer
                            </FormLabel>
                            <DenseInput
                                error={Boolean(formik.touched.layer && formik.errors.layer)}
                                fullWidth
                                type="number"
                                helperText={formik.touched.layer && formik.errors.layer}
                                margin="normal"
                                aria-labelledby="layer"
                                name="layer"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.layer}
                                variant="outlined"
                            />
                        </FormControl>
                        {(formik.values.element_type === 'image' ||
                            formik.values.element_type === 'text') && (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Action settings</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="h-[300px] overflow-y-scroll mb-2 px-2">
                                        <div className="px-4 py-2 rounded">
                                            <FormControl
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FormLabel id="tag" sx={style.labelText}>
                                                    Action tag name
                                                </FormLabel>
                                                <DenseInput
                                                    error={Boolean(
                                                        formik.touched.tagName &&
                                                            formik.errors.tagName,
                                                    )}
                                                    fullWidth
                                                    type="text"
                                                    helperText={
                                                        formik.touched.tagName &&
                                                        formik.errors.tagName
                                                    }
                                                    margin="normal"
                                                    aria-labelledby="tagName"
                                                    name="tagName"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.tagName}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </div>
                                        {formik.values.actions.map((action, index) => (
                                            <div
                                                key={`actions-${index}`}
                                                className="bg-gray-200 px-4 py-2 rounded mb-2"
                                            >
                                                <div className="flex justify-between">
                                                    <Typography>Action {index + 1}</Typography>
                                                    {/* {index > 0 && ( */}
                                                    <Tooltip>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveAction(index)
                                                            }
                                                        >
                                                            <GrClose size={14} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {/* )} */}
                                                </div>
                                                <FormControl
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <FormLabel
                                                        id={`controlTag-${index}`}
                                                        sx={style.labelText}
                                                    >
                                                        Control tag(s)
                                                    </FormLabel>
                                                    <DenseInput
                                                        error={Boolean(
                                                            formik.touched.actions?.[index]
                                                                ?.controlTags &&
                                                                formik.errors.actions?.[index]
                                                                    ?.controlTags,
                                                        )}
                                                        fullWidth
                                                        type="text"
                                                        helperText={
                                                            formik.touched.actions?.[index]
                                                                ?.controlTags &&
                                                            formik.errors.actions?.[index]
                                                                ?.controlTags
                                                        }
                                                        margin="normal"
                                                        aria-labelledby={`controlTags-${index}`}
                                                        name={`actions[${index}].controlTags`}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={
                                                            formik.values.actions?.[index]
                                                                ?.controlTags
                                                        }
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {/* tagEffect */}
                                                    <FormLabel
                                                        id={`tagEffect-${index}`}
                                                        sx={style.labelText}
                                                    >
                                                        Action effect
                                                    </FormLabel>
                                                    <DenseInput
                                                        select
                                                        error={Boolean(
                                                            formik.touched.actions?.[index]
                                                                ?.tagEffect &&
                                                                formik.errors.actions?.[index]
                                                                    ?.tagEffect,
                                                        )}
                                                        fullWidth
                                                        type="text"
                                                        helperText={
                                                            formik.touched.actions?.[index]
                                                                ?.tagEffect &&
                                                            formik.errors.actions?.[index]
                                                                ?.tagEffect
                                                        }
                                                        margin="normal"
                                                        aria-labelledby={`tagEffect-${index}`}
                                                        name={`actions[${index}].tagEffect`}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        value={
                                                            formik.values.actions?.[index]
                                                                ?.tagEffect
                                                        }
                                                        variant="outlined"
                                                    >
                                                        {elementTagList.map((x) => (
                                                            <MenuItem
                                                                value={x.tag_effect}
                                                                key={x.tag_effect}
                                                            >
                                                                <em>{x.tag_effect_name}</em>
                                                            </MenuItem>
                                                        ))}
                                                    </DenseInput>
                                                </FormControl>
                                                {formik.values.actions?.[index]?.tagEffect ===
                                                    'url' && (
                                                    <FormControl
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <FormLabel
                                                            id={`tagUrl-${index}`}
                                                            sx={style.labelText}
                                                        >
                                                            Url
                                                        </FormLabel>
                                                        <DenseInput
                                                            error={Boolean(
                                                                formik.touched.actions?.[index]
                                                                    ?.tagUrl &&
                                                                    formik.errors.actions?.[index]
                                                                        ?.tagUrl,
                                                            )}
                                                            fullWidth
                                                            type="text"
                                                            helperText={
                                                                formik.touched.actions?.[index]
                                                                    ?.tagUrl &&
                                                                formik.errors.actions?.[index]
                                                                    ?.tagUrl
                                                            }
                                                            margin="normal"
                                                            aria-labelledby={`tagUrl-${index}`}
                                                            name={`actions[${index}].tagUrl`}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            value={
                                                                formik.values.actions?.[index]
                                                                    ?.tagUrl
                                                            }
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                )}
                                            </div>
                                        ))}
                                        {((formik.values.actions?.[0]?.controlTags &&
                                            formik.values.actions?.[0]?.tagEffect) ||
                                            formik.values.actions?.length > 1) && (
                                            <div className="w-full flex justify-center ">
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => handleAddAction()}
                                                >
                                                    <span className="text-lg">
                                                        Add a new action
                                                    </span>
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {/* <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='position_x' sx={style.labelText}>
								Position X
							</FormLabel>
							<DenseInput
								error={Boolean(formik.touched.position_x && formik.errors.position_x)}
								fullWidth
								type='number'
								helperText={formik.touched.position_x && formik.errors.position_x}
								margin='normal'
								aria-labelledby='position_x'
								name='position_x'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.position_x}
								variant='outlined'
							/>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='position_y' sx={style.labelText}>
								Position Y
							</FormLabel>
							<DenseInput
								error={Boolean(formik.touched.position_y && formik.errors.position_y)}
								fullWidth
								type='number'
								helperText={formik.touched.position_y && formik.errors.position_y}
								margin='normal'
								aria-labelledby='position_y'
								name='position_y'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.position_y}
								variant='outlined'
							/>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='width' sx={style.labelText}>
								Width
							</FormLabel>
							<DenseInput
								error={Boolean(formik.touched.width && formik.errors.width)}
								fullWidth
								type='number'
								helperText={formik.touched.width && formik.errors.width}
								margin='normal'
								aria-labelledby='width'
								name='width'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={maxWidth(parseInt(formik.values.width))}
								variant='outlined'
							/>
						</FormControl>
						<FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormLabel id='height' sx={style.labelText}>
								Height
							</FormLabel>
							<DenseInput
								error={Boolean(formik.touched.height && formik.errors.height)}
								fullWidth
								type='number'
								helperText={formik.touched.height && formik.errors.height}
								margin='normal'
								aria-labelledby='height'
								name='height'
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={maxHeight(parseInt(formik.values.height))}
								variant='outlined'
							/>
						</FormControl> */}

                        <DialogActions>
                            <Button sx={style.button} onClick={handleCloseEdit} variant="outlined">
                                Cancel
                            </Button>
                            <Button
                                sx={style.button}
                                // onClick={handleCloseEditDialog}
                                autoFocus
                                variant="contained"
                                type="submit"
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EditElement;
