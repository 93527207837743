/**
 *
 * Reducer of handling media
 *
 */
const media = [];

const handleMedia = (state = media, action) => {
    const mediaList = action.payload;
    switch (action.type) {
        case 'ADD_MEDIA':
            console.log('Add ================================================');

            //check if Product is already exist?
            console.log('mediaList:', mediaList);
            console.log('state:', state);
            const exist = state.find((x) => x.element_id === (Array.isArray(mediaList) ? mediaList[0]?.element_id : mediaList?.element_id));
            console.log('exist:', exist);

            if (exist) {
                return state.map((x) => (x.element_id === (Array.isArray(mediaList) ? mediaList[0]?.element_id : mediaList?.element_id) ? { ...mediaList } : x));
            } else {
                console.log('not exist ================================================\n ');
                console.log('=> state ', [...state]);
                console.log('=> mediaList', [mediaList]);
                return Array.isArray(mediaList) ? [...state, ...mediaList] : [...state, mediaList];
            }
        // return [...state, ...mediaList];
        case 'RESET_MEDIA':
            console.log('Reset================================================');

            return media;

        case 'REMOVE_WEB':
            return state.map((item) => {
                if (item.element_id === mediaList.element_id) {
                    // Remove the resource_website content for the matching element_id
                    item.resource_list.forEach((resource) => {
                        resource.resource_website = '';
                    });
                }
                console.log('file: handleMedia.jsx:37 => returnstate.map => item:', item);
                return item;
            });

        case 'UPDATE_WEB':
            return state.map((item) =>
                item.element_id === mediaList.element_id
                    ? {
                          ...item,
                          resource_list: item.resource_list.map((resource) => ({
                              ...resource,
                              resource_website: mediaList?.detail_list?.resource_content[0]?.resource_website || '',
                          })),
                      }
                    : item,
            );

        default:
            return state;
    }
};
export default handleMedia;
