import Button from '@mui/material/Button';
import React from 'react';

function GetStarted() {
    return (
        <div className="max-w-6xl mx-auto text-center items-center">
            <h1 className="font-display font-bold text-4xl  md:text-5xl mt-32">
                Get Started Today{' '}
            </h1>
            <p className="mx-auto mt-3 mb-4">
                Talk to us to create your unique digital experience today.
            </p>
            <Button
                variant="contained"
                href="./contact"
                style={{
                    color: '#ffffff',
                    fontSize: '16px',
                    fontWeight: 500,
                    padding: '20px 32px',
                }}
            >
                Get in Touch
            </Button>
        </div>
    );
}
export default GetStarted;
