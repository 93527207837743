import { Box, Button, FormControl, MenuItem, Select, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';

// import { Button } from 'react-bootstrap';
import { Createcheckout } from './Api';

const StripePay = ({
    user_id,
    username,
    selectedrows,
    numberOfsixMonthsItems,
    numberOfOneYearItems,
    isBusinessCustomer,
    price_id_list,
    customerType,
}) => {
    const [items, setitems] = useState({});

    const getitems = (isBusinessCustomer) => {
        if (isBusinessCustomer === true) {
            setitems({
                line_items: [
                    numberOfsixMonthsItems > 0
                        ? {
                              price: price_id_list['BS180'], // bussiness 6months
                              quantity: numberOfsixMonthsItems,
                          }
                        : null,
                    numberOfOneYearItems > 0
                        ? {
                              price: price_id_list['BS360'], //  bussiness 1 year
                              quantity: numberOfOneYearItems,
                          }
                        : null,
                ].filter(Boolean),
            });
        } else {
            setitems({
                line_items: [
                    numberOfsixMonthsItems > 0
                        ? {
                              price: price_id_list['GS180'], // general 6months
                              quantity: numberOfsixMonthsItems,
                          }
                        : null,
                    numberOfOneYearItems > 0
                        ? {
                              price: price_id_list['GS360'], // general 1 year
                              quantity: numberOfOneYearItems,
                          }
                        : null,
                ].filter(Boolean),
            });
        }
    };
    useEffect(() => {
        getitems(isBusinessCustomer);
    }, []);

    const clickcheckout = async (e) => {
        //const profile = {userId: "1", user_email:"vincent.liu@soniq.com"}

        const updatedArray = selectedrows.map(({ id, new_start, new_end, type }) => ({
            id,
            new_start,
            new_end,
            type,
        }));
        const updateDict = { device_summary: updatedArray };

        const profile = { userId: user_id };
        const customer_type = isBusinessCustomer
            ? { customer_type: 'cooperate' }
            : { customer_type: 'general' };
        const total_body = {
            ...items,
            ...updateDict,
            ...profile,
            ...(customerType !== 0 && { customer_type: customerType }),
            username: username,
        };

        console.log(total_body);
        console.log(price_id_list);

        const data = await Createcheckout(JSON.stringify(total_body));

        if (data['statusCode'] == 200) {
            window.location.href = data['message'][0];
        } else {
            console.log('data', data);
        }
    };

    return (
        <Button variant="contained" onClick={clickcheckout}>
            checkout stripe
        </Button>
    );
};

export default StripePay;
