import React from 'react';
import Image1 from '../images/blogEpimage1.png';
import Image2 from '../images/blogEpimage2.png';
import Image3 from '../images/blogEpimage3.png';
import Image4 from '../images/blogEpimage4.png';

const Epworth = () => {
  return (
    <div className="container mx-auto max-w-6xl bg-white p-20 my-20">
      <h1 className="text-4xl text-display text-center px-auto mb-2 font-bold">Digital Communications at</h1>
      <h1 className="text-4xl text-display text-center px-auto mb-3 font-bold">Epworth HealthCare</h1>
      <p className="text-base text-gray-400 mb-12 text-display text-center">9/8/2022</p>
      <img
        src={Image1}
        alt="Description"
        className="w-full h-auto my-4"
      />
      <p className="text-base text-gray-700 mb-4">
        This case study explores how Epworth uses Cybercast Digital Signage throughout their 13 healthcare facilities in Victoria to enhance communication, engagement and interactions with staff, residents, patients and visitors. The project commenced with Epworth Eastern in Box Hill followed by remaining facilities.        </p>

      <section className="mb-10">
        <h2 className="text-3xl text-display mb-8 font-semibold">About
          the Client
        </h2>

        <p className="text-base text-gray-700 mb-4">
          Epworth HealthCare is Victoria’s largest
          not-for-profit private hospital group, renowned for excellence in diagnosis, treatment, care and rehabilitation.
        </p>
        <p className="text-base text-gray-700 mb-4">
          Epworth is an innovator in Australia’s health system, embracing the latest in
          evidence-based medicine to pioneer treatments and services for our patients.
        </p>


      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Opportunity</h2>
        <p className="text-base text-gray-700 mb-4">
          With the heightened need to communicate during the 2020 pandemic, there was an immediacy to replace static posters and notice boards with a responsive and centralised communication tool. A great need arose to share important corporate, health & wellness and compliance messaging across a digital signage network at multiple locations while being able to easily manage, update and tailor content in real time, per specific screen. There were also internal needs to quickly and efficiently maintain and broadcast operating theatre timetables to relevant personnel, doctors and nurses. Digital residency directories were also on the agenda.        </p>
        <p className="text-base text-gray-700 mb-4">
          With multiple digital display endpoints at different locations, serving specific purposes, there were many components to the project. It was important for Epworth to find a technology partner that provides an effective end-to-end solution.</p>
        <img
          src={Image2}
          alt="Description"
          className="w-full h-auto my-4"
        />
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Solution</h2>
        <p className="text-base text-gray-700 mb-4">
          Cybercast provided a Comprehensive Solution, including digital displays, hardware mounts, Cybercast CMS, installation support, training, and bespoke applications.
        </p>


        <p className="text-base  font-semibold mb-2 px-8">
          •	Digital Directories
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          A bespoke application was its Digital Residency Directory. Residents shift and change. Cybercast digital signage ensures that the resident information including doctor profile, services provided and room location is always accurate and up to date.
        </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Operating Theatre Schedule
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Previously the operating theatre schedule was hand-written by an administrator on a traditional whiteboard. The administrator needed to attend to the whiteboard at its physical location and manually made changes. It was time consuming, and accuracy was not guaranteed.        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Working together with the Epworth team, Cybercast developed an expert bespoke application that enabled the theatre schedules to be uploaded and displayed on digital screens at different locations, remotely from the administrator’s computer. Live updates were just one click away. It is crucial that doctors and nurses were guided to their assigned theatres with accuracy in a timely manner.        </p>
        <img
          src={Image3}
          alt="Description"
          className="w-full h-auto my-4"
        />
      </section>

      <section className="mb-10">
        <h2 className="text-3xl mb-3 font-semibold">The Results & Benefits</h2>
        <p className="text-base text-gray-700 mb-4">
          With the deployment of Cybercast, Epworth has enhanced its branding as the innovator in the health industry pioneering in the process of digital transformation.        </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Communication Efficiency
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Cybercast is an effective and efficient communication platform that serves as a means of conveying critical and timely information across multiple locations with assurances of network health. Cybercast has helped Epworth communicate with their doctors, nurses, staff, residents, visitors and patients with the right content, at the right time, in the right place and in a very simple way. </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Stability and Reliability
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          With the solution supporting a network spanning across multiple locations, the new solution had full support from Cybercast including services, training and system monitoring to ensure network health. </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Operational Costs Reduction
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Cybercast digital signage solution has helped Epworth reduce operational costs significantly. Usage of prints and posters have been minimised, enabling Epworth to achieve a clean and professional appearance as well as reduce the carbon footprint.
        </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Intuitive Content Management System and Ease of Deployment
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          The content management and network administration of Cybercast are user-friendly, intuitive and effective. The deployment of campaigns to multiple screens of various sizes and configurations needing to play specific contents with relevance was made so easy to operate with that one does not need to be technically savvy to master Cybercast.                </p>
        <p className="text-base  font-semibold mb-2 px-8">
          •	Strong Technology Partnership
        </p>
        <p className="text-base text-gray-700  mb-4 px-8">
          Cybercast provided not only the full technology solution but also the deep knowledge and commitment to continue supporting Epworth on what they needed for success.</p>
      </section>

      <img
          src={Image4}
          alt="Description"
          className="w-full h-auto my-4"
        />
    </div>
  );
};

export default Epworth;
