import * as Yup from 'yup';

import {
	AddUser,
	ChangePassword,
	GetMenuList,
	GetPermissionGroupList,
	GetUserDetail,
	GetUserList,
	UpdateUser,
} from '../../../api/api';
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	List,
	ListItem,
	ListItemButton,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import { Header, Navbar } from '../components/dashboard';
import React, { useEffect, useState } from 'react';
// import { UpdateUserSec, UserList } from '../components/dashboard/user';
import { useNotification, useStateContext } from '../../../contexts/ContextProvider';

import { Pulse } from '../utils';
// import { links } from '../../data/dummy';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';

const UserList = ({ userList, handleGetUserDetails, currentUser }) => {
	// const [userList, setUserList] = useState([]);

	return (
		<div className='col-span-1 h-full p-4 shadow-lg rounded-3xl'>
			<div className='pl-3 font-semibold text-lg'>User</div>
			<List
				sx={{
					width: '100%',
					bgcolor: 'background.paper',
					overflow: 'auto',
					maxHeight: 700,
					'& ul': { padding: 0 },
				}}
				subheader={<li />}>
				<ul>
					{userList?.length > 0 ? (
						userList.map(
							(item) =>
								item.user_level !== currentUser.user_level && (
									<ListItemButton
										key={item.user_id}
										onClick={() => handleGetUserDetails(item.user_id)}>
										<ListItem>
											{item.username}
											{/* <ListItemText primary={item.username} /> */}
										</ListItem>
									</ListItemButton>
								)
						)
					) : (
						<Pulse />
					)}
				</ul>
			</List>
		</div>
	);
};

export default UserList;
