import {
    AboutUsCard,
    Advantage,
    AnimationWrapper,
    BottomSignUp,
    GetStarted,
    News,
    Portfolio,
    ResponsiveSnap,
    StatCard,
    Testimonial,
    TestimonialBlock,
    Title,
    WebNavbar,
    WebSidebar,
} from '../components/website';
import { Card, CssBaseline, MobileStepper, Typography } from '@mui/material';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import Client1 from '../images/client1.png';
import Client2 from '../images/client2.png';
import Client3 from '../images/client3.png';
import Client4 from '../images/client4.png';
import Client5 from '../images/client5.png';
import Client6 from '../images/client6.png';
import Client7 from '../images/client7.png';
import Client8 from '../images/client8.png';
import Client9 from '../images/client9.png';
import GetStartedBg from '../images/GetStarted.png';
import Overlay from '../images/overlay.png';
import Solutions from '../images/Solutions.png';
import WatchDemo from '../images/watchDemo.svg';
import WhiteFrog from '../images/whitefrog.svg';
import backgroundImage from '../images/backgroundImage.svg';
import homeVideo from '../images/home.mp4';
import imageList from '../images/imageList';
import { useStateContext } from '../contexts/ContextProvider';

const HomePage = () => {
    const {
        activeMenu,
        currentColor,
        // themeSettings,
        screenSize,
        // setThemeSettings,
        setShowSidebar,
        setScreenSize,
        showSidebar,
    } = useStateContext();

    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const duplicatedImagesList = [...imageList, ...imageList];

    return (
        <div className="flex flex-col items-center w-full bg-custom-bg">
            {/* First row */}
            <div className="w-full pt-28 bg-white">
                <div className="max-w-6xl mx-auto h-full flex flex-col md:flex-row items-center justify-between overflow-visible">
                    {/* First Column - Typography */}
                    <div className="justify-between w-full md:w-1/3 space-y-10 px-2 py-16 mb-auto z-5 ">
                        <div className="w-full ">
                            <Typography variant="h3">
                                <span className="font-body font-bold text-4xl md:text-5xl">
                                    Your{' '}
                                </span>
                                <span className="font-display font-bold text-4xl md:text-5xl text-customPrimary">
                                    All-in-one{' '}
                                </span>
                                <span className="font-display font-bold text-4xl md:text-5xl">
                                    Solution for Digital Signage
                                </span>
                            </Typography>
                        </div>
                        <div className="mt-10 mb-auto">
                            <Typography variant="body1">
                                Cybercast helps businesses across Australia and New Zealand drive
                                sales and increase engagement using our cloud-based digital signage
                                platform.{' '}
                            </Typography>
                        </div>
                        <div className="items-center mt-10 h-20 mb-auto flex space-x-6">
                            {/* <Button size="large" variant="contained" href="/" color='primary' style={{
                color: '#ffffff', fontSize: '16px', fontWeight: 500, whiteSpace: 'nowrap', padding: '20px 32px',
              }} >
                Get Started
              </Button> */}
                            <Button
                                className="flex items-center"
                                variant="outlined"
                                style={{
                                    borderColor: 'white',
                                    whiteSpace: 'nowrap',
                                    borderWidth: '2px',
                                }}
                            >
                                <img src={WatchDemo} alt="icon" className="w-4 h-4 m-3" />
                                <span className="font-display font-bold text-base mr-3 text-customPrimary">
                                    {' '}
                                    Watch Demo
                                </span>
                            </Button>
                        </div>
                    </div>

                    {/* Second Column - Video Background */}
                    <div className="relative mx-auto overflow-visible w-full  h-145">
                        <img
                            src={Overlay}
                            alt="overlay image"
                            className="absolute z-10"
                            style={{
                                width: '90%',
                                top: '86%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                        <video
                            autoPlay
                            loop
                            muted
                            className="absolute w-76 sm:w-106 h-full object-cover rounded-t-3xl"
                            style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                        >
                            <source src={homeVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    {/* Third Column - Additional text/content in two rows */}
                    <div className="hidden md:flex px-2 flex-col ml-auto">
                        <div className="py-5 justify-around space-y-1 " style={{ width: '120px' }}>
                            <h3 className="font-display font-bold text-4xl md:text-5xl">1K+</h3>
                            <span className="font-display text-xs text-slate-400">
                                Users making the most of Digital Signage
                            </span>
                        </div>
                        <div className="py-5 justify-around space-y-1 " style={{ width: '120px' }}>
                            <h3 className="font-display font-bold text-4xl md:text-5xl">5K+</h3>
                            <span className="font-display text-xs text-slate-400">
                                Screens deployed across Australia & New Zealand
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Second row */}
            <div className="w-full bg-slate-50 items-center py-25" style={{ height: '300px' }}>
                <div className="max-w-6xl mx-auto  overflow-hidden">
                    <div className="scrolling-container ">
                        {duplicatedImagesList.map((image, index) => (
                            <img
                                key={index}
                                className="w-40 mr-4"
                                src={image.src}
                                alt={image.alt}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {/* jumped module */}
            <div className="md:hidden flex px-2">
                <div className="py-5 justify-around mr-2 space-y-1 " style={{ width: '140px' }}>
                    <h3 className="font-display font-bold text-4xl md:text-5xl">1K+</h3>
                    <span className="font-display text-xs text-slate-400">
                        Users making the most of Digital Signage
                    </span>
                </div>
                <div className="py-5 justify-around ml-2 space-y-1 " style={{ width: '140px' }}>
                    <h3 className="font-display font-bold text-4xl md:text-5xl">5K+</h3>
                    <span className="font-display text-xs text-slate-400">
                        Screens deployed across Australia & New Zealand
                    </span>
                </div>
            </div>
            {/* Third row  ABOUT US*/}
            <div className="w-full pt-44 bg-white">
                <div className="max-w-6xl mx-auto flex">
                    {/* First row */}
                    <div className="mx-auto text-center space-y-4">
                        <div className="w-full">
                            <span className="font-display font-bold text-2xl text-customPrimary">
                                ABOUT US
                            </span>
                        </div>
                        <div className="w-full">
                            <span className="font-display font-bold text-4xl md:text-5xl">
                                Everything you need for Digital Signage
                            </span>
                        </div>
                        <div className="w-full px-24">
                            <p>
                                At Cybercast, we’re solving the problem of a fragmented marketplace
                                by offering high-quality hardware and software that works together
                                seamlessly and can be easily configured by anyone.{' '}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl mx-auto flex items-center mb-8">
                    <AboutUsCard />
                </div>
            </div>
            {/* Fourth row features */}
            <div className="w-full pt-44">
                <ResponsiveSnap />
            </div>
            {/* Fifth row  support*/}
            <div className="w-full pt-44 pb-44">
                <div className="max-w-6xl mx-auto ">
                    <div
                        className="relative bg-cover bg-center h-screen flex justify-center rounded-2xl "
                        style={{ backgroundImage: `url(${Solutions})`, height: '365px' }}
                    >
                        <div className="text-center">
                            <h1 className="font-display font-bold text-2xl mt-14 mb-3 text-customPrimary">
                                SUPPORT
                            </h1>
                            <span className="font-display font-bold text-4xl  md:text-5xl">
                                We are here to help
                            </span>
                            <p className="mx-auto mt-3 mb-4" style={{ width: '65%' }}>
                                Being part of the Cybercast family means you're able to get direct
                                contact to our technicians, developers and designers. You'll always
                                have someone on hand to assist.
                            </p>
                            <Button
                                size="medium"
                                variant="contained"
                                href="./contact"
                                color="primary"
                                style={{ color: '#ffffff', fontSize: '16px', fontWeight: 500 }}
                            >
                                Speak to an Expert
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sixth row  Testmonial*/}
            <div className="w-full pt-44 pb-44 bg-customPrimary">
                <TestimonialBlock />
            </div>
            {/* Seventh row  profolio*/}
            <div className="w-full text-center mx-auto mt-36 ">
                <span className="font-display font-bold text-2xl text-customPrimary">
                    CASE STUDIES
                </span>
            </div>
            <div
                className="relative w-full bg-scroll mt-4 overflow-hidden"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    height: '740px',
                }}
            >
                <div className="flex flex-col items-center text-center justify-between ">
                    <span className="font-display font-bold text-4xl md:text-5xl mt-0">
                        Check out our Awesome Porfolio{' '}
                    </span>

                    <div className="max-w-6xl flex flex-wrap mt-8 justify-center">
                        <img
                            src={Client1}
                            alt="Description 1"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                        <img
                            src={Client2}
                            alt="Description 2"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                        <img
                            src={Client3}
                            alt="Description 3"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                        <img
                            src={Client4}
                            alt="Description 1"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                        <img
                            src={Client5}
                            alt="Description 2"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                        <img
                            src={Client6}
                            alt="Description 3"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                        <img
                            src={Client7}
                            alt="Description 1"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                        <img
                            src={Client8}
                            alt="Description 2"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                        <img
                            src={Client9}
                            alt="Description 3"
                            className="m-2 max-w-xs rounded-md hover:opacity-80"
                        />
                    </div>
                    <div className="absolute left-0 top-50 inset-0 bg-gradient-to-t from-white to-transparent flex justify-center items-center">
                        <Button
                            size="large"
                            variant="contained"
                            href="./contact"
                            color="primary"
                            style={{
                                absolute: 'bottom-0',
                                color: '#ffffff',
                                fontSize: '16px',
                                fontWeight: 500,
                                padding: '24px 32px',
                            }}
                        >
                            Speak to an Expert
                        </Button>
                    </div>
                </div>
            </div>

            {/* Eighth row  News*/}
            {/* <div className="w-full pt-16 pb-44">
                <News />
            </div> */}
            {/* Nineth row  Get started*/}
            <div
                className="w-full pt-20 pb-44 relative bg-cover bg-center h-screen flex justify-center"
                style={{ backgroundImage: `url(${GetStartedBg})`, height: '511px' }}
            >
                <GetStarted />
            </div>
            {/* Tenth row Get started*/}
            {/* <div className="w-full pt-10 pb-10 ">
                <BottomSignUp />
            </div> */}
        </div>
    );
};
export default HomePage;
