import { Breadcrumbs, Typography } from '@mui/material';
import { MdHome, MdNavigateNext } from 'react-icons/md';

import { Link } from 'react-router-dom';

const WebBreadcrumbs = (props) => {
	let { sites } = props;
	sites = sites ? sites : [];

	return (
		<Breadcrumbs separator={<MdNavigateNext />}>
			<Link
				to='/'
				className='flex place-items-center text-cybercast-blue no-underline visited:text-cybercast-blue group py-2'>
				<MdHome fontSize='inherit' className='mr-0.5' />{' '}
				<div className='transition duration-300 border-b-2 border-solid border-transparent group-hover:border-cybercast-blue '>
					Home
				</div>
			</Link>
			{sites.map((site, i) => {
				// console.log(`${[...Array(i+1).keys()].join('/').replaceAll(/(\d)/g,(match,key)=>{return sites[parseInt(match)].link})}`)
				return i === sites.length - 1 ? (
					<Typography key={site.name}>{site.name}</Typography>
				) : (
					// using an array till i, join them by '/' then replace number with sites[i].link
					<Link
						key={site.name}
						to={`/${[...Array(i + 1).keys()].join('/').replaceAll(/(\d)/g, (match, key) => {
							return sites[parseInt(match)].link;
						})}`}
						className='flex place-items-center text-cybercast-blue no-underline un'>
						{site.name}
					</Link>
				);
			})}
		</Breadcrumbs>
	);
};

export default WebBreadcrumbs;
