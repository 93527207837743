// useWebSocket.js
import { useEffect, useRef, useState } from 'react';

const useWebSocket = (url) => {
    const [messages, setMessages] = useState([]);
    const websocketRef = useRef(null);

    useEffect(() => {
        // 建立WebSocket连接
        websocketRef.current = new WebSocket(url);

        websocketRef.current.onopen = () => {
            console.log('WebSocket连接已打开');
            // 可以在这里发送一个连接成功的消息或进行其他操作
        };

        websocketRef.current.onmessage = (event) => {
            // 当接收到消息时更新状态
            const message = JSON.parse(event.data);
            setMessages((prevMessages) => [...prevMessages, message]);
        };

        websocketRef.current.onclose = () => {
            console.log('WebSocket连接已关闭');
            // 可以在这里尝试重连或进行其他操作
        };

        // // 清理函数
        // return () => {
        //     websocketRef.current.close();
        // };
    }, [url]);

    // 发送消息
    const sendMessage = (message) => {
        if (websocketRef.current) {
            websocketRef.current.send(JSON.stringify(message));
        }
    };

    // 心跳检测
    const sendHeartbeat = async () => {
        const response = await fetch('http://13.55.17.16:8080/backstage/websocket/heartbeat');
        const text = await response.text();
        console.log(text); // 应该打印"pong"，如果服务器响应正常
    };

    return { messages, sendMessage, sendHeartbeat };
};

export default useWebSocket;
