import * as Yup from 'yup';

import { Button, FormControl, FormLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNotification, useStateContext } from '../contexts/ContextProvider';

import { DenseInput } from '../components/dashboard/utils';
import { Header } from '../components/dashboard/';
import { ResetPassword } from '../api/api';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

const style = {
    labelText: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        marginTop: '8px',
        fontSize: '14px',
        color: 'black',
    },
    label: {
        minWidth: '170px',
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
        color: 'black',
    },
    labelSelect: {
        display: 'flex',
        textAlign: 'end',
        paddingRight: '12px',
        fontSize: '14px',
    },
};

const SetUpNewPassword = () => {
    const { currentUser } = useStateContext();
    const notify = useNotification();
    const navigate = useNavigate();
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const url_code = params.get('code');

    console.log('url_code', url_code);

    const initialValues = {
        new_pwd: '',
    };
    const [formData, setFormData] = useState(initialValues);
    const handleSubmit = async () => {
        const { current_pwd, new_pwd, confirm_pwd } = formData;
        const { code, data, msg } = await ResetPassword(url_code, new_pwd);
        console.log('file: SetUpNewPassword.jsx:72 => handleSubmit => msg:', msg);
        console.log('file: SetUpNewPassword.jsx:72 => handleSubmit => code:', code);

        console.log('data:', data);
        if (code === 0) {
            // setFormData(initialValues);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'Password was changed successfully!!',
                },
            });
            navigate('../login', { replace: true });
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'Password was changed unsuccessfully!',
                },
            });
        }
    };
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div className="md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <Header title="Reset Password" />

            <FormControl
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <FormLabel id="new_pwd" sx={style.labelText}>
                    New Password
                </FormLabel>
                <DenseInput
                    fullWidth
                    margin="normal"
                    aria-labelledby="new_pwd"
                    name="new_pwd"
                    type="password"
                    variant="outlined"
                    onChange={handleChange}
                />
            </FormControl>

            <div className="flex w-full justify-center mt-6">
                <Button variant="contained" onClick={() => handleSubmit()}>
                    save
                </Button>
            </div>
        </div>
    );
};

export default SetUpNewPassword;
