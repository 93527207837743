import {
    Avatar,
    Badge,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
// GetCampaignPlaybackGroupList;
import { CopyDialog, DeleteDialog, Pulse } from '../utils';
import {
    GrCaretNext,
    GrCopy,
    GrNext,
    GrShareOption,
    GrStar,
    GrSystem,
    GrTrash,
} from 'react-icons/gr';
import { HiOutlineLockClosed, HiOutlineLockOpen } from 'react-icons/hi';
import React, { useEffect, useState } from 'react';
import { RiDeleteBin6Fill, RiFolder3Fill, RiSettings2Fill } from 'react-icons/ri';
import { deleteCampaign, getCampaignDetail } from '../../../api/api';

import CampaignDetailDialog from './CampaignDetailDialog';
import { FaMapMarkerAlt } from 'react-icons/fa';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { TbFileExport } from 'react-icons/tb';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// import { useStateContext } from '../contexts/ContextProvider';
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -16,
        top: 12,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0.5),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const CampaignList = ({
    item,
    locationList,
    expanded,
    handleExpand,
    currentUser,
    triggerUpdate,
    setTriggerUpdate,
    // handleCopyCampaign,
    // handleDeleteCampaign,
}) => {
    console.log('expanded:', expanded);
    // Handle Expand area
    const [innerExpanded, setInnerExpanded] = useState('');
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    const handleInnerExpand = (panel) => (newExpanded) => {
        if (innerExpanded !== panel) setInnerExpanded(newExpanded ? panel : false);
        else setInnerExpanded();
    };
    /**
     *
     * Function: Open campaign detail dialog that allow to
     * preview, delete & Edit campaign
     * @param {*} item
     */
    const [openCampaignDetial, setOpenCampaignDetial] = useState(false);
    const [currentCampaign, setCurrentCampaign] = useState();

    const handleCampaignDetail = (item) => {
        setOpenCampaignDetial(true);
        console.log('detail================================');
        console.log('item', item);
        handleCampaignDetails(item.campaign_id);
        // setCurrentCampaign(item);
    };

    const handleCampaignDetails = async (campaign_id) => {
        const data = await getCampaignDetail(switchUser, campaign_id);
        console.log('data', data);
        setCurrentCampaign(data.data);
    };

    const handleCloseCampaignDetail = (item) => {
        setOpenCampaignDetial(false);
    };

    // Delete campaign
    const handleDeleteCampaign = async (item) => {
        const data = await deleteCampaign(switchUser, item.campaign_id);
        console.log('delete', data);
        if (data.code === 0) setTriggerUpdate(!triggerUpdate);
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const handleOpenDeleteDialog = (item) => {
        console.log('item', item);
        setDeleteItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const [copyItem, setCopyItem] = useState();

    const handleOpenCopyDialog = (item) => {
        console.log('file: CampaignList.jsx:141 => handleOpenCopyDialog => item:', item);
        setCopyItem(item);
        setOpenCopyDialog(true);
    };

    const handleCloseCopyDialog = () => {
        setOpenCopyDialog(false);
    };

    // Paid campaign
    const [isHovered, setIsHovered] = useState();
    const toggleHover = (item) => {
        setIsHovered(item);
    };
    const toggleLeave = () => {
        setIsHovered('');
    };

    return (
        <Accordion expanded={expanded === item}>
            <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ paddingY: '8px' }}
                expandIcon={
                    item.group_num !== 0 && <GrNext size={15} onClick={handleExpand(item)} />
                }
            >
                <div className="flex justify-between w-full">
                    <div className="flex flex-row">
                        <GrStar className="mt-1 mr-1" color="gray" />
                        <Typography onClick={() => handleCampaignDetail(item)}>
                            {item.campaign_name}
                        </Typography>
                        <div className="ml-1">
                            {item.free_campaign === 0 && (
                                <Tooltip
                                    title="Unlock campagin"
                                    onMouseEnter={() => toggleHover(item)}
                                    onMouseLeave={toggleLeave}
                                    // onClick={() => handleClickOpen(item)}
                                >
                                    <IconButton size="small" sx={{ paddingTop: '3px' }}>
                                        {isHovered === item ? (
                                            <HiOutlineLockOpen color="black" />
                                        ) : (
                                            <HiOutlineLockClosed color="red" />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                        <StyledBadge badgeContent={item.group_num} color="primary">
                            {item.group_name}
                        </StyledBadge>

                        {openCampaignDetial && (
                            <CampaignDetailDialog
                                open={openCampaignDetial}
                                handleDeleteCampaign={handleDeleteCampaign}
                                item={item}
                                currentCampaign={currentCampaign}
                                handleCloseCampaignDetail={handleCloseCampaignDetail}
                            />
                        )}
                    </div>

                    <div className="flex flex-row ">
                        <Tooltip title="Copy">
                            <IconButton size="small" onClick={() => handleOpenCopyDialog(item)}>
                                <GrCopy size={15} />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title='Export'>
							<IconButton size='small'>
								<GrShareOption size={15} />
							</IconButton>
						</Tooltip> */}
                        <Tooltip title="Delete">
                            <IconButton size="small" onClick={() => handleOpenDeleteDialog(item)}>
                                <GrTrash size={15} />
                            </IconButton>
                        </Tooltip>

                        {openDeleteDialog && (
                            <DeleteDialog
                                openDialog={openDeleteDialog}
                                handleCloseDialog={handleCloseDeleteDialog}
                                setTriggerUpdate={setTriggerUpdate}
                                text={'campaign'}
                                deleteItem={deleteItem}
                                triggerUpdate={triggerUpdate}
                            />
                        )}
                        {openCopyDialog && (
                            <CopyDialog
                                openCopyDialog={openCopyDialog}
                                handleCloseCopyDialog={handleCloseCopyDialog}
                                setTriggerUpdate={setTriggerUpdate}
                                text={'campaign'}
                                copyItem={copyItem}
                                triggerUpdate={triggerUpdate}
                            />
                        )}
                    </div>
                </div>
            </AccordionSummary>
            {locationList?.length > 0 ? (
                <AccordionDetails
                    sx={{ paddingTop: '0px', paddingBottom: '0px', paddingX: '32px' }}
                >
                    {locationList?.map((x, index) => (
                        <Accordion
                            key={x + index}
                            sx={{ border: '0' }}
                            expanded={innerExpanded === x}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                sx={{
                                    backgroundColor: 'rgba(13, 202, 240, 0.2)',
                                    border: '0',
                                    padding: '0',
                                    marginLeft: '12px',
                                    borderRadius: '10px',
                                    margin: '10px',
                                    paddingX: '20px',
                                }}
                                expandIcon={<GrNext size={15} onClick={handleInnerExpand(x)} />}
                            >
                                <div className="flex justify-between w-full">
                                    <div className="flex flex-row">
                                        <FaMapMarkerAlt className="mt-1 mr-1" color="#12acd7" />
                                        <Typography>{x.group_name}</Typography>
                                    </div>
                                </div>
                            </AccordionSummary>
                            {(Array.isArray(x?.device_list) ? x?.device_list : [x?.device_list])
                                ?.length > 0 && (
                                <AccordionDetails
                                    sx={{
                                        borderTop: '0',
                                        paddingBottom: '0',
                                        marginLeft: '38px',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {(Array.isArray(x?.device_list)
                                        ? x?.device_list
                                        : [x?.device_list]
                                    )?.map((i, index) => (
                                        <div
                                            key={i + index}
                                            className="flex w-full flex-row items-center"
                                        >
                                            <GrSystem size={15} />
                                            <p className="m-1 pl-2">{i.device_name}</p>
                                        </div>
                                    ))}
                                </AccordionDetails>
                            )}
                        </Accordion>
                    ))}
                </AccordionDetails>
            ) : (
                <Pulse />
            )}
        </Accordion>
    );
};

export default CampaignList;
