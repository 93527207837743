import { Divider, Grid, Link, Typography } from '@mui/material';

import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import logo1 from '../../images/logo1.png';
import { useNavigate } from 'react-router-dom';

function WebFooter() {
    let navigate = useNavigate();
    function handleClick() {
        navigate('/');
    }
    return (
        <div className=" w-full bg-white pt-10 pb-2">
            <div className="max-w-6xl mx-auto h-70">
                <div className="flex flex-wrap mb-10 ">
                    {/* spacing={5} columns={{ xs: 4, sm: 12 }} */}
                    <div className="w-full pl-2 md:w-1/2 ">
                        <button type="button" onClick={handleClick}>
                            <img className="w-56 pb-1" src={logo1} alt="Cybercast" />
                        </button>
                        <h6 className="pb-1 font-display font-bold">Australia Office</h6>
                        <h6 className="pb-3 text-slate-400">414 Lower Dandenong Road, Braeside, Victoria 3195 </h6>
                        <h6 className="pb-1 font-display font-bold">New Zealand Office</h6>
                        <h6 className="pb-3 text-slate-400">4/113 Pavilion Drive, Airport Oaks, Auckland 2022 </h6>
                    </div>
                    <div className="w-full pl-2 md:w-1/2 flex flex-wrap">
                        <div className="pl-2 pt-8 pb-2 w-1/3 space-y-3">
                            <h6 className="font-display font-bold">Product</h6>
                            <h6 className="text-slate-400">
                                <a href="./product/hardware">Hardware</a>{' '}
                            </h6>
                            {/* <h6 className="text-slate-400">
                                <a>Software </a>
                            </h6> */}
                            <h6 className=" text-slate-400">
                                <a href="./pricing">Pricing </a>
                            </h6>
                            <h6 className="text-slate-400">
                                <a href="./product/features">Features </a>
                            </h6>
                        </div>
                        {/* <div className="pl-2 pt-8 pb-2 w-1/3 space-y-3">
						<h6 className=' font-display font-bold'>Resources</h6>
						<h6 className='text-slate-400'>
							FAQ						</h6>
						<h6 className='text-slate-400'>
							Case studies					</h6>
						<h6 className=' text-slate-400'>
							Industries					</h6>
						<h6 className='text-slate-400'>
							Blog					</h6>
					</div> */}
                        <div className="pl-2 pt-8 pb-2 w-1/3 space-y-3">
                            <h6 className="font-display font-bold">Company</h6>
                            <h6 className="text-slate-400">
                                <a href="./contact">Contact</a>{' '}
                            </h6>
                            <h6 className="text-slate-400">
                                <a href="./web/policy.html">Privacy Statement </a>
                            </h6>
                            <h6 className=" text-slate-400">
                                <a href="./web/terms.html">Terms of Use</a>{' '}
                            </h6>
                            {/* <h6 className="text-slate-400">Wattanty </h6> */}
                        </div>
                    </div>
                </div>
                <Divider />
                <p className="py-10 mr-auto">
                    © 2021 All rights reserved by
                    <ReactLink underline="none" to="/" className="text-slate-500 font-bold no-underline">
                        {' '}
                        CYBERCAST
                    </ReactLink>
                </p>
            </div>
        </div>
    );
}
export default WebFooter;
