import { Box, Button, Card, Container, Stack, TextField, Typography } from '@mui/material';

import Icon1 from '../../images/icon1.png';
import Icon2 from '../../images/icon2.png';
import Icon3 from '../../images/icon3.png';

function TagColumn({
    bg,
    text,
    icon,
    customer,
    category,
    slogan,
    price,
    timespan,
    feature1,
    feature2,
    feature3,
    feature4,
}) {
    return (
        <div
            className="shadow-lg flex flex-col p-12 rounded-2xl w-96"
            style={{ backgroundColor: `${bg}`, color: `${text}` }}
        >
            <div className="flex items-center mb-4 space-x-3">
                <div className="w-16 h-16 bg-gray">
                    <img src={icon} />
                </div>
                <div>
                    <h3>{customer}</h3>
                    <h3 className="font-display text-3xl font-bold">{category}</h3>
                </div>
            </div>
            <h3 className=" mb-4">{slogan}</h3>
            <div className="flex ">
                <span className="font-display text-5xl font-bold">{price}</span>
                <span className="text-sm mt-auto">{timespan}</span>
            </div>
            <Stack spacing={2} sx={{ mt: 5 }}>
                <h3 className="font-display text-xl font-semibold">What's included</h3>
                <div className="flex items-center space-x-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                    >
                        <path
                            d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                            fill={bg === '#ffffff' ? '#01B5E2' : '#ffffff'}
                        />
                        <path
                            d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                            stroke={bg === '#ffffff' ? '#ffffff' : '#01B5E2'}
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <h3>{feature1}</h3>
                </div>
                <div className="flex items-center space-x-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                    >
                        <path
                            d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                            fill={bg === '#ffffff' ? '#01B5E2' : '#ffffff'}
                        />
                        <path
                            d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                            stroke={bg === '#ffffff' ? '#ffffff' : '#01B5E2'}
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <h3>{feature2}</h3>
                </div>
                <div className="flex items-center space-x-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                    >
                        <path
                            d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                            fill={bg === '#ffffff' ? '#01B5E2' : '#ffffff'}
                        />
                        <path
                            d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                            stroke={bg === '#ffffff' ? '#ffffff' : '#01B5E2'}
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <h3>{feature3}</h3>
                </div>
                <div className="flex items-center space-x-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                    >
                        <path
                            d="M13.5 26.2998C20.6799 26.2998 26.5 20.4797 26.5 13.2998C26.5 6.1199 20.6799 0.299805 13.5 0.299805C6.3201 0.299805 0.5 6.1199 0.5 13.2998C0.5 20.4797 6.3201 26.2998 13.5 26.2998Z"
                            fill={bg === '#ffffff' ? '#01B5E2' : '#ffffff'}
                        />
                        <path
                            d="M7.6167 14.1404L10.9785 17.5022L19.3831 9.09766"
                            stroke={bg === '#ffffff' ? '#ffffff' : '#01B5E2'}
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <h3>{feature4}</h3>
                </div>
                {bg === '#ffffff' && (
                    <Button
                        variant="contained"
                        href="./signup"
                        color="primary"
                        style={{
                            color: '#ffffff',
                            fontSize: '16px',
                            fontWeight: 500,
                            padding: '20px 32px',
                        }}
                    >
                        Get Started
                    </Button>
                )}

                {bg === '#01B5E2' && (
                    <Button
                        variant="contained"
                        href="./signup"
                        color="white"
                        style={{
                            color: '#01B5E2',
                            fontSize: '16px',
                            fontWeight: 500,
                            padding: '20px 32px',
                        }}
                    >
                        Get Started
                    </Button>
                )}
            </Stack>
        </div>
    );
}
function PriceTag({ checked }) {
    const PriceData = [
        {
            switch: false,
            bg: '#ffffff',
            text: '#212121',
            icon: Icon1,
            customer: 'General Edition',
            category: 'Standard',
            slogan: 'Perfect for getting started with a side project.',
            price: '$29',
            timespan: '/month per screen',
            feature1: 'Basic CMS features',
            feature2: 'Free templates',
            feature3: 'Normal support',
            feature4: '1GB Media Storage',
        },
        {
            switch: false,
            bg: '#ffffff',
            text: '#212121',
            icon: Icon2,
            customer: 'General Edition',
            category: 'Standard',
            slogan: 'Perfect for getting started with a side project.',
            price: '$179',
            timespan: '/6 months per screen',
            feature1: 'Basic CMS features',
            feature2: 'Free templates',
            feature3: 'Normal support',
            feature4: '1GB Media Storage',
        },
        {
            switch: false,
            bg: '#ffffff',
            text: '#212121',
            icon: Icon3,
            customer: 'General Edition',
            category: 'Standard',
            slogan: 'Perfect for getting started with a side project.',
            price: '$330',
            timespan: '/year per screen',
            feature1: 'Basic CMS features',
            feature2: 'Free templates',
            feature3: 'Normal support',
            feature4: '1GB Media Storage',
        },
        {
            switch: true,
            bg: '#01B5E2',
            text: '#ffffff',
            icon: Icon1,
            customer: 'Business Edition',
            category: 'Enterprise',
            slogan: 'Ready for large teams with enough room to scale.',
            price: '$49',
            timespan: '/month per screen',
            feature1: 'All CMS features',
            feature2: '10hrs of Content Creation',
            feature3: 'Dedicated support',
            feature4: 'Unlimited Media Storage',
        },
        {
            switch: true,
            bg: '#01B5E2',
            text: '#ffffff',
            icon: Icon2,
            customer: 'Business Edition',
            category: 'Enterprise',
            slogan: 'Ready for large teams with enough room to scale.',
            price: '$299',
            timespan: '/6 months per screen',
            feature1: 'All CMS features',
            feature2: '10hrs of Content Creation',
            feature3: 'Dedicated support',
            feature4: 'Unlimited Media Storage',
        },
        {
            switch: true,
            bg: '#01B5E2',
            text: '#ffffff',
            icon: Icon3,
            customer: 'Business Edition',
            category: 'Enterprise',
            slogan: 'Ready for large teams with enough room to scale.',
            price: '$540',
            timespan: '/year per screen',
            feature1: 'All CMS features',
            feature2: '10hrs of Content Creation',
            feature3: 'Dedicated support',
            feature4: 'Unlimited Media Storage',
        },
    ];
    return (
        <div>
            <div className="max-w-8xl mx-auto py-4 flex flex-col md:flex-row md:justify-center gap-12">
                {PriceData.map((column) =>
                    column.switch === checked ? (
                        <TagColumn
                            bg={column.bg}
                            text={column.text}
                            icon={column.icon}
                            category={column.category}
                            customer={column.customer}
                            slogan={column.slogan}
                            price={column.price}
                            timespan={column.timespan}
                            feature1={column.feature1}
                            feature2={column.feature2}
                            feature3={column.feature3}
                            feature4={column.feature4}
                        />
                    ) : null,
                )}
            </div>
        </div>
    );
}
export default PriceTag;
