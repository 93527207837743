import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputLabel,
    ListItemIcon,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {
    MdAppSettingsAlt,
    MdKeyboardArrowDown,
    MdLogoDev,
    MdLogout,
    MdOutlineAccountCircle,
    MdOutlineAssignment,
    MdOutlinePayments,
    MdOutlineSecurity,
    MdOutlineSubscriptions,
} from 'react-icons/md';
import React, { useEffect } from 'react';

import { AccountSettings } from '../../pages';
import { AiOutlineMenu } from 'react-icons/ai';
import Tooltip from '@mui/material/Tooltip';
import avatar from '../../data/avatar.jpg';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';

// import Avatar from '@mui/material/Avatar';

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
    <Tooltip title={title} position="BottomCenter">
        <IconButton
            // type='button'
            onClick={() => customFunc()}
            style={{ color }}
            className="relative text-xl rounded-full p-3 hover:bg-light-gray"
        >
            <span
                style={{ background: dotColor }}
                className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
            />
            {icon}
        </IconButton>
    </Tooltip>
);

const Navbar = () => {
    const {
        currentColor,
        activeMenu,
        setActiveMenu,
        setCurrentUser,
        isClicked,
        currentUser,
        setScreenSize,
        screenSize,
    } = useStateContext();
    const navigate = useNavigate();

    // Switch User name
    const username = JSON.parse(localStorage.getItem('switchUserName'));
    // console.log('file: Navbar.jsx:73 => Navbar => username:', username);

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (screenSize <= 1200) {
            setActiveMenu(false);
        } else {
            setActiveMenu(true);
        }
    }, [screenSize]);

    const handleActiveMenu = () => setActiveMenu(!activeMenu);
    const handleAccountSettings = () => navigate('/dashboard/account-settings');
    const handleAdmin = () => navigate('/dashboard/admin');
    const handleSecurity = () => navigate('/dashboard/security');
    const handleContract = () => navigate('/dashboard/contract');
    const handlePayment = () => navigate('/dashboard/payment');
    const handleSubscription = () => navigate('/dashboard/subscription');
    // path='/dashboard/order-summary'
    const handleOrderSummary = () => navigate('/dashboard/order-summary');
    const handleLog = () => navigate('/dashboard/log');

    /**
     *
     * dropdown menu
     *
     */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDropdownMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Handle logout
    const handleLogout = () => {
        localStorage.removeItem('currentUser');
        setCurrentUser('');
        navigate('/login', { replace: true });
    };

    return (
        <div className="flex w-full justify-between p-2 relative bg-white">
            {/* <div className='md:ml-6'> */}
            <NavButton
                title="Menu"
                customFunc={handleActiveMenu}
                color={currentColor}
                icon={<AiOutlineMenu />}
            />
            {/* </div> */}
            <div className="flex mr-2">
                <Tooltip title="Account Settings">
                    <div
                        className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                        onClick={handleDropdownMenu}
                    >
                        {/* <img className='rounded-full w-8 h-8' src={avatar} alt='user-profile' /> */}
                        <p className="text-center m-0">
                            <span className="text-gray-400 text-14">Hi,</span>{' '}
                            <span className="text-gray-400 font-bold ml-1 text-14">
                                {username ? username : currentUser.username}
                            </span>
                        </p>
                        <MdKeyboardArrowDown className="text-gray-400 text-14" />
                    </div>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,

                            display: 'flex',
                            justifyContent: 'center',
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                                zIndex: 999,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                // transform: 'translateY(-50%) rotate(45deg)',
                                // zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            padding: 1.5,
                        }}
                        onClick={handleAccountSettings}
                    >
                        <ListItemIcon>
                            {/* <img className='rounded-full w-8 h-8' src={avatar} alt='user-profile' /> */}
                            <MdOutlineAccountCircle fontSize="large" />
                        </ListItemIcon>
                        Account Settings
                    </MenuItem>

                    {/* <MenuItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            padding: 1.5,
                        }}
                        onClick={handleSecurity}
                    >
                        <ListItemIcon>

                            <MdOutlineSecurity fontSize="large" />
                        </ListItemIcon>
                        Security
                    </MenuItem> */}
                    <MenuItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            padding: 1.5,
                        }}
                        onClick={handleContract}
                    >
                        <ListItemIcon>
                            {/* <img className='rounded-full w-8 h-8' src={avatar} alt='user-profile' /> */}
                            <MdOutlineAssignment fontSize="large" />
                        </ListItemIcon>
                        Contract
                    </MenuItem>
                    <MenuItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            padding: 1.5,
                        }}
                        onClick={handlePayment}
                    >
                        <ListItemIcon>
                            {/* <img className='rounded-full w-8 h-8' src={avatar} alt='user-profile' /> */}
                            <MdOutlinePayments fontSize="large" />
                        </ListItemIcon>
                        Payment
                    </MenuItem>
                    <MenuItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            padding: 1.5,
                        }}
                        onClick={handleSubscription}
                    >
                        <ListItemIcon>
                            {/* <img className='rounded-full w-8 h-8' src={avatar} alt='user-profile' /> */}
                            <MdOutlineSubscriptions fontSize="large" />
                        </ListItemIcon>
                        Subscription
                    </MenuItem>
                    {/* <MenuItem
						sx={{
							display: 'flex',
							justifyContent: 'left',
							padding: 1.5,
						}}
						onClick={handleOrderSummary}>
						<ListItemIcon>
							<MdOutlineAccountCircle fontSize='large' />
						</ListItemIcon>
						Order Summary
					</MenuItem> */}
                    {currentUser.user_level === 0 && (
                        <>
                            <MenuItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    padding: 1.5,
                                }}
                                onClick={handleLog}
                            >
                                <ListItemIcon>
                                    <MdLogoDev fontSize="large" />
                                </ListItemIcon>
                                Log
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    padding: 1.5,
                                }}
                                onClick={handleAdmin}
                            >
                                <ListItemIcon>
                                    <MdAppSettingsAlt fontSize="large" />
                                </ListItemIcon>
                                Admin
                            </MenuItem>
                        </>
                    )}

                    <MenuItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            padding: 1.5,
                        }}
                        onClick={handleLogout}
                    >
                        <ListItemIcon className="px-1">
                            <MdLogout fontSize="large" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default Navbar;
