import { alpha, styled } from '@mui/material/styles';

import TextField from '@mui/material/TextField';

export const DenseInput = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        border: 'none',
        position: 'relative',
        height: '20px',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 14,
        padding: '4px 8px',
        minWidth: '70px',
    },
    '& fieldset': { border: 'none' },
}));
// export default DeleteDialog;
