import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import { GetMenuList, GetUserInfo } from '../../api/api';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Link, NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { GrClose } from 'react-icons/gr';
import { MdOutlineClose } from 'react-icons/md';
import { TiBackspaceOutline } from 'react-icons/ti';
// import Tooltip from '@mui/material/Tooltip';
import { links } from '../../data/dummy';
import logo from '../../data/cybercast-logo-pro.png';
import { styled } from '@mui/material/styles';
import { useStateContext } from '../../contexts/ContextProvider';

// import { TooltipComponent } from '@syncfusion/ej2-react-popups';
const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const Sidebar = () => {
    const { currentColor, activeMenu, setActiveMenu, screenSize, currentUser } = useStateContext();
    console.log('file: Sidebar.jsx:53 => Sidebar => currentUser:', currentUser);
    // const activeMenu = true;
    const switchedUser = JSON.parse(localStorage.getItem('switchUser'));

    const handleCloseSideBar = () => {
        // console.log('file: Sidebar.jsx:59 => handleCloseSideBar => activeMenu:', activeMenu);
        if (activeMenu !== undefined && screenSize <= 900) {
            setActiveMenu(false);
        }
    };
    const [userList, setUserList] = useState([]);
    // console.log('file: Sidebar.jsx:83 => Sidebar => userList:', userList);
    const [switchUser, setSwitchUser] = useState(switchedUser);
    const [menuList, setMenuList] = useState([]);
    const [userInfo, setUserInfo] = useState();
    // console.log('file: Sidebar.jsx:85 => Sidebar => userInfo:', userInfo);

    const handleMenuList = async () => {
        const { code, data, msg } = await GetMenuList(switchUser);
        if (code === 0) {
            setMenuList(data?.list);
        }
        // else {
        //     alert(msg);
        // }
    };
    useEffect(() => {
        handleGetStorage();
        handleMenuList();
    }, []);

    useEffect(() => {
        setUserList(JSON.parse(localStorage.getItem('allUsers')));
    }, [localStorage.getItem('allUsers')]);

    const [value, setValue] = React.useState();
    // console.log('file: Sidebar.jsx:126 => Sidebar => value:', value);
    const formattedUserList = userList?.map((user) => ({
        label: user.username,
        value: user.user_id,
    }));
    // console.log('file: Sidebar.jsx:132 => formattedUserList => formattedUserList:', formattedUserList);
    const handleSelectUser = (newValue) => {
        // console.log('file: Sidebar.jsx:133 => handleSelectUser => newValue:', newValue);
        currentUser.user_id !== newValue.value
            ? localStorage.setItem('switchUser', JSON.stringify(newValue.value))
            : localStorage.removeItem('switchUser');
        const name = userList.find((x) => x.user_id === newValue.value).username;
        // handleGetStorage(switchedUser ? switchedUser : null);
        // console.log('file: Sidebar.jsx:88 ===> handleChange ===> name:', name);
        localStorage.setItem('switchUserName', JSON.stringify(name));
        window.location.href = './dashboard/schedule-campaign';
        handleGetStorage();
    };

    const handleRedirectToHome = () => {
        setActiveMenu(false);
        window.location.href = './';
    };

    const handleGetStorage = async () => {
        // console.log('file: Sidebar.jsx:132 => handleGetStorage => user_id:', switchedUser);
        const { code, data } = await GetUserInfo(switchUser);
        if (code === 0) {
            setUserInfo(data);
            localStorage.setItem('userDetails', JSON.stringify(data));
        }
    };

    const activeLink =
        'flex items-center gap-3 pl-4 p-4 my-1 pb-2.5 rounded-md text-white text-lg m-2 no-underline';
    const normalLink =
        'flex items-center gap-3 pl-4 p-4 my-1 pb-2.5 rounded-md text-lg text-gray-700 no-underline dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2 hover:text-sky-400';

    return (
        <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 bg-white">
            {activeMenu && (
                <div className="flex flex-col h-full w-full">
                    <div className="flex justify-between items-center">
                        <Link
                            // to='/'
                            onClick={handleRedirectToHome}
                            className="items-center gap-3 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
                        >
                            <img
                                src={logo}
                                alt="Logo"
                                style={{
                                    maxHeight: '72px',
                                    paddingLeft: '20px',
                                }}
                            />
                        </Link>
                        <Tooltip>
                            <IconButton
                                onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
                                style={{
                                    color: currentColor,
                                    marginTop: '24px',
                                }}
                                className="text-xl p-3 hover:bg-light-gray mt-4 block md:invisible"
                            >
                                <MdOutlineClose />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="flex w-full">
                        <div className="w-full">
                            {links
                                ?.filter((x) =>
                                    menuList?.find((y) => x.name.toLowerCase() === y.menu_name.toLowerCase()),
                                )
                                ?.map((link) => (
                                    <NavLink
                                        to={link.path}
                                        key={link.name}
                                        onClick={handleCloseSideBar}
                                        style={({ isActive }) => ({
                                            backgroundColor: isActive ? currentColor : '',
                                            paddingTop: '20px',
                                        })}
                                        className={({ isActive }) => (isActive ? activeLink : normalLink)}
                                    >
                                        {link.icon}
                                        <span className="capitalize ">{link.name}</span>
                                    </NavLink>
                                ))}
                        </div>
                        {/* ))} */}
                    </div>

                    <div className="flex-grow" />
                    <div className="pl-2 pr-5 mb-3 flex flex-col">
                        <div className="w-full flex flex-row justify-between">
                            <div className="mb-0">Storage</div>
                            <div className="text-gray-600">
                                {(currentUser.user_level === 0 && switchUser === null) ||
                                userInfo?.customer_type === 2
                                    ? 'unlimited'
                                    : `${
                                          userInfo?.usable_storage_space_total
                                              ? (
                                                    1 -
                                                    userInfo?.usable_storage_space_total / 1073741824
                                                ).toFixed(2)
                                              : (0).toFixed(2)
                                      } GB of 1 GB used`}
                            </div>
                        </div>
                        <div className="w-full mt-1.5">
                            <BorderLinearProgress
                                variant="determinate"
                                value={
                                    currentUser.user_level === 0 || userInfo?.customer_type === 2
                                        ? userInfo?.usable_storage_space_total === 0
                                            ? 100
                                            : parseInt(
                                                  (
                                                      ((1073741824 - userInfo?.usable_storage_space_total) /
                                                          1073741824) *
                                                      100
                                                  )?.toFixed(0),
                                              )
                                        : 0
                                }
                            />
                        </div>
                    </div>
                    {currentUser.user_level === 0 && (
                        <div className="flex items-end align-bottom pr-5 pl-2">
                            <Autocomplete
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                options={formattedUserList}
                                value={
                                    switchUser
                                        ? {
                                              label: userList.find((x) => x.user_id === switchUser).username,
                                              value: userList.find((x) => x.user_id === switchUser).user_id,
                                          }
                                        : {
                                              label: currentUser.username,
                                              value: currentUser.user_id,
                                          }
                                }
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(e, newValue) => handleSelectUser(newValue)}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Sidebar;
