import {
    Divider,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Yes from '../../images/yes.svg';


function PriceComparison() {

    return (<div className="max-w-6xl">
            <div className="flex-col max-w-6xl pt-20 pb-20">
                <div className="grid grid-cols-3 gap-5 p-4">
                    <div className="font-bold text-2xl text-slate-600">Features</div>
                    <div className="font-bold text-2xl text-customPrimary text-center">General</div>
                    <div className="font-bold text-2xl text-customPrimary text-center">Business</div>
                </div>
                <Divider />
                <div className="grid grid-cols-3 gap-5 p-4">
                    <div className="font-semibold text-xl text-slate-500" >Cybercast Platform</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center"></div>

                    <div className="font-bold" >Media Type</div>
                    <div className="text-center flex items-center justify-center">Image, video, text</div>
                    <div className="text-center flex items-center justify-center">Image, video, text, URL, custom</div>

                    <div className="font-bold" >Device Management</div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>

                    <div class="font-bold" >Campaign Templates</div>
                    <div class="text-center flex items-center justify-center">Limited</div>
                    <div class="text-center flex items-center justify-center">All Access</div>

                    <div className="font-bold" >Schedule Manager</div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>

                    <div className="font-bold" >Analytics Report</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>

                    <div className="font-bold" >Media Storage</div>
                    <div className="text-center flex items-center justify-center">1GB</div>
                    <div className="text-center flex items-center justify-center">Unlimited</div>

                    <div className="font-bold" >Media saved after account expired</div>
                    <div className="text-center flex items-center justify-center">3 months</div>
                    <div className="text-center flex items-center justify-center">1 year</div>

                    <div className="font-bold" >Control Hierarchy</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>

                    <div className="font-bold" >Monitor Devices</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>

                </div>
                <Divider />
                <div className="grid grid-cols-3 gap-5 p-4">
                    <div className="font-semibold text-xl text-slate-500" >Support</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center"></div>

                    <div className="font-bold" >Phone/Online Support</div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>

                    <div className="font-bold" >Onsite Support</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center">6 Call-outs</div>

                    <div className="font-bold" >Onsite Training</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center">2 Training Sessions</div>

                    <div className="font-bold" >Priority Support</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>

                    <div className="font-bold" >Managed Services</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center"><img src={Yes} /></div>

                    <div className="font-bold" >Content Design & Development</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center">Up to 10hrs of Service</div>
                </div>
                <Divider />
                <div className="grid grid-cols-3 gap-5 p-4">
                    <div className='font-semibold text-xl text-slate-500'>Hardware</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center"></div>

                    <div className="font-bold" >Device Warranty</div>
                    <div className="text-center flex items-center justify-center">12 months</div>
                    <div className="text-center flex items-center justify-center">Subscription Duration</div>

                    <div className="font-bold" >Multiple Device Discount</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center">Contact for a Quote</div>

                    <div className="font-bold" >Device Expire Protection</div>
                    <div className="text-center flex items-center justify-center"></div>
                    <div className="text-center flex items-center justify-center">30 days</div>
                </div>
            </div>
    </div>);
}
export default PriceComparison;