import * as Yup from 'yup';

import { DenseInput, Pulse } from '../utils';
import { FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNotification, useStateContext } from '../../../contexts/ContextProvider';

import { FormStyles } from './';
import { Header } from '../';
import { IOSSwitch } from './';
import { SwitchDeviceNotification } from '../../../api/api';

const Configurations = ({ profileDetail, setUpdateProfile, updateProfile }) => {
    const notify = useNotification();
    const [deviceStatusNotification, setDeviceStatusNotification] = useState();
    console.log('Configurations => deviceStatusNotification:', deviceStatusNotification);
    const handleSwitchChange = (event) => {
        setDeviceStatusNotification(event.target.checked);
        handleChangeStatus(event.target.checked ? 1 : 0);
    };

    console.log('Configurations => profileDetail:', profileDetail);
    useEffect(() => {
        setDeviceStatusNotification(profileDetail?.device_online_status_notification === 1 ? true : false);
    }, [profileDetail]);
    const handleChangeStatus = async (statusCode) => {
        const { code, msg } = await SwitchDeviceNotification(statusCode);
        console.log('handleChangeStatus => msg:', msg);

        if (code === 0) {
            setUpdateProfile(!updateProfile);
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: `Device status notification ${!deviceStatusNotification ? 'switched on' : 'switched off'}`,
                },
            });
        } else {
            notify.dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: msg ? msg : 'Device status notification switched unsuccessfully!',
                },
            });
        }
    };
    return (
        <div className="md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            {profileDetail ? (
                <>
                    <Header title="Configurations" />
                    <div className="mx-4">
                        <FormControl sx={FormStyles.formControlStyle}>
                            <FormLabel id="current_pwd" sx={FormStyles.labelText}>
                                Device Status Notification
                            </FormLabel>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={deviceStatusNotification}
                                        onChange={handleSwitchChange}
                                    />
                                }
                            />
                        </FormControl>
                    </div>
                </>
            ) : (
                <Pulse />
            )}
        </div>
    );
};

export default Configurations;
